import React from 'react'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import getLabel from '../../constants/textMultiLang'


export default function EditArtistForm(props) {
    const { lang } = useSelector(state => state.app)
    let { values, setValues, handleSubmit } = props

    const {
        NAME,
        FIRST_NAME,
        BIRTH_YEAR,
        DEATH_YEAR,
        CITY,
        COUNTRY,
        COMPLETE_FIELD,
    } = getLabel(lang, [
        'NAME',
        'FIRST_NAME',
        'BIRTH_YEAR',
        'DEATH_YEAR',
        'CITY',
        'COUNTRY',
        'COMPLETE_FIELD',
    ])
 
    const handleChange = prop => e => {
        const newValues = values
        newValues[prop].value = e.target.value
        newValues[prop].messageError = ''
        setValues({...newValues})
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box
                component='form'
                id={'edit-artist-form'}
                sx={{ textAlign: 'center' }}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
            >
                <TextField
                    autoFocus
                    label={`${NAME}`}
                    name='name'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.name.value}
                    onChange={handleChange('name')}
                    error={Boolean(values.name.messageError)}
                    helperText={values.name.messageError}
                />
                <TextField
                    label={`${FIRST_NAME}`}
                    name='firstName'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.firstName.value}
                    onChange={handleChange('firstName')}
                    error={Boolean(values.firstName.messageError)}
                    helperText={values.firstName.messageError}
                />
                <TextField
                    label={`${BIRTH_YEAR}`}
                    name='birthYear'
                    type='number'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.birthYear.value}
                    onChange={handleChange('birthYear')}
                    error={Boolean(values.birthYear.messageError)}
                    helperText={values.birthYear.messageError}
                />
                <TextField
                    label={`${DEATH_YEAR}`}
                    name='deathYear'
                    type='number'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.deathYear.value}
                    onChange={handleChange('deathYear')}
                    error={Boolean(values.deathYear.messageError)}
                    helperText={values.deathYear.messageError}
                />
                <TextField
                    label={`${CITY} fr`}
                    name='city_fr'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.city_fr.value}
                    onChange={handleChange('city_fr')}
                    error={Boolean(values.city_fr.messageError)}
                    helperText={values.city_fr.messageError}
                />
                <TextField
                    label={`${CITY} en`}
                    name='city_en'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.city_en.value}
                    onChange={handleChange('city_en')}
                    error={Boolean(values.city_en.messageError)}
                    helperText={values.city_en.messageError}
                />
                <TextField
                    label={`${COUNTRY} fr`}
                    name='country_fr'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.country_fr.value}
                    onChange={handleChange('country_fr')}
                    error={Boolean(values.country_fr.messageError)}
                    helperText={values.country_fr.messageError}
                />
                <TextField
                    label={`${COUNTRY} en`}
                    name='country_en'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.country_en.value}
                    onChange={handleChange('country_en')}
                    error={Boolean(values.country_en.messageError)}
                    helperText={values.country_en.messageError}
                />
                <Box component='input' sx={{ display: 'none' }} type='submit' />
            </Box>
        </Box>
    )
}