import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Editor } from '@tinymce/tinymce-react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { alpha } from '@mui/material/styles'

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

import Modal from '../global/Modal'
import Alert from '../global/Alert'
import Snackbar from '../global/Snackbar'

import Input, { buildSelectOptions } from './Input'

import { HOST, WWW_HOST, TINY_API_KEY } from '../../config'
import getLabel from '../../constants/textMultiLang'
import axios from '../../utils/axios'
import { Checkbox } from '@mui/material'


function buildFormdata(values) {
    const formData = new FormData()
    for (const property in values) {
        // console.log(property + ': ' + values[property])
        if(!values[property].isFile || (values[property].value && values[property].value.size && values[property].value.size > 0))
            formData.append(property, typeof values[property].value ==='string' ? values[property].value.trim() : values[property].value)
    }
    return formData
}


function TitleDescriptionCitationForm(props) {
    const { data, handleSubmitGlobal } = props
    const { name, description, citation, _id } = data
    const defaultValues = {
        name_fr: { value: name.fr, messageError: '', isRequired: true },
        name_en: { value: name.en, messageError: '', isRequired: true },
        name_he: { value: name.he, messageError: '', isRequired: true },
        description_fr: { value: description.fr, messageError: '' },
        description_en: { value: description.en, messageError: '' },
        description_he: { value: description.he, messageError: '' },
        citation_fr: { value: citation.fr, messageError: '' },
        citation_en: { value: citation.en, messageError: '' },
        citation_he: { value: citation.he, messageError: '' },
    }
    const [values, setValues] = useState(defaultValues)
    const { lang } = useSelector(state => state.app)

    const {
        NAME,
        DESCRIPTION,
        CITATION,
        SAVE,
    } = getLabel(lang, [
        'NAME',
        'DESCRIPTION',
        'CITATION',
        'SAVE',
    ])

    const handleChange = prop => e => {
        const newValues = values
        newValues[prop].value = e.target.value
        newValues[prop].messageError = ''
        setValues({...newValues})
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const status = await handleSubmitGlobal(values, _id, 'name_description_citation')
        if(status && status.novalid)
            setValues(status.validation)
    }


    return (
        <Box
            component='form'
            id='add-painting-form'
            sx={{ textAlign: 'center' }}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
        >
            <TextField
                // autoFocus
                label={`${NAME} fr`}
                name='name_fr'
                type='text'
                // fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.name_fr.value}
                onChange={handleChange('name_fr')}
                error={Boolean(values.name_fr.messageError)}
                helperText={values.name_fr.messageError}
            />
            <TextField
                label={`${NAME} en`}
                name='name_en'
                type='text'
                // fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.name_en.value}
                onChange={handleChange('name_en')}
                error={Boolean(values.name_en.messageError)}
                helperText={values.name_en.messageError}
            />
            <TextField
                label={`${NAME} he`}
                name='name_he'
                type='text'
                // fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.name_he.value}
                onChange={handleChange('name_he')}
                error={Boolean(values.name_he.messageError)}
                helperText={values.name_he.messageError}
            />
            <TextField
                label={`${DESCRIPTION} fr`}
                name='description_fr'
                type='text'
                multiline
                rows={2}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.description_fr.value}
                onChange={handleChange('description_fr')}
                error={Boolean(values.description_fr.messageError)}
                helperText={values.description_fr.messageError}
            />
            <TextField
                label={`${DESCRIPTION} en`}
                name='description_en'
                type='text'
                multiline
                rows={2}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.description_en.value}
                onChange={handleChange('description_en')}
                error={Boolean(values.description_en.messageError)}
                helperText={values.description_en.messageError}
            />
            <TextField
                label={`${DESCRIPTION} he`}
                name='description_he'
                type='text'
                multiline
                rows={2}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.description_he.value}
                onChange={handleChange('description_he')}
                error={Boolean(values.description_he.messageError)}
                helperText={values.description_he.messageError}
            />
            <TextField
                label={`${CITATION} fr`}
                name='citation_fr'
                type='text'
                multiline
                rows={2}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.citation_fr.value}
                onChange={handleChange('citation_fr')}
                error={Boolean(values.citation_fr.messageError)}
                helperText={values.citation_fr.messageError}
            />
            <TextField
                label={`${CITATION} en`}
                name='citation_en'
                type='text'
                multiline
                rows={2}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.citation_en.value}
                onChange={handleChange('citation_en')}
                error={Boolean(values.citation_en.messageError)}
                helperText={values.citation_en.messageError}
            />
            <TextField
                label={`${CITATION} he`}
                name='citation_he'
                type='text'
                multiline
                rows={2}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.citation_he.value}
                onChange={handleChange('citation_he')}
                error={Boolean(values.citation_he.messageError)}
                helperText={values.citation_he.messageError}
            />
            <Box sx={{ position: 'sticky', bottom: 0, textAlign: 'end' }}>
                <IconButton  color='secondary' type='submit' aria-label={SAVE} sx={{ backgroundColor: t => t.palette.primary.main, '&:hover': {backgroundColor: t => alpha(t.palette.primary.main, .7)} }}><SaveOutlinedIcon /></IconButton>
            </Box>
        </Box>
    )
}



function TextForm(props) {
    const { data, handleSubmitGlobal } = props
    const { text, _id } = data
    const defaultValues = {
        text_fr: { value: text.fr, messageError: '' },
        text_en: { value: text.en, messageError: '' },
        text_he: { value: text.he, messageError: '' },
    }
    const [values, setValues] = useState(defaultValues)
    const { lang } = useSelector(state => state.app)

    const {
        TEXT,
        SAVE,
    } = getLabel(lang, [
        'TEXT',
        'SAVE',
    ])

    const handleChange = prop => e => {
        const newValues = values
        newValues[prop].value = e.target.value
        newValues[prop].messageError = ''
        setValues({...newValues})
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const status = await handleSubmitGlobal(values, _id, 'text')
        if(status && status.novalid)
            setValues(status.validation)
    }


    return (
        <Box
            component='form'
            id='add-painting-form'
            sx={{ textAlign: 'center' }}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
        >
            <TextField
                label={`${TEXT} fr`}
                name='text_fr'
                type='text'
                multiline
                rows={5}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.text_fr.value}
                onChange={handleChange('text_fr')}
                error={Boolean(values.text_fr.messageError)}
                helperText={values.text_fr.messageError}
            />
            <TextField
                label={`${TEXT} en`}
                name='text_en'
                type='text'
                multiline
                rows={5}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.text_en.value}
                onChange={handleChange('text_en')}
                error={Boolean(values.text_en.messageError)}
                helperText={values.text_en.messageError}
            />
            <TextField
                label={`${TEXT} he`}
                name='text_he'
                type='text'
                multiline
                rows={5}
                fullWidth
                variant="standard"
                required
                sx={{ m: 2 }}
                value={values.text_he.value}
                onChange={handleChange('text_he')}
                error={Boolean(values.text_he.messageError)}
                helperText={values.text_he.messageError}
            />
            <Box sx={{ position: 'sticky', bottom: 0, textAlign: 'end' }}>
                <IconButton  color='secondary' type='submit' aria-label={SAVE} sx={{ backgroundColor: t => t.palette.primary.main, '&:hover': {backgroundColor: t => alpha(t.palette.primary.main, .7)} }}><SaveOutlinedIcon /></IconButton>
            </Box>
        </Box>
    )
}


function ArtistGenreCurrentForm(props) {

    const { data, handleSubmitGlobal } = props
    const { artist, genre, current, _id } = data
    
    const defaultValues = {
        artist: {option: null, value: ''},
        genre: {option: null, value: ''},
        current: {option: null, value: ''},
    }
    
    const [selects, setSelects] = useState({ artists: [], genres: [], currents: [] })
    const [values, setValues] = useState(defaultValues)
    const { lang } = useSelector(state => state.app)

    const { ARTIST, GENRE, CURRENT, NO_OPTION, SAVE } = getLabel(lang, [ 'ARTIST', 'GENRE', 'CURRENT', 'NO_OPTION', 'SAVE' ])

    useEffect(() => {
        const getData = async () => {
            const data = {}
            const artists = await axios.getArtists()
            const genres = await axios.getGenres()
            const currents = await axios.getCurrents()
            data.artists = artists.code === 200 && artists.data.length ? artists.data.map(a => ({ id: a._id, label: [a.name, a.firstName].join(' ') })) : []
            data.genres = genres.code === 200 && genres.data ? genres.data.map(g => ({ id: g._id, label: g.name[lang] })) : []
            data.currents = currents.code === 200 && currents.data ? currents.data.map(c => ({ id: c._id, label: c.name[lang] })) : []
            setSelects(data)
        }
        getData()
    }, [setSelects])

    useEffect(() => {
        const defaultValues = {
            artist: artist._id && selects.artists.length ? { option: selects.artists.filter(a => a.id === artist._id)[0], value: artist._id } : {option: null, value: ''},
            genre: genre._id && selects.genres.length ? { option: selects.genres.filter(g => g.id === genre._id)[0], value: genre._id } : {option: null, value: ''},
            current: current._id && selects.currents.length ? { option: selects.currents.filter(c => c.id === current._id)[0], value: current._id } : {option: null, value: ''},
        }
        setValues(defaultValues)
    }, [artist, genre, current, selects, setValues])

    const handleChange = prop => (e, value) => {
        const newValues = values
        newValues[prop] = { option: value, value: (value && value.id) || '' }
        setValues({...newValues})
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const status = await handleSubmitGlobal(values, _id, 'artist_genre_current')
        if(status && status.novalid)
            setValues(status.validation)
    }


    return (
        <Box
            component='form'
            id='add-painting-form'
            sx={{ textAlign: 'center' }}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
        >
            <Autocomplete
                options={selects.artists}
                value={values.artist.option}
                onChange={handleChange('artist')}
                isOptionEqualToValue={(option, value) => option && option.id === value.id}
                renderInput={params => <TextField {...params} label={ARTIST} variant='standard' />}
                noOptionsText={NO_OPTION}
            />
            <Autocomplete
                options={selects.genres}
                value={values.genre.option}
                onChange={handleChange('genre')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => <TextField {...params} label={GENRE} variant='standard' />}
                noOptionsText={NO_OPTION}
            />
            <Autocomplete
                options={selects.currents}
                value={values.current.option}
                onChange={handleChange('current')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => <TextField {...params} label={CURRENT} variant='standard' />}
                noOptionsText={NO_OPTION}
            />
            <Box sx={{ position: 'sticky', bottom: 0, textAlign: 'end' }}>
                <IconButton  color='secondary' type='submit' aria-label={SAVE} sx={{ backgroundColor: t => t.palette.primary.main, '&:hover': {backgroundColor: t => alpha(t.palette.primary.main, .7)} }}><SaveOutlinedIcon /></IconButton>
            </Box>
        </Box>
    )
}

function AudioForm(props) {
    const langAudio = ['fr', 'en', 'he']
    const defaultValues = {}
    langAudio.forEach(lang => defaultValues['audioPainting_' + lang] = { value: {}, isFile: true, isRequired: true, messageError: '' })

    const { data, handleSubmitGlobal } = props
    const { artist, genre, current, audioPaintings, _id } = data
    
    const [values, setValues] = useState(defaultValues)
    const [delValue, setDelValue] = useState('')
    const [success, setSuccess] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const { lang } = useSelector(state => state.app)

    const { DELETE_FILE, ATTENTION, WANT_DELETE_FILE, WANT_ABLE_TO_REVERT, CANCEL, DELETE, SAVE } = getLabel(lang, [ 'DELETE_FILE', 'ATTENTION', 'WANT_DELETE_FILE', 'WANT_ABLE_TO_REVERT', 'CANCEL', 'DELETE', 'SAVE' ])

    useEffect(() => {
        if(success)
            setSuccess(false)
    }, [success])

    useEffect(() => {
        if(delValue) {
            async function handleSubmit() {
                const status = await handleSubmitGlobal({delValue: { value: delValue }}, _id, 'del_audio_description')
            }
            handleSubmit()
            setDelValue('')
        }
    }, [delValue, _id, handleSubmitGlobal])

    const handleChange = prop => file => {
        const newValues = values
        newValues[prop].value = file || {}
        newValues[prop].messageError = ''
        setValues({...newValues})
    }

    const deleteFile = prop => e => {
        setConfirmOpen(prop)
    }

    const deleteConfirm = prop => e => {
        setDelValue(prop)
    }

    const handleSubmit = prop => async e => {
        e.preventDefault()
        const status = await handleSubmitGlobal({[prop]: values[prop]}, _id, 'audio_description')
        if(status && status.novalid)
            setValues(prevValues => ({ ...prevValues, ...status.validation }))
        else if(status && !status.novalid) {
            setValues(defaultValues)
            setSuccess(true)
        }
    }


    return (
        <>
            {langAudio.map(lang => (
                <Grid container justifyContent='space-between' alignItems='center' key={lang} sx={{ margin: '30px 0' }}>
                    <Grid sx={{ margin: 'auto' }}>
                        <audio controls preload='metadata' src={`${HOST}audios/${audioPaintings[lang]}`} />
                    </Grid>
                    <Grid sx={{ margin: 'auto' }}>
                        <Box
                            component='form'
                            sx={{ textAlign: 'center' }}
                            onSubmit={handleSubmit('audioPainting_' + lang)}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid container>
                                <Grid>
                                    <Input
                                        label=''
                                        type="file"
                                        name={"audioPainting_" + lang}
                                        errorMessage={values['audioPainting_' + lang].messageError}
                                        maxSize={13145728}
                                        accept='.mp3'
                                        onChange={handleChange('audioPainting_' + lang)}
                                        success={success}
                                        inline
                                    />
                                </Grid>
                                <Grid>
                                    <Grid container direction='column' justifyContent='space-around' sx={{ height: '100%', paddingBottom: '1em' }}>
                                        <Grid>
                                            <IconButton color='secondary' type='submit' aria-label={SAVE} sx={{ backgroundColor: t => t.palette.primary.main, '&:hover': {backgroundColor: t => alpha(t.palette.primary.main, .7)} }}><SaveOutlinedIcon /></IconButton>
                                        </Grid>
                                        <Grid>
                                            <IconButton disabled={!audioPaintings[lang]} color='secondary' type='button' aria-label={DELETE_FILE} sx={{ backgroundColor: '#d10303', '&:hover': {backgroundColor: t => alpha('#d10303', .7)} }} onClick={deleteFile('audioPainting_' + lang)}><DeleteOutlinedIcon /></IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            ))}
            <Modal 
                dialogTitle={
                    <></>
                }
                dialogContent={
                    <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                        <Box><WarningAmberOutlinedIcon color='error' fontSize='large' /></Box>
                        <Typography component='h2' variant='h5'>{ATTENTION}</Typography><br />
                        <Typography>{WANT_ABLE_TO_REVERT}<br />{WANT_DELETE_FILE}</Typography>
                    </Box>
                }
                // height='100%'
                // maxWidth={maxWidth}
                // fullWidth={true}
                buttons={[{ label: CANCEL, func: deleteConfirm('') }, { label: DELETE, type: 'button', func: deleteConfirm(confirmOpen) }]}
                // blockBackdropClick={true}
                isCustomOpen={true}
                customOpen={Boolean(confirmOpen)}
                customClose={() => setConfirmOpen(false)}
                closeButton={true}
            />
        </>
    )
}

function BuildFilesList(props) {
    const [data, setData] = useState([])
    const [fileChecked, setFileChecked] = useState({ input: 0, value: '' })
    const { setFileSelected } = props

    useEffect(() => {
        const getData = async () => {
            const data = {}
            const res = await axios.getFilesDirectory()
            if(res.code === 200)
                setData(res.data)
            else
                setData([])
        }
        getData()
    }, [setData])

    useEffect(() => {
        setFileSelected(fileChecked.value)
    }, [fileChecked])

    function getFileName(name) {
        const arrayName = name.split('.')
        arrayName.pop()
        return arrayName.join('')
    }

    const handleSelect = props => e => {
        setFileChecked(prev => ({ input: prev.input !== props ? props : 0, value: prev.input !== props ? e.target.value : '' }))
    }

    return (
        <Grid container>
            {data && data.map((file, index) => {
                const fileName = getFileName(file.name)
                const src = ['.jpg'].includes(file.extension) ? encodeURI(`${WWW_HOST}images/upload/${file.name}`) : `${WWW_HOST}images/site/no-image`
            
                return (
                    <Grid key={index} md={2} sm={4} sx={{ padding: '10px' }}>
                        <Box sx={{ width: '100%', position: 'relative' }} title={fileName}>
                            <Box component='img' src={src} sx={{ verticalAlign: 'bottom' }} />
                            <Typography component='span' sx={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: '#ffffff54', borderRadius: '5px', '& .MuiButtonBase-root': { padding: '3px' } }}>
                                <Checkbox checked={fileChecked.input === index + 1} onChange={handleSelect(index + 1)} value={`${src}§§${fileName}`} />
                            </Typography>
                        </Box>
                        <Box><Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={fileName}>{fileName}</Typography></Box>
                    </Grid>
                )
            })}
        </Grid>
    )

}

function CompleteDescriptionForm(props) {
    const { data, handleSubmitGlobal } = props
    const { addInfo, _id } = data

    const defaultValues = {
        addInfo_fr: { value: addInfo.fr, messageError: '' },
        addInfo_en: { value: addInfo.en, messageError: '' },
        addInfo_he: { value: addInfo.he, messageError: '' },
    }
    const [values, setValues] = useState(defaultValues)
    // const [text, setText] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [dataFile, setDataFile] = useState({})
    const [fileSelected, setFileSelected] = useState('')

    const { lang } = useSelector(state => state.app)

    const {
        ADDITIONAL_INFORMATIONS,
        SAVE,
        CANCEL,
        SELECT,
    } = getLabel(lang, [
        'ADDITIONAL_INFORMATIONS',
        'SAVE',
        'CANCEL',
        'SELECT',
    ])

    const handleChange = (key, value) => {
        const newValues = values
        newValues[key].value =value
        newValues[key].messageError = ''
        setValues({...newValues})
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const status = await handleSubmitGlobal(values, _id, 'addInfo')
        if(status && status.novalid)
            setValues(status.validation)
    }

    
    const importFileSelected = () => {
        const { callback, value, mmeta } = dataFile
        const fileArray = fileSelected.split('§§')
        const fileURI = fileArray[0]
        const fileName = fileArray[1]
        callback(fileURI, { alt: fileName })
    }

    async function getFilesDirectory (callback, value, meta) {
        /* Provide image and alt text for the image dialog */
        if (meta.filetype === 'image') {
            // callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
            setDataFile({ callback, value, meta })
            setModalOpen(true)
        }
        /* Provide file and text for the link dialog */
        // if (meta.filetype === 'file') {
        //     callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
        // }

        /* Provide alternative source and posted for the media dialog */
        // if (meta.filetype === 'media') {
        //     callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
        // }
    }

    const init = {
        language_url: `${HOST}static/js/tiny/langs/${lang}.js`,
        language: `${lang}_${lang.toUpperCase()}`,
        menubar: 'file edit view insert format tools table help',
        plugins: ['a11ychecker', 'powerpaste', 'preview', 'importcss', 'searchreplace', 'autolink', 'autosave', 'save', 'directionality', 'code', 'visualblocks', 'visualchars', 'fullscreen', 'image', 'link', 'media', 'template', 'codesample', 'table', 'charmap', 'pagebreak', 'nonbreaking', 'anchor', 'insertdatetime', 'advlist', 'lists', 'wordcount', 'help', 'charmap', 'quickbars', 'emoticons'],
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview /*save*/ print insertfile image media template link anchor codesample | ltr rtl',
        directionality: 'ltr',
        imagetools_cors_hosts: ['picsum.photos'],
        image_advtab: true,
        file_picker_callback: getFilesDirectory,
        toolbar_mode: 'sliding',
        content_style: 'body { font-family:Rubik,sans-serif; font-size:1rem }'
    }

    useEffect(() => {
        const handler = (e) => {
          if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
            e.stopImmediatePropagation();
            document.querySelector(".MuiDialog-container").removeAttribute('tabindex');
          }
        };
        document.addEventListener("focusin", handler);
        return () => document.removeEventListener("focusin", handler);
    }, [])

    return (
        <>
            <Box
                component='form'
                id='add-info-form'
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
            >
                <Box sx={{ padding: '20px 0' }}>
                    <label htmlFor='add-info_fr'>{ADDITIONAL_INFORMATIONS} fr</label>
                    <Editor
                        apiKey={TINY_API_KEY}
                        id='add-info_fr'
                        // initialValue={'initialValue'}
                        value={values.addInfo_fr.value}
                        // onInit={(evt, editor) => {
                        //     setText(editor.getContent({format: 'text'}));
                        // }}
                        onEditorChange={(newValue, editor) => {
                            handleChange('addInfo_fr', newValue)
                            // setValues(newValue);
                            // setText(editor.getContent({format: 'text'}));
                        }}
                        init={init}
                    />
                </Box>
                <Box sx={{ padding: '20px 0' }}>
                    <label htmlFor='add-info_en'>{ADDITIONAL_INFORMATIONS} en</label>
                    <Editor 
                        apiKey={TINY_API_KEY}
                        id='add-info_en'
                        // initialValue={'initialValue'}
                        value={values.addInfo_en.value}
                        // onInit={(evt, editor) => {
                        //     setTextee(editor.getContent({format: 'text'}));
                        // }}
                        onEditorChange={(newValue, editor) => {
                            handleChange('addInfo_en', newValue)
                            // setValueee(newValue);
                            // setTextee(editor.getContent({format: 'text'}));
                        }}
                        init={init}
                    />
                </Box>
                <Box sx={{ padding: '20px 0' }}>
                    <label htmlFor='add-info_he'>{ADDITIONAL_INFORMATIONS} he</label>
                    <Editor 
                        apiKey={TINY_API_KEY}
                        id='add-info_he'
                        // initialValue={'initialValue'}
                        value={values.addInfo_he.value}
                        // onInit={(evt, editor) => {
                        //     setTextee(editor.getContent({format: 'text'}));
                        // }}
                        onEditorChange={(newValue, editor) => {
                            handleChange('addInfo_he', newValue)
                            // setValueee(newValue);
                            // setTextee(editor.getContent({format: 'text'}));
                        }}
                        init={{
                            ...init,
                            directionality: 'rtl', // pour le texte à saisir
                        }} 
                    />
                </Box>
                <Box sx={{ position: 'sticky', bottom: 0, textAlign: 'end', zIndex: 1 }}>
                    <IconButton  color='secondary' type='submit' aria-label={SAVE} sx={{ backgroundColor: t => t.palette.primary.main, '&:hover': {backgroundColor: t => alpha(t.palette.primary.main, .7)} }}><SaveOutlinedIcon /></IconButton>
                </Box>
            </Box>
            <Modal
                dialogTitle={<>Fichiers</>}
                dialogContent={<Box><BuildFilesList setFileSelected={setFileSelected} /></Box>}
                buttons={[{ label: CANCEL, variant: 'outlined' }, { label: SELECT, variant: 'contained', type: 'button', func: importFileSelected }]}
                isCustomOpen
                customOpen={modalOpen}
                blockBackdropClick={true}
                customClose={() => setModalOpen(false)}
                closeButton
                fullWidth
                maxWidth={'lg'}
                height='100%'
            />
        </>
    )
}


export default function EditPaintingForm(props) {
    const [onlineStatus, setOnlineStatus] = useState(false)
    const [tabValue, setTabValue] = useState('1')
    const [snackbarPack, setSnackbarPack] = useState([])
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState(undefined)
    const { lang } = useSelector(state => state.app)
    let { paintingData, updatePaintingsData } = props

    const {
        COMPLETE_FIELD,
        REGISTERED_SUCCESSFULLY,
        BUT_OFFLINE_EMPTY_FIELDS,
        PUT_ONLINE_OFFLINE,
        TEXT,
        TITLE_DESCRIPTION_CITATION,
        ARTIST_GENRE_CURRENT,
        AUDIO_DESCRIPTION,
        ADDITIONAL_INFORMATIONS,
        INTERNAL_ERROR,
    } = getLabel(lang, [
        'COMPLETE_FIELD',
        'REGISTERED_SUCCESSFULLY',
        'BUT_OFFLINE_EMPTY_FIELDS',
        'PUT_ONLINE_OFFLINE',
        'TITLE_DESCRIPTION_CITATION',
        'TEXT',
        'ARTIST_GENRE_CURRENT',
        'AUDIO_DESCRIPTION',
        'ADDITIONAL_INFORMATIONS',
        'INTERNAL_ERROR',
    ])

    const messageAPI = getLabel(lang, ['SAVED_SUCCESSFULLY', 'MISSING_DATA', 'CONTAINS_EMPTY_FIELDS', 'INTERNAL_ERROR'])

    useEffect(() => {
        setOnlineStatus(Boolean(paintingData.status))
    }, [paintingData])

    useEffect(() => {
        if (snackbarPack.length && !snackbarMessage) {
          // Set a new snack when we don't have an active one
          setSnackbarMessage({ ...snackbarPack[0] })
          setSnackbarPack(prev => prev.slice(1))
          setOpenSnackbar(true)
        } else if (snackbarPack.length && snackbarMessage && openSnackbar) {
          // Close an active snack when a new one is added
          setOpenSnackbar(false)
        }
    }, [snackbarPack, snackbarMessage, openSnackbar])

    const handleSetSnackbarPack = message => setSnackbarPack(prev => [...prev, { ...message, key: new Date().getTime() }])

    const handleExited = () => setSnackbarMessage(undefined)

    const handleChangeStatus = async e => {
        const res = await axios.toogleOnline({ id: paintingData._id, status: e.target.checked ? 1 : 0 })
        if(res.code === 201) {
            handleSetSnackbarPack({ message: res.message ? messageAPI[res.message] : null, severity: 'success' })
            updatePaintingsData({ ...paintingData, status: res.data.newDoc.status })
        } else {
            handleSetSnackbarPack({ message: res.message ? messageAPI[res.message] : INTERNAL_ERROR, severity: 'error', duration: 10000 })
        }
    }

    const handleChangeTab = (e, newValue) => setTabValue(newValue)

    function isValidForm(values) {
        let status = true
        const newValues = values
        for (const property in values) {
            // console.log(property + ': ' + values[property])
            if(!values[property].size && values[property].size !== 0) {
                if(values[property].isRequired && ((typeof values[property].value === 'object' && Object.keys(values[property].value).length === 0 && !values[property].value.size) || (typeof values[property].value === 'string' && !values[property].value.trim()))) {
                    status = false
                    newValues[property].messageError = COMPLETE_FIELD
                }
            }
        }
        if(!status) 
            return { novalid: true, validation: {...values, ...newValues}}
        else{
            return { novalid: false }
        }
    }

    const handleSubmit = async (values, id, fields) => {
        const isInvalidFormObject = isValidForm(values)
        if(isInvalidFormObject.novalid)
            return isInvalidFormObject
        const formData = buildFormdata(values)
        formData.append('fields', fields)
        formData.append('id', id)
        const res = await axios.updatePainting(formData)
        if(res.code === 201) {
            handleSetSnackbarPack({ message: REGISTERED_SUCCESSFULLY + (res.data.offline === 1 ? ' ' + BUT_OFFLINE_EMPTY_FIELDS : ''), severity: res.data.offline === 1 ? 'warning' : 'success' })
            updatePaintingsData(res.data.newDoc)
            return true
        } else {
            handleSetSnackbarPack({ message: res.message, severity: 'error' })
            return false
        }
    }


    return (
        <>
        <Box sx={{ width: '100%' }}>
            <Grid container direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' alignItems='center'>
                <Grid sx={{ width: '10rem' }}>
                    <Box component='img' src={HOST + 'images/' + paintingData.thumbLink} alt={''} />
                </Grid>
                <Grid>
                    <Box component='label' sx={{ padding: '30px 0' }}>
                        {PUT_ONLINE_OFFLINE}
                        <Switch
                            checked={onlineStatus}
                            onChange={handleChangeStatus}
                            inputProps={{ 'aria-label': 'controled' }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList variant='scrollable' onChange={handleChangeTab} aria-label="lab API tabs example">
                        <Tab label={TITLE_DESCRIPTION_CITATION} value="1" />
                        <Tab label={TEXT} value="2" />
                        <Tab label={ARTIST_GENRE_CURRENT} value="3" />
                        <Tab label={AUDIO_DESCRIPTION} value="4" />
                        <Tab label={ADDITIONAL_INFORMATIONS} value="5" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <TitleDescriptionCitationForm handleSubmitGlobal={handleSubmit} data={paintingData} />
                </TabPanel>
                <TabPanel value="2">
                    <TextForm handleSubmitGlobal={handleSubmit} data={paintingData} />
                </TabPanel>
                <TabPanel value="3">
                    <ArtistGenreCurrentForm handleSubmitGlobal={handleSubmit} data={paintingData} />
                </TabPanel>
                <TabPanel value="4">
                    <AudioForm handleSubmitGlobal={handleSubmit} data={paintingData} />
                </TabPanel>
                <TabPanel value="5">
                    <CompleteDescriptionForm handleSubmitGlobal={handleSubmit} data={paintingData} />
                </TabPanel>
            </TabContext>
            <Box sx={{ position: 'relative' }}>
                <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} handleExited={handleExited} message={snackbarMessage} />
            </Box>
        </Box>
        
        </>
    )
}