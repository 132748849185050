import { ENV, LANG_ARRAY_CODE, DEFAULT_LANG_CODE, LANG_ARRAY_CODE_WITHOUT_DEFAULT_LANG, LANG_ARRAY_CODE_DIR_RTL } from '../config'
const lang_regexStart_without_defaultLang = new RegExp('^/(' + LANG_ARRAY_CODE_WITHOUT_DEFAULT_LANG.join('|') + ')')

let lastId = 54123
export const newID = () => {
    lastId++;
    return lastId;
}

export const setLanguage = (history) => {
    let langToReturn = ''
    const langHtml = document.documentElement.lang
    if(ENV === 'production' && /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)) {
        langToReturn = langHtml
    } else {
        let navigatorLang = navigator.language || navigator.userLanguage
        navigatorLang = navigatorLang.split('-')[0]
        const langUser = localStorage.getItem("lang") || navigatorLang
        const location = history.location.pathname.replace(lang_regexStart_without_defaultLang, '')
        langToReturn = langUser
        if(langHtml !== langUser)
            langToReturn = LANG_ARRAY_CODE.includes(langUser) ? langUser : DEFAULT_LANG_CODE

        const newLocation = (langToReturn === DEFAULT_LANG_CODE ? location : '/' + langToReturn + location) + history.location.search
        history.push(newLocation)
        document.documentElement.lang = langToReturn
    }
    document.dir = LANG_ARRAY_CODE_DIR_RTL.includes(langToReturn) ? 'rtl' : 'ltr'
    document.querySelector('body').className = LANG_ARRAY_CODE_DIR_RTL.includes(langToReturn) ? 'dir-rtl' : 'dir-ltr'
    return langToReturn
}

export const setThemeMode = (themeMode = null) => {
    if(themeMode)
        localStorage.setItem("themeMode", themeMode)
    else
        themeMode = localStorage.getItem("themeMode") || 'normalTheme'
    return themeMode
}

export const convertToUrl = (string) => {
    const chars = {' ':'-','\'':'-','à':'a','â':'a','é':'e','è':'e','ê':'e','ë':'e','ç':'c','ï':'i','ô':'o','ö':'o','ù':'u','ü':'u'};
    return string.replace(/[\s'àâéèêëçïôöùü]/gi, c => chars[c]).toLowerCase().replace(/[^א-תa-z0-9-]/gi, '')
}

export function checkMime(file, format) {
    // Le format csv n'est pas checkable ainsi que doc,xls,ppt,msi
    switch(file.name.split('.').pop().toLowerCase()) {
        case 'csv':
            return 'text/csv'
        case 'doc':
            return 'text/csv'
        case 'xls':
            return 'text/csv'
        case 'ppt':
            return 'text/csv'
        case 'msi':
            return 'text/csv'
        default:
            const mime = [
                {extension: 'mp3',      pattern: '494433',      fichier: 'audio',       type: 'audio/mpeg'}, // 4944333
                {extension: 'aac',      pattern: '???',         fichier: 'audio',       type: 'audio/aac'},
                {extension: 'mpeg',     pattern: '???',         fichier: 'video',       type: 'video/mpeg'},
                {extension: 'mp4',      pattern: '00018',       fichier: 'video',       type: 'video/mp4'},
                {extension: 'avi',      pattern: '???',         fichier: 'video',       type: 'video/x-msvideo'},
                {extension: 'png',      pattern: '89504e4',     fichier: 'image',       type: 'image/png'}, // 89504e47
                {extension: 'jpg',      pattern: 'ffd8ffe',     fichier: 'image',       type: 'image/jpg'}, // ffd8ffe0
                {extension: 'svg',      pattern: '3c3f786',     fichier: 'image',       type: 'image/svg+xml'}, // 3c3f786d
                {extension: 'pdf',      pattern: '2550444',     fichier: 'doc',         type: 'application/pdf'}, // 25504446
                {extension: 'doc',      pattern: '???',         fichier: 'doc',         type: 'application/msword'},
                {extension: 'rtf',      pattern: '???',         fichier: 'doc',         type: 'application/rtf'},
                {extension: 'docx',     pattern: '504b34',      fichier: 'doc',         type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
                {extension: 'zip',      pattern: '504b34',      fichier: 'compressed',  type: 'application/zip'}, // apparemment zip et docx ont le même pattern
                {extension: 'rar',      pattern: '???',         fichier: 'compressed',  type: 'application/x-rar-compressed'},
            ]
            return new Promise((resolve, reject) => {
                let header = ""
                const fileReader = new FileReader()
                fileReader.onloadend = e => {
                    const arr = (new Uint8Array(e.target.result)).subarray(0, 4)
                    for(var i = 0; i < arr.length; i++) {
                        header += arr[i].toString(16)
                    }
                    if (header.length > 6) // jpg par ex. peut contenir en dernier char 0|1|2|3|4|8 donc on supprime le dernier char
                        header = header.slice(0, -1)
                    const checkFichier = mime.filter(item => item.pattern === header && format.includes(item.extension)).length > 0
                    resolve(checkFichier)
                }
                fileReader.onerror = reject

                fileReader.readAsArrayBuffer(file)
            })
    }
}