import React from 'react'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import getLabel from '../../constants/textMultiLang'


export default function EditGenreForm(props) {
    const { lang } = useSelector(state => state.app)
    let { values, setValues, handleSubmit } = props

    const {
        NAME,
    } = getLabel(lang, [
        'NAME',
    ])
 
    const handleChange = prop => e => {
        const newValues = values
        newValues[prop].value = e.target.value
        newValues[prop].messageError = ''
        setValues({...newValues})
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box
                component='form'
                id={'edit-genre-form'}
                sx={{ textAlign: 'center' }}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
            >
                <TextField
                    autoFocus
                    label={`${NAME} fr`}
                    name='name_fr'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.name_fr.value}
                    onChange={handleChange('name_fr')}
                    error={Boolean(values.name_fr.messageError)}
                    helperText={values.name_fr.messageError}
                />
                <TextField
                    label={`${NAME} en`}
                    name='name_en'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.name_en.value}
                    onChange={handleChange('name_en')}
                    error={Boolean(values.name_en.messageError)}
                    helperText={values.name_en.messageError}
                />
                <TextField
                    label={`${NAME} he`}
                    name='name_he'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.name_he.value}
                    onChange={handleChange('name_he')}
                    error={Boolean(values.name_he.messageError)}
                    helperText={values.name_he.messageError}
                />
                <Box component='input' sx={{ display: 'none' }} type='submit' />
            </Box>
        </Box>
    )
}