import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../redux/store'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { prefixer } from 'stylis'

import { LANG_ARRAY_CODE_WITHOUT_DEFAULT_LANG, LANG_ARRAY_CODE_DIR_RTL } from '../config'

import { setLanguage, setThemeMode } from '../utils/app'
import { setAppLoad, setLang, setTheme } from '../redux/actions/app'
import { getUser } from '../redux/actions/user'


import customTheme from '../theme/theme'

// import "bootstrap/dist/css/bootstrap.min.css";
import './App.scss'

import Home from './home/Home'
import Painting from './painting/Painting'
import Paintings from './paintings/Paintings'
import About from './About'
import Contact from './Contact'
import LegalNotice from './LegalNotice'
import PrivacyPolicy from './PrivacyPolicy'
import Cookie from './Cookie'
import Accessibility from './Accessibility'
import Login from './Login'
import Cms from './cms/Cms.js'
import NotFound from './NotFound'
import ScrollToTop from './ScrollToTop'

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
})
  

export default function App() {
    const dispatch = useDispatch()

    const { appLoad, lang, themeMode } = useSelector(state => state.app)
    const [themeGlobal, setThemeGlobal] = useState(null)

    useEffect(() => {
        if(!themeGlobal && customTheme[themeMode]) {
            customTheme[themeMode].direction = LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'rtl' : 'ltr'
            setThemeGlobal(responsiveFontSizes(customTheme[themeMode]))
        }
    }, [themeMode, themeGlobal, lang])

    useEffect(() => {
        dispatch(setLang(setLanguage(history)))
        dispatch(setTheme(setThemeMode()))
        dispatch(getUser())
        .then(() => dispatch(setAppLoad()))
    }, [dispatch])


    let langRoutes = '(/' + LANG_ARRAY_CODE_WITHOUT_DEFAULT_LANG.join('|/') + '|)'

    const DirProvider = (props) => {
        if(LANG_ARRAY_CODE_DIR_RTL.includes(lang))
            return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>
        else
            return <>{props.children}</>
    }

    const subdomain = window.location.host.split('.')[0]

    return (
        <> {appLoad && themeGlobal && 
            <HelmetProvider>
                <DirProvider value={LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? cacheRtl : ''}>
                    <ThemeProvider theme={themeGlobal}>
                        <ScrollToTop />
                        <CssBaseline />
                            {subdomain === 'cms' ? 
                            <Switch>
                                <Route exact path={`${langRoutes}/login`} component={Login} />
                                <Route path={`/`} component={Cms} />
                            </Switch>
                            :
                            <Switch>
                                <Route exact path={langRoutes} component={Home} />
                                <Route exact path={`${langRoutes}/(les-oeuvres|paintings|ציורים)`} component={Paintings} />
                                <Route exact path={`${langRoutes}/(oeuvre|painting|ציור)/:uuidPainting/:paintingName`} component={Painting} />
                                <Route exact path={`${langRoutes}/(a-propos|about|אודות)`} component={About} />
                                <Route exact path={`${langRoutes}/(contact|צור-קשר)`} component={Contact} />
                                <Route exact path={`${langRoutes}/(mentions-legales|legal-notice|הבהרות-משפטיות)`} component={LegalNotice} />
                                <Route exact path={`${langRoutes}/(confidentialite|privacy-policy|מדיניות-פרטיות)`} component={PrivacyPolicy} />
                                <Route exact path={`${langRoutes}/(cookie|עוגיות)`} component={Cookie} />
                                <Route exact path={`${langRoutes}/(accessibilite|accessibility|נגישות)`} component={Accessibility} />
                                <Route component={NotFound} />
                            </Switch>
                            }
                    </ThemeProvider>
                </DirProvider>
            </HelmetProvider>}
        </>
    )
}

// App.contextTypes = {
//   router: PropTypes.object.isRequired
// }