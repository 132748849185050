import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'

import getLabel from '../../constants/textMultiLang'


// exemple Modal call
// <Modal
//    dialogTitle={'AJOUTER UN NOUVEAU TABLEAU'} 
//    dialogContent={<AddPaintingForm  />}
//    dialogContentText={<>To subscribe to this website, please enter your email address here. We will send updates occasionally.</>}
//    buttons={[ { label: 'CANCEL' }, { label: ADD, type: 'button', func: async () => { document.getElementById('add-painting-form').requestSubmit() } } ]}
//    blockBackdropClick={true}
///>


export default function Modal(props) {
    const [open, setOpen] = useState(false)
    const { lang } = useSelector(state => state.app)
    const { isCustomOpen, customOpen, customClose, closeButton, dialogTitle, dialogContent, dialogContentText, buttons, blockBackdropClick, fullWidth, maxWidth, height } = props
    const paperProps = { style: { height: height || 'auto' } }

    const {
        CLOSE_MENU,
    } = getLabel(lang, [
        'CLOSE_MENU',
    ])

    useEffect(() => {
        if(isCustomOpen) {
            setOpen(customOpen)
        }
    }, [isCustomOpen, customOpen])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = isCustomOpen 
    ? customClose 
    : () => {
        setOpen(false)
    }

    const handleBackdropClick = () => {
        if(!blockBackdropClick)
            handleClose()
    }

    const handleButtonClick = props => {
        if(props.func)
            props.func(props)
        if(!props.keepModal)
            handleClose()
    }

    return (
        <div>
            {!isCustomOpen && <Button variant="outlined" onClick={handleClickOpen}>
                {'NEW_PAINTING'}
            </Button>}
            <Dialog 
                open={open}
                onClose={handleBackdropClick}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{...paperProps}}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    {closeButton && (
                        <Box sx={{ textAlign: 'end', padding: '10px' }}>
                            <IconButton
                                color='inherit'
                                aria-label={CLOSE_MENU}
                                aria-controls=''
                                aria-expanded={true}
                                onClick={handleClose}
                            >
                                <CloseIcon fontSize='large' />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <DialogContent>
                    {dialogContentText && <DialogContentText>{dialogContentText}</DialogContentText>}
                    {dialogContent}
                </DialogContent>
                {buttons && buttons.length > 0 && 
                <DialogActions>
                    {buttons.map((button, index) => (<Button key={index} variant={button.variant} onClick={() => handleButtonClick(button)}>{button.label}</Button>))}
                </DialogActions>
                }
            </Dialog>
        </div>
    )
}

Modal.propTypes = {
    dialogTitle: PropTypes.element.isRequired,
    dialogContent: PropTypes.element,
    dialogContentText: PropTypes.element,
    buttons: PropTypes.array.isRequired,
}