import React, { useState, useEffect } from 'react'
import {  useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CircularProgress from '@mui/material/CircularProgress'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'


import NavBar from '../headers/NavBar'
import Banner from '../headers/Banner'
import Footer from '../footer/Footer'
import SearchForm from '../form/SearchForm'
import ListPaintings from './ListPaintings'
import Pagination from './Pagination'

import getLabel from "../../constants/textMultiLang"
import axios from '../../utils/axios'


const LIMIT = 20


export default function Paintings(props) {
    const theme = useTheme()
    const { lang } = useSelector(state => state.app)
    const [isLoad, setIsLoad] = useState(false)
    const [searchData, setSearchData] = useState({ artists: [], genres: [], currents: [], })
    const [paintingsData, setPaintingsData] = useState([])
    const [paintingsCount, setPaintingsCount] = useState(0)
    const [expanded, setExpanded] = useState(false)
    const query = new URLSearchParams(props.location.search)
    const pageQuery = parseInt(query.get('page') || '1')
    const textQuery = query.get('text') || ''
    const artistQuery = query.get('artists')
    const genreQuery = query.get('genres')
    const currentQuery = query.get('currents')
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
    
    const {
        BOUBLIL_COLLECTION,
        DESCRIPTION_PAINTINGS_PAGE,
        PAINTINGS_PAGE,
        SEARCH2,
        PAINTINGS_FOUND,
        PAINTING_FOUND,
        PAINTING_NOT_FOUND,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'DESCRIPTION_PAINTINGS_PAGE',
        'PAINTINGS_PAGE',
        'SEARCH2',
        'PAINTINGS_FOUND',
        'PAINTING_FOUND',
        'PAINTING_NOT_FOUND',
    ])

    useEffect(() => {
        document.title = `${PAINTINGS_PAGE} | ${BOUBLIL_COLLECTION}`
    }, [BOUBLIL_COLLECTION, PAINTINGS_PAGE])

    useEffect(() => {
        async function getSearchData() {
            const data = { lang }
            const res = await axios.getSearchData(data)
            if (res.code === 200)
                setSearchData(res.searchDatasArrays)
            else
                setSearchData({})
        }
        getSearchData()
    }, [lang])

    useEffect(() => {
        async function getPaintingsData() {
            setIsLoad(false)
            const data = { limit: LIMIT, page: pageQuery, lang, textQuery, artistQuery, genreQuery, currentQuery }
            const res = await axios.getAllPaintings(data)
            if (res.code === 200) {
                setPaintingsData(res.data.paintings)
                setPaintingsCount(res.data.countDocuments)
            } else {
                setPaintingsData([])
                setPaintingsCount(0)
            }
            setIsLoad(true)
        }
        getPaintingsData()
    }, [lang, pageQuery, textQuery, artistQuery, currentQuery, genreQuery])

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <Helmet>
                <meta name="description" content={DESCRIPTION_PAINTINGS_PAGE} />
                <meta name="twitter:title" content={`${PAINTINGS_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta name="twitter:description" content={DESCRIPTION_PAINTINGS_PAGE} />
                <meta property="og:title" content={`${PAINTINGS_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta property="og:description" content={DESCRIPTION_PAINTINGS_PAGE} />
            </Helmet>
            <NavBar />
            <Banner page={PAINTINGS_PAGE} component='h1' />
            <Box component='section' className='dark-section' sx={{ padding: { xs: '50px 0', md: '100px 0' } }}>
                <Container>
                    <Grid container direction={matchesMD ? 'row' : 'column'}>
                        <Grid xs={12} md={4} component='aside' sx={{ paddingRight: { xs: 0, md: '70px' } }}>
                            {matchesMD ? 
                            <>
                                <Typography component='h2' variant='h5'>{SEARCH2}</Typography>
                                <SearchForm searchData={searchData} query={query}  />
                            </>
                            :
                            <>
                                <Typography component='h2' sx={{ maxWidth: { xs: '300px', sm: '500px'}, margin: 'auto' }}>
                                    <Button
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-controls='search-collapse'
                                        variant='primary'
                                        sx={{ paddingLeft: 0, '& .MuiSvgIcon-root': { transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}}
                                    >
                                        <Typography component='p' variant='h5' sx={{ padding: '5px', textTransform: 'none' }}>{SEARCH2}</Typography>
                                        <ExpandMoreIcon />
                                    </Button>
                                </Typography>
                                <Collapse in={expanded} timeout='auto' id='search-collapse'>
                                    <SearchForm searchData={searchData} query={query} />
                                </Collapse>
                            </>
                            }
                        </Grid>
                        <Grid xs={12} md={8}>
                            <Box sx={{ maxWidth: { xs: '300px', sm: '500px', md: '100%', margin: 'auto' } }}>
                                {paintingsCount > 1 ? PAINTINGS_FOUND.replace('nbr', paintingsCount) : (paintingsCount === 1 ? PAINTING_FOUND : (isLoad ? PAINTING_NOT_FOUND : ''))}
                            </Box>
                            <Box sx={{ position: 'relative', minHeight: '400px' }}>
                                <ListPaintings paintingsData={paintingsData} />
                                {!isLoad && 
                                    <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: '100%', backgroundColor: 'rgba(0, 0, 0,.85)' }}>
                                        <Box sx={{ backgroundColor: 'rgba(255,255,255,.1)', width: '100%', height: '100%', paddingTop: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CircularProgress color='inherit' />
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            {paintingsCount > 0 && 
                                <Box sx={{ marginTop: '80px' }}>
                                    <Pagination page={pageQuery} query={query} totalPaintings={paintingsCount} />
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </>
    )
}