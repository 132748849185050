import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet-async"

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'

import NavBar from './headers/NavBar'
import Banner from './headers/Banner'
import Footer from './footer/Footer'
import ContactPageForm from './form/ContactPageForm'

import { HOST } from '../config'
import getLabel from "../constants/textMultiLang";




export default function Contact() {
    const { lang } = useSelector(state => state.app);

    const {
        BOUBLIL_COLLECTION,
        CONTACT_PAGE,
        DESCRIPTION_CONTACT_PAGE,
        INFORMATIONS,
        WRITING,
        SUPPORT,
        ADDRESS,
        EMAIL,
        TEL,
        POST_MESSAGE,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'CONTACT_PAGE',
        'DESCRIPTION_CONTACT_PAGE',
        'INFORMATIONS',
        'WRITING',
        'SUPPORT',
        'ADDRESS',
        'EMAIL',
        'TEL',
        'POST_MESSAGE',
    ])

    useEffect(() => {
        document.title = `${CONTACT_PAGE} | ${BOUBLIL_COLLECTION}`
    }, [BOUBLIL_COLLECTION, CONTACT_PAGE])


    return (
        <>
            <Helmet>
                <meta name="description" content={DESCRIPTION_CONTACT_PAGE} />
                <meta name="twitter:title" content={`${CONTACT_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta name="twitter:description" content={DESCRIPTION_CONTACT_PAGE} />
                <meta property="og:title" content={`${CONTACT_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta property="og:description" content={DESCRIPTION_CONTACT_PAGE} />
            </Helmet>
            <NavBar />
            <Banner page={'Contact us'} component='h1' />
            <Box sx={{ background: `url('${HOST}images/site/bgContactPage.jpg') no-repeat 100% 50%/cover`, position: 'relative' }}>
                <Box sx={{ background: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 1) 100%)', padding: '100px 0' }}>
                    <Container>
                        <Grid container justifyContent='space-between' sx={{ flexDirection: { xs: 'column', md: 'row' }, color: '#fff' }}>
                            <Grid md={5} container direction='column' justifyContent='space-between' className='dark-section' sx={{ backgroundColor: 'transparent', paddingBottom: { xs: '30px', md: 0 }}}>
                                <Grid>
                                    <Typography component='h2' variant='h6' sx={{ paddingBottom: '15px' }}>{INFORMATIONS}</Typography>
                                    <Typography>{EMAIL} : <Link color='inherit' underline='none' href='mailto:contact@boublilcollection.com' dir='ltr'>contact@boublilcollection.com</Link></Typography>
                                    <Typography>{TEL} : <Link color='inherit' underline='none' href='tel:0123456789' dir='ltr'>01 23 45 67 89</Link></Typography>
                                </Grid>
                                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.5)', margin: '15px 0' }} />
                                <Grid>
                                    <Typography component='h2' variant='h6' sx={{ paddingBottom: '15px' }}>{WRITING}</Typography>
                                    <Typography>{EMAIL} : <Link color='inherit' underline='none' href='mailto:redaction@boublilcollection.com' dir='ltr'>redaction@boublilcollection.com</Link></Typography>
                                </Grid>
                                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.5)', margin: '15px 0' }} />
                                <Grid>
                                    <Typography component='h2' variant='h6' sx={{ paddingBottom: '15px' }}>{SUPPORT}</Typography>
                                    <Typography>{EMAIL} : <Link color='inherit' underline='none' href='mailto:support@boublilcollection.com' dir='ltr'>support@boublilcollection.com</Link></Typography>
                                </Grid>
                                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.5)', margin: '15px 0' }} />
                                <Grid>
                                    <Typography component='h2' variant='h6' sx={{ paddingBottom: '15px' }}>{ADDRESS}</Typography>
                                    <Typography>Paris, France</Typography>
                                </Grid>
                            </Grid>
                            <Grid md={5} className='light-section'>
                                <Paper elevation={12} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: { xs: '30px 10px', sm: '50px' }, borderRadius: '8px' }}>
                                    <Typography component='h2' variant='h6' id="form-comment-title" sx={{ paddingBottom: '20px', textAlign: { xs: 'center', md: 'start' } }}>{POST_MESSAGE}</Typography>
                                    <ContactPageForm />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Footer />
        </>
    )
}