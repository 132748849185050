import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// import { localStorageMiddleware } from '../middleware';
import createRootReducer from './reducers/reducer';

import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';


export const history = createBrowserHistory();

// const initialState = {}
const middlewares = [thunk]

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),  // réducteur racine avec état du routeur 
    preloadedState,
    composeWithDevTools(
      compose(
        applyMiddleware(
          routerMiddleware(history),  // pour la répartition des actions d' historique
          // promiseMiddleware,
          ...middlewares,
          // localStorageMiddleware
          // ... autres middlewares . .. 
        ) ,
      )
    ) ,
  )

  return store
}