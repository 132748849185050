import * as React from 'react'
import { useSelector } from "react-redux"
import { Link as LinkRouter } from 'react-router-dom'


import { HOST, DEFAULT_LANG_CODE, PAGES_SITE_NAV, PAGES_INFOS_NAV, RS_LINKS } from '../../config'
import getLabel from "../../constants/textMultiLang"


import { useTheme} from '@mui/material/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import SvgIcon from '@mui/material/SvgIcon';
import { useMediaQuery } from '@mui/material'





export default function Footer() {
    const theme = useTheme()
    const { lang } = useSelector(state => state.app)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang

    const {
        BOUBLIL_COLLECTION,
        NAME_BC_WHITE_LOGO,
        FOLLOW_US,
        FOLLOW_US_ON,
        ADDRESS,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'NAME_BC_WHITE_LOGO',
        'FOLLOW_US',
        'FOLLOW_US_ON',
        'ADDRESS',
    ])

    const pages = PAGES_SITE_NAV.map(page => {
        page.name = getLabel(lang, [page.constName])[page.constName]
        return page
    })

    const infos = PAGES_INFOS_NAV.map(page => {
        page.name = getLabel(lang, [page.constName])[page.constName]
        return page
    })
    
    const RSLinks = RS_LINKS.map(page => {
        page.name = getLabel(lang, [page.constName])[page.constName]
        return page
    })
    

    return (
        <Box component='footer' className='dark-section' sx={{ padding: { xs: '40px 0 0', md: '80px 0 0' } }}>
            <Container>
                <Grid container alignItems='start' rowSpacing={{ xs: 10, sm: 0 }} columnSpacing={{ xs: 0, sm: 2, md: 6 }} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Grid xs={12} sm={7} md>
                        <Link component={LinkRouter} to={`${urlLang}/`} className='hover-none' title={BOUBLIL_COLLECTION} sx={{ display: 'block', margin: { xs: 'auto', sm: 0, md: 'auto'}, width: '7rem', height: 'auto', padding: '10px' }}>
                            <Box component='img' src={`${HOST}images/site/${NAME_BC_WHITE_LOGO}`} alt={BOUBLIL_COLLECTION} />
                        </Link>
                    </Grid>
                    <Divider
                        orientation={useMediaQuery(theme.breakpoints.up('sm')) ? 'vertical' : 'horizontal'}
                        flexItem
                        sx={{ display: { xs: 'none', sm: 'block', md: 'none' }, margin: { xs: 'auto', sm: 0 }, width: { xs: '70%', sm: 'unset' } }}
                    />
                    <Grid xs={12} sm={4} md={3}>
                        <List>
                        {pages.map((page) => (
                            <ListItem key={page.name} sx={{ justifyContent: { xs: 'center', sm: 'start' } }}>
                                <Link 
                                    component={LinkRouter}
                                    underline='none'
                                    noWrap
                                    to={urlLang + page.link[lang]}
                                >
                                    {page.name}
                                </Link>
                            </ListItem>
                        ))}
                        </List>
                    </Grid>
                    <Divider
                        orientation={useMediaQuery(theme.breakpoints.up('sm')) ? 'vertical' : 'horizontal'}
                        flexItem
                        sx={{ display: { xs: 'block', sm: 'none', md: 'block' }, margin: { xs: 'auto', sm: 0 }, width: { xs: '70%', sm: 'unset' } }}
                    />
                    <Grid xs={12} sx={{ display: { xs: 'none', sm: 'block', md: 'none' }, padding: '20px' }}></Grid>
                    <Grid xs={12} sm={7} md={4}>
                        <List>
                        {infos.map((page) => (
                            <ListItem key={page.name} sx={{ justifyContent: { xs: 'center', sm: 'start' } }}>
                                <Link 
                                    component={LinkRouter}
                                    underline='none'
                                    noWrap
                                    to={urlLang + page.link[lang]}
                                >
                                    {page.name}
                                </Link>
                            </ListItem>
                        ))}
                        </List>
                    </Grid>
                    <Divider
                        orientation={useMediaQuery(theme.breakpoints.up('sm')) ? 'vertical' : 'horizontal'}
                        flexItem
                        sx={{ margin: { xs: 'auto', sm: 0 }, width: { xs: '70%', sm: 'unset' } }}
                    />
                    <Grid xs={12} sm={4} md={3}>
                        <Typography sx={{ padding: '16px 16px 0', textAlign: { xs: 'center', sm: 'start' } }}>
                            {FOLLOW_US} :
                        </Typography>
                        <List sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                        {RSLinks.map((page) => (
                            <ListItem
                            key={page.name}
                            sx={{ width: 'auto', paddingRight: '8px', paddingLeft: '8px' }}
                        >
                            <IconButton
                                component={Link}
                                underline="none"
                                target='blank'
                                rel='noopener noreferrer'
                                href={`https://www.${page.link}`}
                                title={FOLLOW_US_ON + page.name}
                                sx={{ width: '2.5rem' }}
                                color="inherit"
                            >
                                <SvgIcon viewBox={page.icon.viewBox}>
                                    {page.icon.path}
                                </SvgIcon>
                            </IconButton>
                        </ListItem>
                        ))}
                        </List>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{ paddingTop: { xs: '20px', md: '50px' }, textAlign: 'center' }}>
                <Typography sx={{ padding: '10px', textAlign: 'center', backgroundColor: '#1e1e1e' }}>
                    &copy; {BOUBLIL_COLLECTION} 2022-{new Date().getFullYear()}&nbsp;/&nbsp;{ADDRESS} ...
                </Typography>
            </Box>
        </Box>
    )
}