import React, { useEffect, useRef } from 'react'
import ReactAudioPlayer from 'react-h5-audio-player'

import 'react-h5-audio-player/lib/styles.css'
// ajouter à la class .rhap_time: width:45px
// ajouter à la class .rhap_play-pause-button: line-height:40px

import getLabel from '../constants/textMultiLang'


const AudioPlayer = (props) => {
    const audio = useRef()
    const { lang, autoPlay, showSkipControls, showJumpControls, inline, onEnded, srcsData } = props

    const {
        PLAYER,
        PROGRESS_CONTROL,
        VOLUME_CONTROL,
        PLAY,
        PAUSE,
        REWIND,
        FORWARD,
        PREVIOUS,
        NEXT,
        LOOP,
        LOOP_OFF,
        VOLUME,
        VOLUME_MUTE
    } = getLabel(lang, [
        'PLAYER',
        'PROGRESS_CONTROL',
        'VOLUME_CONTROL',
        'PLAY',
        'PAUSE',
        'REWIND',
        'FORWARD',
        'PREVIOUS',
        'NEXT',
        'LOOP',
        'LOOP_OFF',
        'VOLUME',
        'VOLUME_MUTE'
    ])
    

    //array of mp3 srcs in the album order
    // srcs = [
    //     'https://assets.codepen.io/4358584/Anitek_-_Komorebi.mp3',
    //     'https://assets.codepen.io/4358584/Anitek_-_Carry_On.mp3',
    //     'https://assets.codepen.io/4358584/Anitek_-_Wood_Note.mp3'
    // ];
    const srcs = srcsData.map(src => src.audio)


    //array of song titles in the album order
    // const titles = [
    //     'Komorebi',
    //     'Carry On',
    //     'Wood Note'
    // ]
    const titles = srcsData.map(src => src.title)

    const artists = srcsData.map(src => src.artist)
    const artworks = srcsData.map(src => src.artwork)

    const previousTrack = () => {
        //find the index of the audio src in our srcs array to know what src to set next
        const index = srcs.indexOf(audio.current.audio.current.src);
        //if the current track is not the first, set the src and corresponding title to that of the previous track of the current track
        if (index !== 0) {
            audio.current.audio.current.src = srcs[index - 1];
            navigator.mediaSession.metadata.title = titles[index - 1];
            navigator.mediaSession.metadata.artist = artists[index - 1];
        }
        //else set the src and title to the last in the arrays because the current track was the first in the album
        else {
            audio.current.audio.current.src = srcs[srcs.length - 1];
            navigator.mediaSession.metadata.title = titles[srcs.length - 1];
            navigator.mediaSession.metadata.artist = artists[srcs.length - 1];
        }
        //play the previous track
        audio.current.audio.current.play();
    }

    const nextTrack = () => {
        //find the index of the audio src in our srcs array to know what src to set next
        const index = srcs.indexOf(audio.current.audio.current.src);

        //if the current track is not the last, set the src and corresponding title to that of the next track of the current track
        if (index < srcs.length - 1) {
            audio.current.audio.current.src = srcs[index + 1];
            navigator.mediaSession.metadata.title = titles[index + 1];
            navigator.mediaSession.metadata.artist = artists[index + 1];
        }
        //else set the src and title to the first in the arrays because the current track was the last in the album
        else {
            audio.current.audio.current.src = srcs[0];
            navigator.mediaSession.metadata.title = titles[0];
            navigator.mediaSession.metadata.artist = artists[0];
        }
        //play the next track
        audio.current.audio.current.play();
    }

    useEffect(() => {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: titles[0],
                artist: artists[0],
                // album: 'MainStay',
                artwork: [
                    { src: artworks[0], sizes: '96x96', type: 'image/png' },
                    { src: artworks[0], sizes: '128x128', type: 'image/png' },
                    { src: artworks[0], sizes: '192x192', type: 'image/png' },
                    { src: artworks[0], sizes: '256x256', type: 'image/png' },
                    { src: artworks[0], sizes: '384x384', type: 'image/png' },
                    { src: artworks[0], sizes: '512x512', type: 'image/png' }
                ]
            });

            navigator.mediaSession.setActionHandler('pause', () => {
                audio.current.audio.current.pause();
            });
            navigator.mediaSession.setActionHandler('play', () => {
                audio.current.audio.current.play();
            });
            navigator.mediaSession.setActionHandler('seekbackward', (details) => {
                audio.current.audio.current.currentTime = audio.current.audio.current.currentTime - (details.seekOffset || 10);
            });
            navigator.mediaSession.setActionHandler('seekforward', (details) => {
                audio.current.audio.current.currentTime = audio.current.audio.current.currentTime + (details.seekOffset || 10);
            });
            navigator.mediaSession.setActionHandler('previoustrack', () => {
                previousTrack()
            });
            navigator.mediaSession.setActionHandler('nexttrack', () => {
                nextTrack()
            });
        }
    })


    return (
        <ReactAudioPlayer
            ref={audio}
            autoPlay={autoPlay}
            volume='0.5'
            // loop
            src={srcs[0]}
            // onPlay={() => console.log("onPlay")}
            onEnded={onEnded ? nextTrack : false}
            showSkipControls={showSkipControls}
            customAdditionalControls={[]}
            showJumpControls={showJumpControls ? true : false}
            layout={inline ? 'horizontal-reverse' : 'stacked-reverse'}
            i18nAriaLabels={{
                player: PLAYER,
                progressControl: PROGRESS_CONTROL,
                volumeControl: VOLUME_CONTROL,
                play: PLAY,
                pause: PAUSE,
                rewind: REWIND,
                forward: FORWARD,
                previous: PREVIOUS,
                next: NEXT,
                loop: LOOP,
                loopOff: LOOP_OFF,
                volume: VOLUME,
                volumeMute: VOLUME_MUTE
            }}
            onClickPrevious={previousTrack}
            onClickNext={nextTrack}
            // other props here
        />
    )

}

export default AudioPlayer