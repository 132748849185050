import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

import Alert from '../global/Alert'
import Snackbar from '../global/Snackbar'

import getLabel from '../../constants/textMultiLang'
import axios from '../../utils/axios'



export default function AddPaintingForm(props) {
    const defaultValues = {
        name_fr: { value: '', messageError: '', isRequired: true },
        name_en: { value: '', messageError: '', isRequired: true },
        name_he: { value: '', messageError: '', isRequired: true },
        artmynId: { value: '', messageError: '', isRequired: true },
    }
    const [open, setOpen] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState({})
    const [snackbarMessage, setSnackbarMessage] = useState({})
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [values, setValues] = useState(defaultValues)
    const { lang } = useSelector(state => state.app)
    const { getPaintings } = props

    const {
        ADD_NEW_PAINTING,
        ADD_PAINTING,
        NAME,
        COMPLETE_FIELD,
        REGISTERED_SUCCESSFULLY,
        CANCEL,
        ADD,
    } = getLabel(lang, [
        'ADD_NEW_PAINTING',
        'ADD_PAINTING',
        'NAME',
        'COMPLETE_FIELD',
        'REGISTERED_SUCCESSFULLY',
        'CANCEL',
        'ADD',
    ])

    const messageAPI = getLabel(lang, ['MISSING_DATA', 'ARTMYN_ID_EXIST', 'PAINTING_NOT_FOUND_ARTMYN', 'IMAGE_FILE_NOT_FOUND', 'IMAGE_FILE_NOT_IMPORTED', 'IMAGE_FILE_NOT_SAVED', 'INTERNAL_ERROR'])

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => {
        setOpenAlert(false)
        setValues(defaultValues)
        setOpen(false)
    }
    
    const handleChange = prop => e => {
        const newValues = values
        newValues[prop].value = e.target.value
        newValues[prop].messageError = ''
        setValues({...newValues})
    }

    function isFormValid() {
        let status = true
        const newValues = values
        for (const property in values) {
            // console.log(property + ': ' + validationStatus[property])
            if(values[property].isRequired && !values[property].value.trim()) {
                status = false
                newValues[property].messageError = COMPLETE_FIELD
            }
        }
        if(!status)
            setValues({...values, ...newValues})
        return status
    }
    function buildFormdata() {
        const formData = new FormData()
        for (const property in values) {
            // console.log(property + ': ' + validationStatus[property])
            formData.append(property, values[property].value.trim())
        }
        return formData
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setOpenAlert(false)
        if(!isFormValid())
            return false
        const formData = buildFormdata()
        const res = await axios.setPainting(formData)
        if(res.code === 200) {
            setSnackbarMessage({ message: REGISTERED_SUCCESSFULLY, severity: 'success' })
            setOpenSnackbar(true)
            getPaintings()
            handleClose()
            return true
        } else {
            setAlertMessage({ message: messageAPI[res.message], severity: 'error' })
            setOpenAlert(true)
            return false
        }
    }


    return (
        <div>
            <Box sx={{ textAlign: 'end' }}>
                <Button variant="outlined" onClick={handleClickOpen}>
                    <AddOutlinedIcon /><Typography component='span' variant='body' sx={{ marginTop: '4px', marginLeft: '5px' }}>{ADD_PAINTING}</Typography>
                </Button>
            </Box>
            <Dialog open={open}>
                <DialogTitle>{ADD_NEW_PAINTING}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>To subscribe to this website, please enter your email address here. We will send updates occasionally.</DialogContentText> */}
                    <Box
                        component='form'
                        id='add-painting-form'
                        sx={{ textAlign: 'center' }}
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            autoFocus
                            label={`${NAME} fr`}
                            name='name_fr'
                            type='text'
                            // fullWidth
                            variant="standard"
                            required
                            sx={{ m: 2 }}
                            value={values.name_fr.value}
                            onChange={handleChange('name_fr')}
                            error={Boolean(values.name_fr.messageError)}
                            helperText={values.name_fr.messageError}
                        />
                        <TextField
                            label={`${NAME} en`}
                            name='name_en'
                            type='text'
                            // fullWidth
                            variant="standard"
                            required
                            sx={{ m: 2 }}
                            value={values.name_en.value}
                            onChange={handleChange('name_en')}
                            error={Boolean(values.name_en.messageError)}
                            helperText={values.name_en.messageError}
                        />
                        <TextField
                            label={`${NAME} he`}
                            name='name_he'
                            type='text'
                            // fullWidth
                            variant="standard"
                            required
                            sx={{ m: 2 }}
                            value={values.name_he.value}
                            onChange={handleChange('name_he')}
                            error={Boolean(values.name_he.messageError)}
                            helperText={values.name_he.messageError}
                        />
                        <TextField
                            label={'Artmyn id'}
                            name='artmynId'
                            type='text'
                            // fullWidth
                            variant="standard"
                            required
                            sx={{ m: 2 }}
                            value={values.artmynId.value}
                            onChange={handleChange('artmynId')}
                            error={Boolean(values.artmynId.messageError)}
                            helperText={values.artmynId.messageError}
                        />
                        <Box component='input' sx={{ display: 'none' }} type='submit' />
                        <Alert open={openAlert} setOpen={setOpenAlert} message={alertMessage} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{CANCEL}</Button>
                    <Button onClick={handleSubmit}>{ADD}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
        </div>
    )
}