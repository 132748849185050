import {
    APP_LOAD,
    SET_LANG,
    SET_THEME_MODE,
    SET_OPEN_DRAWER
} from "../../constants/actionTypes"



const initialState = { appLoad: false, lang: null, themeMode: null }

export default function app (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case APP_LOAD:
            return {
                ...state,
                appLoad: true
            }
        case SET_LANG:
            return {
                ...state,
                lang: payload,
            }
        case SET_THEME_MODE:
            return {
                ...state,
                themeMode: payload,
            }
        case SET_OPEN_DRAWER:
            return {
                ...state,
                openDrawer: payload,
            }
        default:
            return state;
    }
}
