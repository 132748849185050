import React, { useEffect } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { useTheme } from '@mui/material/styles'

import { HOST, DEFAULT_LANG_CODE, LANG_ARRAY_CODE_DIR_RTL, PAGES_ROUTES_ARRAY } from '../../config'
import getLabel from '../../constants/textMultiLang'

import NavBar from '../headers/NavBar'
import Banner from '../headers/Banner'
import Footer from '../footer/Footer'
import H2Title from '../H2Title'
import MorePaintings from '../MorePaintings'
import ContactForm from '../form/ContactForm'

import { ArrowDoubleRightIcon } from '../../theme/icons'




export default function Home() {
    const theme = useTheme()
    const { lang } = useSelector(state => state.app)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang

    const {
        BOUBLIL_COLLECTION,
        HOME_PAGE,
        DESCRIPTION_HOME_PAGE,
        JOSE_BOUBLIL,
        BY2,
        DETAIL_OF_PAINTING,
        ABOUT_PAGE,
        MR_BOUBLIL,
        CONTACT_ME,
        DISCOVER,
        THE_PAINTINGS_FOLLOW,
        ALL_THE_PAINTINGS,
        POST_MESSAGE,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'HOME_PAGE',
        'DESCRIPTION_HOME_PAGE',
        'JOSE_BOUBLIL',
        'BY2',
        'DETAIL_OF_PAINTING',
        'ABOUT_PAGE',
        'MR_BOUBLIL',
        'CONTACT_ME',
        'DISCOVER',
        'THE_PAINTINGS_FOLLOW',
        'ALL_THE_PAINTINGS',
        'POST_MESSAGE',
    ])

    const getLangPath = (pageName) => {
        return PAGES_ROUTES_ARRAY.filter(e => e.constName === pageName)[0].link[lang]
    }

    useEffect(() => {
        document.title = `${HOME_PAGE} | ${BOUBLIL_COLLECTION}`
    }, [BOUBLIL_COLLECTION, HOME_PAGE])
   
    return (
        <>
            <Helmet>
                <meta name='description' content={DESCRIPTION_HOME_PAGE} />
                <meta name='twitter:title' content={`${HOME_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta name='twitter:description' content={DESCRIPTION_HOME_PAGE} />
                <meta property='og:title' content={`${HOME_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta property='og:description' content={DESCRIPTION_HOME_PAGE} />
            </Helmet>
            <NavBar />
            <Banner page={HOME_PAGE} />
            <Box component='section' className='dark-section' sx={{ padding: { xs: '50px 0', md: '100px 0' } }}>
                <Container>
                    <H2Title row1={''} row2={'Portrait en pied de Marcel Proust'} row3={`${BY2}Jacques Emile Blanche`} />
                    <Grid container sx={{ padding: '50px 0 100px' }}>
                        <Grid md={6}>
                            
                        </Grid>
                        <Grid md={6}>
                            <Typography gutterBottom>
                                Ce tableau de ...x.... représente Marcel Proust en habit sombre, posant près d'un
                                caniche noir. Le tableau est daté 1889 (selon nous) , et sous titré P Remcal , pour
                                Marcel P. On reconnait d'une part Marcel Proust, peint une autre fois par Blanche.
                                Et aussi, l'écrivain et le caniche sont exactement la meme image que Mettling
                                et son caniche.
                            </Typography>
                            <Typography gutterBottom>
                                Ce tableau est une énigme, notamment par l'anagramme à la place de Marcel
                                Proust.
                            </Typography>
                            <Typography gutterBottom>
                                La qualité exceptionnelle de l'oeuvre, ainsi que la signature en entier
                                du peintre, parfaitement dans la pâte, et avec le graphisme de Blanche,
                                écarte toute copie ou plagiat.
                            </Typography>
                            <Typography gutterBottom>
                                Une experte, ...., et son amie, ...., ont indiqué que ce tableau était
                                incohérent notamment parce que Marcel Proust détestait les chiens.
                                Remarque d'une rare malhonnêteté intellectuelle:
                                - D'une part, je n'ai jamais entendu qu'une pause avec un chien
                                nécessitait l'amour des chiens
                                - D'autre part, surtout, nous avons retrouvé une lettre très sympathique,
                                que Proust envoie au chien de son ami, qu'il aime beaucoup.Il s'agit de Zadig, le
                                chien de Reynaldo Hahn...La lettre commence par :
                                "Mon cher Zadig, je t'aime beaucoup parce que...."
                                Pour certains "experts", refuser un tableau, même important,
                                est un sport qui émane de la jalousie, et d'un ego démesuré
                                (ici, l'expert n'avait pas été consultée avant la vente aux enchères;
                                elle a curieusement refusé le tableau, et trpouve des arguments
                                stupides pour maintenir sa position).
                            </Typography>
                            <Box sx={{ textAlign: 'end', paddingTop: '50px' }}>
                                <Button
                                    component={LinkRouter}
                                    to={urlLang + getLangPath('PAINTINGS_PAGE')}
                                    variant='contained'
                                    color='secondary'
                                    size='large'
                                    endIcon={<ArrowDoubleRightIcon transform={ LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } />}
                                    sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', justifyContent: 'space-between', borderRadius: '0 0 3rem 0' }}
                                >
                                    <Typography component='span' sx={{ paddingRight: '40px', textAlign: 'start' }}>{DETAIL_OF_PAINTING}</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <H2Title row1={''} row2={'Conchita'} row3='Par Picasso' />
                    <Grid container sx={{ padding: '50px 0 70px' }}>
                        <Grid md={6}>
                            
                        </Grid>
                        <Grid md={6}>
                            <Typography gutterBottom>
                                Ce tableau est une oeuvre de jeunesse de Pablo Picasso, réalisé en 1896, lorsqu'il a
                                environ 15 ans.
                            </Typography>
                            <Typography gutterBottom>
                                Le portrait de cet homme au chapeau mesure ....x....
                                Il s'agit d'une huile sur toile encadré dans un cadre d'époque.
                            </Typography>
                            <Typography gutterBottom>
                                Acheté auprès d'un marchand en Espagne, nous avons effectué
                                les recherches qui se caractérisent pas:<br />
                                La première chose essentielle est la très grande force qui se dégage du tableau,
                                ce qui est une indication sur le niveau du peintre.
                            </Typography>
                            <Typography gutterBottom>
                                Nous avons découvert , en regardant la signature "Conchita" et la date 1896,
                                qu'il existe une seule Conchita célèbre dans l'histoire de l'art: c'est la soeur de
                                Picasso, morte de maladie à l'âge de 7 ans en 1895, qui a brisé le coeur du grand
                                frère. Ce traumatisme l'a amené ici à signer son tableau par le nom de sa soeur.
                            </Typography>
                            <Typography gutterBottom>
                                Voici une enveloppe à plusieurs couleurs pour les chiffres et les lettres.
                            </Typography>
                            <Box sx={{ textAlign: 'end', paddingTop: '50px' }}>
                                <Button
                                    component={LinkRouter}
                                    to={urlLang + getLangPath('PAINTINGS_PAGE')}
                                    variant='contained'
                                    color='secondary'
                                    size='large'
                                    endIcon={<ArrowDoubleRightIcon transform={ LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } />}
                                    sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', justifyContent: 'space-between', borderRadius: '0 0 3rem 0' }}
                                >
                                    <Typography component='span' sx={{ paddingRight: '40px', textAlign: 'start' }}>{DETAIL_OF_PAINTING}</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box component='section' className='light-section' sx={{ padding: { xs: '50px 0', md: '100px 0' } }}>
                <Container>
                    <H2Title row1={ABOUT_PAGE} row2={MR_BOUBLIL} row3='' />
                    <Grid container sx={{ paddingTop: '50px' }}>
                        <Grid md={6} sx={{ margin: 'auto' }}>
                            <Box sx={{ maxWidth: { xs: '100%', md: '50%' }, margin: 'auto', paddingBottom: '50px' }}>
                                <picture>
                                    <source media={theme.breakpoints.up('sm').replace('@media ', '')} type="image/svg+xml" srcSet={`${HOST}images/site/jose_photo.svg`} />
                                    <source media={theme.breakpoints.up('xs').replace('@media ', '')} type="image/svg+xml" srcSet={`${HOST}images/site/jose_photo.svg`} />
                                    <source media={theme.breakpoints.up('sm').replace('@media ', '')} type="image/png" srcSet={`${HOST}images/site/jose_photo.png`} />
                                    <source media={theme.breakpoints.up('xs').replace('@media ', '')} type="image/png" srcSet={`${HOST}images/site/jose_photo.png`} />
                                    <Box component='img' src={`${HOST}images/site/jose_photo.svg`} alt={JOSE_BOUBLIL} />
                                </picture>
                            </Box>
                        </Grid>
                        <Grid container md={6} direction='column' justifyContent='space-evenly'>
                            <Grid>
                                <Typography gutterBottom>
                                    Lorem ipsum dolor sit amet, consectetur dolore adipisicing eli sed
                                    do eiusmod tempor incididunt uAt labore et dolore si magna aliqua.
                                    Ut enim ad minim veniam, quis ester nostrud e exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo ut consequat in Duis aute irure
                                    dolor in ut mag. Ut enim ad minim veniam, quis ester nostrud epse
                                </Typography>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur ester adipisicing eli sed do
                                    eiusmod tempor incididunt ut labore et dolore si magna aliqua.
                                    Ut enim ad minim veniam, quis ester nostrud e exercitation
                                </Typography>
                            </Grid>
                            <Grid>
                            <Box sx={{ textAlign: 'end', paddingTop: '50px' }}>
                                <Button
                                    component={LinkRouter}
                                    to={urlLang + getLangPath('CONTACT_PAGE')}
                                    variant='contained'
                                    color='primary'
                                    size='large'
                                    endIcon={<ArrowDoubleRightIcon transform={ LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } />}
                                    sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', justifyContent: 'space-between', borderRadius: '0 0 3rem 0' }}
                                >
                                    <Typography component='span' sx={{ paddingRight: '40px', textAlign: 'start' }}>{CONTACT_ME}</Typography>
                                </Button>
                            </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box component='section' className='dark-section' sx={{ padding: { xs: '50px 0', md: '100px 0' } }}>
                <Container>
                    <H2Title row1={DISCOVER} row2={THE_PAINTINGS_FOLLOW} row3='' />
                    <Box sx={{ padding: { xs: '30px 0', md: '60px 0'} }}>
                        <MorePaintings nbrImg={6} />
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            component={LinkRouter}
                            to={urlLang + getLangPath('PAINTINGS_PAGE')}
                            variant='contained'
                            color='secondary'
                            size='large'
                            endIcon={<ArrowDoubleRightIcon transform={ LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } />}
                            sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', justifyContent: 'space-between', borderRadius: '0 0 3rem 0' }}
                        >
                            <Typography component='span' sx={{ paddingRight: '40px', textAlign: 'start' }}>{ALL_THE_PAINTINGS}</Typography>
                        </Button>
                    </Box>
                </Container>
            </Box>
            <Box component='section' className='light-section' sx={{ background: '#f7c370 url("' + HOST + 'images/site/navBarBg.jpg") no-repeat fixed 50%/cover' }}>
                <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: '20vh 0' }}>
                    <Container>
                        <Paper sx={{ backgroundColor: theme.palette.secondary.main, padding: { xs: '30px 10px', sm: '50px' } }}>
                            <Typography component='h2' variant='h6' id="form-message-title" sx={{ paddingBottom: '20px', textAlign: { xs: 'center', md: 'start' } }}>{POST_MESSAGE}</Typography>
                            <ContactForm />
                        </Paper>
                    </Container>
                </Box>
            </Box>
            <Footer />
        </>
    )
}