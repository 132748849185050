import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'




export default function H2Title(props) {
    return (
        <>
            <Typography component='h2' sx={{ textAlign: 'center'}}>
                { props.row1 && <Typography component='span'>{props.row1}</Typography>}
                <Grid component='span' container alignItems='center' columnGap={3}>
                    <Grid className='hr-line' component='span' sx={{ display: { xs: 'none', md: 'inline' }, flexGrow: 1 }} />
                    <Grid component='span' sx={{ margin: 'auto', maxWidth: { xs: '100%', md: '45%'} }}>
                        <Typography component='span' variant="h4" sx={{ textAlign: 'center' }}>{props.row2}</Typography>
                    </Grid>
                    <Grid className='hr-line' component='span' sx={{ display: { xs: 'none', md: 'inline' }, flexGrow: 1 }} />
                </Grid>
                { props.row3 && <Typography component='span'>{props.row3}</Typography>}
            </Typography>
            <Divider className='hr-line' sx={{ width: '80%', display: { xs: 'block', md: 'none' }, margin: 'auto', paddingTop: '40px' }} />
        </>
    )
}


H2Title.propTypes = {
    row1: PropTypes.string.isRequired,
    row2: PropTypes.string.isRequired,
    row3: PropTypes.string.isRequired,
}