import React, { useState, useEffect } from 'react'
import { Link as LinkRouter, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useTheme } from '@mui/material/styles'

import { HOST, SHARE_LINKS, DEFAULT_LANG_CODE, LANG_ARRAY_CODE_DIR_RTL, PAGES_ROUTES_ARRAY } from '../../config'
import getLabel from '../../constants/textMultiLang'
import { convertToUrl } from '../../utils/app'
import axios from '../../utils/axios'

import NavBar from '../headers/NavBar'
import Banner from '../headers/Banner'
import Footer from '../footer/Footer'
import NotFound from '../NotFound'
import CommentairesForm from '../form/CommentairesForm'
import MorePaintings from '../MorePaintings'
import H2Title from '../H2Title'
import AudioPlayer from '../AudioPlayerSession'
import * as shareIcons from '../../theme/icons'
import { ArrowDoubleRightIcon } from '../../theme/icons'


export default function Painting(props) {

    const theme = useTheme()
    const [onLoad, setOnload] = useState(false)
    const [paintingData, setPaintingData] = useState({})
    const [pageUrl, setPageUrl] = useState('')
    const [shareLinks, setShareLinks] = useState([])
    const { lang } = useSelector(state => state.app)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang
    const { uuidPainting, paintingName } = props.match.params
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })

    const {
        BOUBLIL_COLLECTION,
        PAINTING_PAGE,
        DESCRIPTION_PAINTING_PAGE,
        ARTIST,
        GENRE,
        CURRENT,
        SHARE_PAGE,
        ON,
        BY,
        LISTEN_DESCRIPTION,
        DESCRIPTION,
        PAINTING_COMMENTS,
        POST_COMMENT,
        DISCOVER,
        THE_PAINTINGS_FOLLOW,
        ALL_THE_PAINTINGS
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'PAINTING_PAGE',
        'DESCRIPTION_PAINTING_PAGE',
        'ARTIST',
        'GENRE',
        'CURRENT',
        'SHARE_PAGE',
        'ON',
        'BY',
        'LISTEN_DESCRIPTION',
        'DESCRIPTION',
        'PAINTING_COMMENTS',
        'POST_COMMENT',
        'DISCOVER',
        'THE_PAINTINGS_FOLLOW',
        'ALL_THE_PAINTINGS'
    ])

    const preposition = { ON, BY }

    const getLangPath = (pageName) => {
        return PAGES_ROUTES_ARRAY.filter(e => e.constName === pageName)[0].link[lang]
    }

    useEffect(() => {
        async function getPainting() {
            const res = await axios.getPaintingByUuid(uuidPainting)
            if (res.code === 200)
                setPaintingData(res.tableau)
            else 
                setPaintingData({})
            setOnload(prevOnload => !prevOnload && true)
        }
        getPainting()
    }, [uuidPainting])

    useEffect(() => {
        if(paintingData && paintingData.name && paintingData.name[lang]) {
            const pageUrl = convertToUrl(paintingData.name[lang])
            setPageUrl(pageUrl)
            document.title = `${paintingData.name[lang]} | ${BOUBLIL_COLLECTION}`
        } else {
            document.title = BOUBLIL_COLLECTION
        }
    }, [paintingData, setPageUrl, setOnload, lang, BOUBLIL_COLLECTION])

    useEffect(() => {
        if(shareLinks.length === 0) {
            const shareLinksTemp = SHARE_LINKS.map(share => {
                share.name = getLabel(lang, [share.constName])[share.constName]
                share.component = shareIcons[share.componentName]
                share.text = share.text ? getLabel(lang, [`SHARE_PAGE_${share.constName}_TEXT`])[`SHARE_PAGE_${share.constName}_TEXT`] : ''
                share.subject = share.subject ? getLabel(lang, [`SHARE_PAGE_${share.constName}_SUBJECT`])[`SHARE_PAGE_${share.constName}_SUBJECT`] : ''
                return share
            })
            setShareLinks(shareLinksTemp)
        }
    }, [shareLinks, lang])

    if (paintingData && paintingData.name && paintingData.name[lang] && pageUrl) {
        if(paintingName !== pageUrl) {
            return <Redirect to={`${urlLang}${getLangPath('PAINTING_PAGE')}/${uuidPainting}/${pageUrl}`} />
        }
    }

    const pageUrlComplet = `www.boublilcollection.com${urlLang}${getLangPath('PAINTING_PAGE')}/${uuidPainting}/${pageUrl && pageUrl}`
    

    return (
        <>
            {onLoad && (!paintingData || !paintingData.name) && (
                <NotFound />
            )}
            {onLoad && paintingData && paintingData.name && (
            <>
            <Helmet>
                <meta name="description" content={DESCRIPTION_PAINTING_PAGE} />
                <meta name="twitter:title" content={`${paintingData.name[lang]} | ${BOUBLIL_COLLECTION}`} />
                <meta name="twitter:description" content={DESCRIPTION_PAINTING_PAGE} />
                <meta property="og:title" content={`${paintingData.name[lang]} | ${BOUBLIL_COLLECTION}`} />
                <meta property="og:description" content={DESCRIPTION_PAINTING_PAGE} />
            </Helmet>
            <NavBar />
            <Banner page={PAINTING_PAGE} />
            <Box component='section' sx={{ padding: '50px 0' }}>
                <Box className='light-section' sx={{ padding: { xs: 0, md: '50px 0' } }}>
                    <Container>
                        {!matchesMD &&
                        <Box sx={{ paddingBottom: '25px' }}>
                            <Typography component='h1' variant='h4' className="painting-title" sx={{ paddingBottom: '25px', textAlign: { xs: 'center', md: 'start' } }}>
                                {paintingData.name && paintingData.name[lang]}
                                {paintingData.artist && (paintingData.artist.firstName || paintingData.artist.firstName) && ' - '}
                                <span dir='ltr' lang={lang === 'he' ? 'en' : lang}>
                                    {paintingData.artist && paintingData.artist.firstName && paintingData.artist.firstName}
                                    {paintingData.artist && paintingData.artist.name && ' ' + paintingData.artist.name}
                                </span>
                            </Typography>
                            <Divider className='hr-line' sx={{ width: '80%', margin: { xs: 'auto', md: 0 } }} />
                        </Box>
                        }
                        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row-reverse' }, minHeight: { xs: '0', md: '600px' } }}>
                            <Grid container direction='column' md={6} sx={{ paddingLeft: { md: '30px' } }}>
                                {matchesMD &&
                                <Grid sx={{ paddingBottom: '25px' }}>
                                    <Typography component='h1' variant='h4' className="painting-title" sx={{ paddingBottom: '25px', textAlign: { xs: 'center', md: 'start' } }}>
                                        {paintingData.name && paintingData.name[lang]}
                                        {paintingData.artist && (paintingData.artist.firstName || paintingData.artist.firstName) && ' - '}
                                        <span dir='ltr' lang={lang === 'he' ? 'en' : lang}>
                                            {paintingData.artist && paintingData.artist.firstName && paintingData.artist.firstName}
                                            {paintingData.artist && paintingData.artist.name && ' ' + paintingData.artist.name}
                                        </span>
                                    </Typography>
                                    <Divider sx={{ width: '80%', margin: { xs: 'auto', md: 0 }, borderColor: theme.palette.secondary.contrastText}} />
                                </Grid>
                                }
                                <Grid sx={{ fontSize: '1.25rem', '& .MuiTypography-root': { fontSize: '1.15rem' } }}>
                                    <Grid container direction='column' sx={{ alignItems: { xs: 'center', md: 'start' } }}>
                                        <Typography>
                                            <Typography component='span'>{ARTIST} : </Typography>
                                            <Typography component='span' dir='ltr' lang={lang === 'he' ? 'en' : lang} sx={{ fontWeight: 'bold' }}>
                                                {paintingData.artist && paintingData.artist.firstName && paintingData.artist.firstName}
                                                {paintingData.artist && paintingData.artist && paintingData.artist.name && ' ' + paintingData.artist.name}
                                            </Typography>
                                        </Typography>
                                        <Typography>
                                            <Typography component='span'>{GENRE} : </Typography>
                                            <Typography component='span' sx={{ fontWeight: 'bold' }}>
                                                {paintingData.genre && paintingData.genre.name && paintingData.genre.name[lang]}
                                            </Typography>
                                        </Typography>
                                        <Typography>
                                            <Typography component='span'>{CURRENT} : </Typography>
                                            <Typography component='span' sx={{ fontWeight: 'bold' }}>
                                                {paintingData.current && paintingData.current.name && paintingData.current.name[lang]}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ flexGrow: 1, minHeight: '30px' }}></Grid>
                                <Grid sx={{ marginTop: '20px' }}>
                                    <Grid container sx={{ flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', alignItems: 'center' }}>
                                        <Grid md={2}>
                                            <Typography><i>{SHARE_PAGE} : </i></Typography>
                                        </Grid>
                                        <Grid md={10}>
                                            {paintingData && lang && (
                                            <List sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                {shareLinks.length > 0 && shareLinks.map((share) => (
                                                        <ListItem key={share.name} disablePadding sx={{ width: 'auto' }}>
                                                        <IconButton 
                                                            component={Link}
                                                            underline="none"
                                                            target='blank'
                                                            rel='noopener noreferrer'
                                                            href={`${share.link.replace('§§link§§', pageUrlComplet).replace('§§text§§', share.text && share.text).replace('§§subject§§', share.subject && share.subject)}`}
                                                            title={SHARE_PAGE + ' ' + preposition[share.preposition] + share.name}
                                                            sx={{ width: '2.5rem' }}
                                                            color="inherit"
                                                            data-action={share.dataAction ? share.dataAction : ''}
                                                        >
                                                            <share.component />
                                                        </IconButton>
                                                    </ListItem>
                                                ))}
                                            </List>)}
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ marginTop: { xs: '10px', sm: 0 }, flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', alignItems: 'center' }}>
                                        <Grid md={2}>
                                            <Typography><i>{LISTEN_DESCRIPTION} : </i></Typography>
                                        </Grid>
                                        <Grid xs={12} sm={9} md={10} dir='ltr' sx={{ width: '100%', '& .rhap_container': { boxShadow: 0 }, '& .rhap_additional-controls': { flex: 0 } }}>
                                            {paintingData && paintingData.audioPaintings && lang && paintingData.audioPaintings[lang] && 
                                            <AudioPlayer 
                                                srcsData={[{
                                                    title: paintingData.name[lang],
                                                    artist: paintingData.artist.firstName + ' ' + paintingData.artist.name,
                                                    audio: HOST + 'audios/' + paintingData.audioPaintings[lang],
                                                    artwork: HOST + 'images/' + paintingData.thumbLink
                                                }]}
                                                inline={matchesSM ? true : false}
                                                lang={lang}
                                            /> 
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid md={6}>
                                <Box lang='en' dir='ltr' sx={{width: '100%', height: '100%', marginBottom: { xs: '20px', md: 0 } }}>
                                    {paintingData.artmynId && 
                                    <Box
                                        component='iframe'
                                        style={{width: '100%', height: '100%', minHeight: '300px', border: 'none'}}
                                        src={`https://artmyn.com/embed/${paintingData.artmynId}?face=clean&auto_start=0&auto_play=0&t_id=*&cartel=0&partner=1&share=0&close=1&tutorial=1`}
                                        title={paintingData.name[lang]} 
                                        allowFullScreen={true} webkitallowfullscreen='true' mozallowfullscreen='true'
                                    />}
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ margin: 'auto', width: { xs: '100%', md: '80%' }, paddingTop: '30px' }}>
                            <Typography component='h2' variant="h6" sx={{ padding: '20px 0', textAlign: { xs: 'center', md: 'start' } }}>{DESCRIPTION}</Typography>
                            <Typography sx={{textAlign:'justify'}}>
                                {paintingData && paintingData.description && lang && paintingData.description[lang]}
                            </Typography>
                        </Box>
                        <Box sx={{ margin: 'auto', width: { xs: '100%', md: '80%' }, padding: '50px 0' }}>
                            <Box sx={{ width: { sm: '70%', xs: '100%' }, marginLeft: 'auto' }}>
                                <Typography sx={{textAlign:'justify'}}>
                                    {paintingData && paintingData.text && lang && paintingData.text[lang]}
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box sx={{ paddingTop: '50px' }} className='dark-section'>
                    <Container>
                        <Box 
                            sx={{ 
                                background: 'url("data:image/svg+xml,%3Csvg style=\'fill:' + theme.palette.primary.contrastText.replace('#', '%23') + '\' xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 890.55 1229.73\'%3E%3Cpath d=\'M36.21,1229.73V1.04H684.97c112.78,0,204.54,91.76,204.54,204.54V1229.73h1.04V205.57C890.55,92.22,798.33,0,684.97,0H35.18V1229.73h1.04Z\' /%3E%3Cpath d=\'M24.49,1229.73V11.77H681.94c107.99,0,195.85,87.86,195.85,195.85v1022.11h1.04V207.62c0-108.56-88.32-196.89-196.89-196.89H23.45V1229.73h1.04Z\' /%3E%3Cpath d=\'M12.76,1229.73V22.5H676.22c104.68,0,189.84,85.16,189.84,189.84v1017.39h1.04V212.34c0-105.25-85.63-190.87-190.87-190.87H11.73V1229.73h1.04Z\' /%3E%3Cpath d=\'M1.04,1229.73V33.24H672.47c100.28,0,181.87,81.59,181.87,181.87v1014.63h1.04V215.11c0-100.85-82.05-182.9-182.9-182.9H0V1229.73H1.04Z\' /%3E%3C/svg%3E%0A") 0 0/100% no-repeat',
                                transform: LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)'
                            }}
                        >
                            <Typography component='p' sx={{ fontFamily: "Neue-Metana,'Ellinia', sans-serif", fontWeight: 700, width: '80%', margin: 'auto', padding: '10% 10px 5% 0', textAlign:'justify', transform: LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' }}>
                                {paintingData && paintingData.citation && lang && paintingData.citation[lang]}
                            </Typography>
                        </Box>
                    </Container>
                </Box>
            </Box>
            <Box component='section' className='light-section' sx={{ padding: { xs: 0, md: '50px 0' } }}>
                <Container>
                    <Typography component='h2' variant='h6' sx={{ paddingBottom: '20px', textAlign: { xs: 'center', md: 'start' } }}>{PAINTING_COMMENTS}</Typography>
                    <Box sx={{ padding: { xs: 0, sm: '0 100px', md: 0 } }}>
                        <Box sx={{ padding: '30px 0' }}>
                            <Typography sx={{ fontWeight: 'bold', textAlign: { xs: 'center', md: 'start' } }}>DANNY BENNETT</Typography>
                            <Typography sx={{ padding: '10px 0', fontStyle: 'italic' }}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqu ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                            </Typography>
                        </Box>
                        <Divider sx={{ borderColor: theme.palette.secondary.contrastText, margin: 'auto', width: { xs: '80%', md: '100%' } }} />
                        <Box sx={{ padding: '30px 0' }}>
                            <Typography sx={{ fontWeight: 'bold', textAlign: { xs: 'center', md: 'start' } }}>DANNY BENNETT</Typography>
                            <Typography sx={{ padding: '10px 0', fontStyle: 'italic' }}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqu ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                            </Typography>
                        </Box>
                        <Divider sx={{ borderColor: theme.palette.secondary.contrastText, margin: 'auto', width: { xs: '80%', md: '100%' } }} />
                        <Box sx={{ padding: '30px 0' }}>
                            <Typography sx={{ fontWeight: 'bold', textAlign: { xs: 'center', md: 'start' } }}>DANNY BENNETT</Typography>
                            <Typography sx={{ padding: '10px 0', fontStyle: 'italic' }}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqu ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box component='section' className='light-section' sx={{ background: '#f7c370 url("' + HOST + 'images/site/navBarBg.jpg") no-repeat fixed 50%/cover' }}>
                <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: '50px 0' }}>
                    <Container>
                        <Paper sx={{ backgroundColor: theme.palette.secondary.main, padding: { xs: '30px 10px', sm: '50px' } }}>
                            <Typography component='h2' variant='h6' id="form-comment-title" sx={{ paddingBottom: '20px', textAlign: { xs: 'center', md: 'start' } }}>{POST_COMMENT}</Typography>
                            <CommentairesForm />
                        </Paper>
                    </Container>
                </Box>
            </Box>
            <Box component='section' className='dark-section' sx={{ padding: { xs: '50px 0', md: '100px 0' } }}>
                <Container>
                    <H2Title row1={DISCOVER} row2={THE_PAINTINGS_FOLLOW} row3='' />
                    <Box sx={{ padding: { xs: '30px 0', md: '60px 0'} }}>
                        <MorePaintings nbrImg={3} />
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            component={LinkRouter}
                            to={urlLang + getLangPath('PAINTINGS_PAGE')}
                            variant='contained'
                            color='secondary'
                            size='large'
                            endIcon={<ArrowDoubleRightIcon transform={ LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } />}
                            sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', justifyContent: 'space-between', borderRadius: '0 0 3rem 0' }}
                        >
                            <Typography component='span' sx={{ paddingRight: '40px', textAlign: 'start' }}>{ALL_THE_PAINTINGS}</Typography>
                        </Button>
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>)}
        </>
    )
}