import React from 'react'

import { HOST } from '../../config'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import NavBar from '../headers/NavBar'




const Banner = (props) => {
    const { page, component } = props

    return (
        <Box component='section' sx={{position: 'relative', background: '#f7c370 url("' + HOST + 'images/site/navBarBg.jpg") no-repeat 50%/cover', padding:'0', color: '#fff'}}>
            <Box sx={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
            <NavBar fake />
            <Container>
                <Box sx={{padding: { md: '70px 0', xs: '20px 0' }}}>
                    <Typography component={component ? component : 'p'} variant="h3" textAlign='center'>{page}</Typography>
                </Box>
            </Container>
            </Box>
        </Box>
    )
}

export default Banner