import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Link as LinkRouter } from 'react-router-dom'
import { history } from '../../redux/store'


import { setLanguage } from '../../utils/app'
import { setLang } from '../../redux/actions/app'
import { HOST, LANG_ARRAY, DEFAULT_LANG_CODE } from '../../config'

import getLabel from '../../constants/textMultiLang'

import { alpha, styled, useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Grow from '@mui/material/Grow';
import SvgIcon from '@mui/material/SvgIcon';
import AdbIcon from '@mui/icons-material/Adb';
import Slide from '@mui/material/Slide';
import { borderBottom } from '@mui/system'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';






const DropdownLang = (props) => {
    const dispatch = useDispatch()
    const theme = useTheme()

    let { lang } = useSelector(state => state.app)

    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const { fake } = props

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return
        setOpen(false)
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false)
            anchorRef.current.focus()

        prevOpen.current = open
    }, [open])

    // const matchesMD = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
    // console.log(matchesMD)

    const handleLang = e => {
        e.preventDefault()
        handleToggle()
        const newLang = e.target.lang
        console.log(newLang)
        console.log(lang)
        if(newLang === lang)
            return
        localStorage.setItem('lang', newLang)
        const userLang = setLanguage(history)
        dispatch(setLang(userLang))
    }

    return (
        <Box sx={{position: 'relative'}}>
            <IconButton
                ref={anchorRef}
                id={`search-button${!fake ? '' : '-fake'}`}
                size='large'
                // aria-label={'Choose your language. Actual choice: ' + lang}
                // aria-controls={open ? 'menu-lang' : undefined}
                // aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="inherit"
            >
                <Box component='img' sx={{ width: {xs: '1.3rem', md: '1.5rem'} }} src={`${HOST}images/site/search.svg`} alt='dropdown arrow' />
            </IconButton>
            {/* 
            {!fake && (
            <Popper
                // sx={{ inset: '0 !important' }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                keepMounted
            >
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{
                    transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
                >
                    <Paper 
                        sx={{ maxHeight: 'calc(100vh - 60px)', overflow: 'auto' }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id="menu-lang"
                                aria-labelledby="search-button"
                                onKeyDown={handleListKeyDown}
                            >
                                {LANG_ARRAY.map((item) => (
                                    <MenuItem
                                        key={item.code}
                                        className='notranslate'
                                        lang={item.code}
                                        aria-label={item.lang}
                                        aria-hidden='true'
                                        onClick={handleLang}
                                        aria-current={lang === item.code}
                                        selected={lang === item.code}
                                    >
                                        {item.code.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
            </Popper> 
            )}
            */}
        </Box>
    )

}

export default DropdownLang