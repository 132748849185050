import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Button from '@mui/material/Button'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Grow from '@mui/material/Grow'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import { newID } from '../../utils/app'


export default function MenuSelect(props) {
    const [open, setOpen] = useState(false)
    const [buttonID, setButtonID] = useState(0)
    const [menuID, setMenuID] = useState(0)
    const anchorRef = useRef(null)

    const { buttonLabel, actionsList } = props

    useEffect(() => {
        setButtonID(newID())
        setMenuID(newID())
    }, [])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = prop => event => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return
        setOpen(false)
        if (prop && typeof prop === 'function')
            prop()
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false)
            anchorRef.current.focus()

        prevOpen.current = open
    }, [open])


    return (
        <Box sx={{ position: 'relative' }}>
            <Button
                color='inherit'
                variant='outlined'
                ref={anchorRef}
                id={buttonID}
                aria-controls={menuID}
                aria-expanded={open}
                aria-haspopup='true'
                onClick={handleToggle}
                disableElevation
                endIcon={<KeyboardArrowDownIcon />}
            >
                {buttonLabel}
            </Button>
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement='bottom-end'
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'top' }}
                    >
                        <Paper
                            sx={{ maxHeight: 'calc(100vh - 60px)', overflow: 'auto' }}
                        >
                            <ClickAwayListener onClickAway={handleClose('')}>
                                <MenuList
                                    autoFocusItem={open}
                                    id={menuID}
                                    aria-labelledby={buttonID}
                                    onKeyDown={handleListKeyDown}
                                >
                                    {actionsList.length && actionsList.map((item, index) => (
                                        <MenuItem
                                            sx={{ padding: 0 }}
                                            key={index}
                                            onClick={handleClose(item.function)}
                                            disableRipple
                                        >
                                            {item.html}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

MenuSelect.propTypes ={
    buttonLabel: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]).isRequired,
    actionsList: PropTypes.array.isRequired,
}