import React from "react"
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link as LinkRouter } from 'react-router-dom'

import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'


import { DEFAULT_LANG_CODE, LANG_ARRAY_CODE_DIR_RTL, PAGES_ROUTES_ARRAY } from '../../config'
import getLabel from "../../constants/textMultiLang"

const nbrPaintingsPerPage = 20

export default function Pagination(props) {
    const theme = useTheme()
    const { lang } = useSelector(state => state.app)
    const { page, query, totalPaintings } = props
    const queryNavigation = query
    const countPage = Math.ceil(totalPaintings / nbrPaintingsPerPage)

    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang

    const {
        PAGINATION_NAVIGATION,
        GO_TO_PREVIOUS_PAGE,
        GO_TO_NEXT_PAGE,
        GO_START_ELLIPSIS_PAGE,
        GO_END_ELLIPSIS_PAGE,
        PAGE,
    } = getLabel(lang, [
        'PAGINATION_NAVIGATION',
        'GO_TO_PREVIOUS_PAGE',
        'GO_TO_NEXT_PAGE',
        'GO_START_ELLIPSIS_PAGE',
        'GO_END_ELLIPSIS_PAGE',
        'PAGE',
    ])

    const getLangPath = (pageName) => {
        return PAGES_ROUTES_ARRAY.filter(e => e.constName === pageName)[0].link[lang]
    }

    const paginationLang = (type, page) => {
        const data = {
            page: { label: PAGE },
            previous: { label: GO_TO_PREVIOUS_PAGE },
            next: { label: GO_TO_NEXT_PAGE },
            'start-ellipsis': { label: GO_START_ELLIPSIS_PAGE },
            'end-ellipsis': { label: GO_END_ELLIPSIS_PAGE },
        }
        return data[type].label + (type === 'page' ? ' ' + page : '')
    }

    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })

    return (
        <MuiPagination
            dir='ltr'
            aria-label={PAGINATION_NAVIGATION}
            page={page}
            count={countPage}
            siblingCount={matchesSM ? 1 : 0}
            sx={{ '& .MuiPagination-ul': { justifyContent: 'center' }, '& .MuiPaginationItem-icon': { transform: LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } }}
            renderItem={(item) => {
                queryNavigation.set('page', item.page)
                item.disabled = item.disabled || item.selected
                item['aria-label'] = paginationLang(item.type, item.page)
                item.title = paginationLang(item.type, item.page)
                return(
                    <PaginationItem
                        component={LinkRouter}
                        to={`${urlLang}${getLangPath('PAINTINGS_PAGE')}?${queryNavigation}`}
                        {...item}
                    />
                )
            }}
        />
    )
}

Pagination.propTypes = {
    query: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    totalPaintings: PropTypes.number.isRequired,
}