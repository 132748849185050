import { createTheme, alpha } from '@mui/material/styles'

const objectKeysRubik = ['fontFamily', 'overline', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'button', 'caption']
const objectKeysEllinia = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
const font = {}
objectKeysRubik.forEach(item => font[item] = {fontFamily: '"Rubik", sans-serif'})
objectKeysEllinia.forEach(item => font[item] = { fontFamily: 'Neue-Metana, "Ellinia", sans-serif', fontWeight: 700 })

const theme = createTheme({
    palette: {
      third: {
        main: 'rgb(153 123 73)',
        light: 'rgb(173, 149, 109)',
        dark: 'rgb(107, 86, 51)',
        contrastText: '#fff'
      },
      black: {
        main: '#000',
        light: 'rgb(51, 51, 51)',
        dark: 'rgb(0, 0, 0)',
        contrastText: '#fff'
      },
      white: {
        main: '#fff',
        light: 'rgb(255, 255, 255)',
        dark: 'rgb(178, 178, 178)',
        contrastText: '#000',
      },
    },
    typography: {
      ...font
    },
    components: {
        // Name of the component
        // MuiCssBaseline: {
        //   styleOverrides: {
        //     body: {
        //       '&::-moz-selection, & *::-moz-selection': {
        //         backgroundColor: (theme => alpha(theme.palette.secondary.main, 0.3))
        //       },
        //       '&::-webkit-selection, & *::-webkit-selection': {
        //         backgroundColor: (theme => alpha(theme.palette.secondary.main, 0.3))
        //       },
        //       '&::selection, & *::selection': {
        //         backgroundColor: (theme => alpha(theme.palette.secondary.main, 0.3))
        //       }
        //     }
        //   }
        // }
        // MuiTabs: {
        //   styleOverrides: {
        //     indicator: ({ theme }) => ({
        //         backgroundColor: theme.palette.third.main,
        //     }),
        //   },
        // },
      },
});

const normalTheme = createTheme(theme, {
    mode: 'light',
    palette: {
      ...theme.palette,
      // primary: theme.palette.black,
      primary: theme.palette.black,
      secondary: theme.palette.white,
      // background: {
      //   default: '#000000',
      // },
    },
    components: {
      // Name of the component
      MuiCssBaseline: {
        styleOverrides: {
          body: {
          },
          '& .MuiSvgIcon-root': {
            fill: theme.palette.white.main,
          },
          '& .dark-section': {
            backgroundColor: theme.palette.black.main,
            color: theme.palette.white.main,
            '& .MuiSvgIcon-root': {
              fill: theme.palette.white.main,
            },
            '& .MuiButton-root.MuiButton-contained:hover': {
              color: alpha(theme.palette.white.contrastText, .8),
              backgroundColor: theme.palette.white.main,
              boxShadow: '0px 2px 4px -1px rgb(250 250 250 / 20%), 0px 4px 5px 0px rgb(250 250 250 / 14%), 0px 1px 10px 0px rgb(250 250 250 / 78%)'
            },
            '& .MuiButton-contained .MuiSvgIcon-root': {
              fill: 'currentColor',
            },
            '& .MuiIconButton-root, & .MuiPaginationItem-root': {
              color: theme.palette.white.main,
              '&:not(.MuiPaginationItem-ellipsis)': {
                '&:hover, & .focus, &.Mui-focusVisible': {
                  outline: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  '@media(hover: none)': {
                    backgroundColor: 'transparent',
                  }
                },
                '&.Mui-selected.Mui-disabled': {
                  color: theme.palette.white.main,
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }
              }
            },
            '& .MuiLink-root': {
              color: theme.palette.white.main,
              '&:hover:not(.hover-none), &:focus': {
                opacity: 0.7,
                outline: 0,
              },
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.primary.contrastText,
              '&.Mui-focused': {
                color: theme.palette.primary.contrastText,
              }
            },
            '& .MuiInputBase-root': {
              color: theme.palette.primary.contrastText,
                // backgroundColor: alpha(theme.palette.primary.contrastText, .5),
                '&:before': {
                  borderColor: theme.palette.primary.contrastText,
                },
                '&:after': {
                  borderColor: theme.palette.primary.contrastText,
                },
                '&:hover, &.Mui-hover': {
                    backgroundColor: alpha(theme.palette.primary.contrastText, .1),
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottomColor: theme.palette.primary.contrastText,
                },
                '&:focus, &.Mui-focused': {
                    backgroundColor: alpha(theme.palette.primary.contrastText, .2),
                    borderColor: theme.palette.primary.contrastText,
                },
            },
            '& .MuiDivider-root': {
              borderColor: theme.palette.white.main
            },
            '& .hr-line': {
              borderBottom: `${theme.palette.white.main} solid 1px !important`
            },
            '&::-moz-selection, & *::-moz-selection': {
              backgroundColor: theme.palette.white.main,
              color: theme.palette.black.main
            },
            '&::-webkit-selection, & *::-webkit-selection': {
              backgroundColor: theme.palette.white.main,
              color: theme.palette.black.main
            },
            '&::selection, & *::selection': {
              backgroundColor: theme.palette.white.main,
              color: theme.palette.black.main
            },
          },
          '&::-moz-selection, & *::-moz-selection': {
            backgroundColor: theme.palette.black.main,
            color: theme.palette.white.main
          },
          '& .hr-line': {
            borderBottom: `${theme.palette.black.main} solid 1px !important`
          },
          '&::-webkit-selection, & *::-webkit-selection': {
            backgroundColor: theme.palette.black.main,
            color: theme.palette.white.main
          },
          '&::selection, & *::selection': {
            backgroundColor: theme.palette.black.main,
            color: theme.palette.white.main
          },
        }
      }
    }
})

console.log(normalTheme)


const lightTheme = createTheme(theme, {
    mode: 'light',
})

const darkTheme = createTheme(theme, {
    mode: 'dark'
})

const customTheme = { normalTheme, lightTheme, darkTheme }
export default customTheme