import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'


import getLabel from '../../constants/textMultiLang'

import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Drawer from '@mui/material/Drawer'
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'
import SvgIcon from '@mui/material/SvgIcon';

import AccordionLang from './AccordionLang'






const MenuMobile = (props) => {
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false);

    let { lang } = useSelector(state => state.app)

    const { urlLang, pages, RSLinks, fake } = props


    const {
        MAIN_MENU,
        OPEN_MENU,
        CLOSE_MENU,
        FOLLOW_US,
        FOLLOW_US_ON,
    } = getLabel(lang, [
        'MAIN_MENU',
        'OPEN_MENU',
        'CLOSE_MENU',
        'FOLLOW_US',
        'FOLLOW_US_ON',
    ])
    

    const handleDrawerToggle =  () => {
        setMobileOpen(!mobileOpen);
    }

    const drawerWidth = 240

    return (
        <Box>
            {/* Hamburger button */}
            <IconButton
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                color="inherit"
                aria-label={OPEN_MENU}
                aria-controls="drawer-mobile"
                aria-haspopup="true"
                aria-expanded={mobileOpen}
                edge='start'
                onClick={handleDrawerToggle}
            >
                <MenuIcon />
            </IconButton>
            {! fake &&
            <>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    id='drawer-mobile'
                    variant="temporary"
                    open={mobileOpen}
                    anchor='right'
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        disablePortal: true,
                        keepMounted: true,
                        autoFocus: true
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none', textAlign: 'end', color: theme.palette.secondary.contrastText },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, maxWidth: '100%', color: 'inherit' },
                    }}
                >
                    <Box sx={{ textAlign: 'start', padding: '5px' }}>
                        <IconButton
                            color="inherit"
                            aria-label={CLOSE_MENU}
                            aria-controls="drawer-mobile"
                            aria-expanded={mobileOpen}
                            onClick={handleDrawerToggle}
                        >
                            <CloseIcon fontSize='large' />
                        </IconButton>
                    </Box>
                    {/* <Divider /> */}
                    <Box
                        component="nav"
                        aria-label={MAIN_MENU}
                    >
                        <List>
                            {pages.map((page, index) => (
                                <ListItem 
                                    key={page.name}
                                    disablePadding
                                >
                                    {/* <ListItemButton href={urlLang + page.link}> */}
                                    <ListItemButton 
                                        component={NavLink}
                                        exact
                                        activeClassName='active'
                                        to={urlLang + page.link[lang]}
                                        sx={{ justifyContent: 'flex-end', '&.active': { fontWeight: 700 } }}
                                        autoFocus={mobileOpen && index === 0}
                                    >
                                        {page.name}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Divider sx={{ color: theme.palette.primary.main }} />
                    <AccordionLang />
                    <Divider sx={{ color: theme.palette.primary.main }} />
                    <Typography sx={{ padding: '16px 16px 0' }} id='followUs_mobile'>{FOLLOW_US}</Typography>
                    <List
                        aria-labelledby='followUs_mobile'
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                    {RSLinks.map((page) => (
                        <ListItem
                            key={page.name}
                            sx={{ width: 'auto', paddingRight: '8px', paddingLeft: '8px' }}
                        >
                            <IconButton
                                component={Link}
                                color='inherit'
                                underline="none"
                                target='blank'
                                rel='noopener noreferrer'
                                href={`https://www.${page.link}`}
                                title={FOLLOW_US_ON + page.name}
                                sx={{ width: '2.5rem' }}
                                onClick={handleDrawerToggle}
                            >
                                <SvgIcon viewBox={page.icon.viewBox}>
                                    {page.icon.path}
                                </SvgIcon>
                            </IconButton>
                        </ListItem>
                    ))}
                    </List>
                </Drawer>
            </>}
        </Box>
    )

}

export default MenuMobile