import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { history } from '../../redux/store'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

import { alpha } from '@mui/material'

import AddGenreForm from '../form/AddGenreForm'
import EditGenreForm from '../form/EditGenreForm'
import Table from '../global/Table'
import Snackbar from '../global/Snackbar'
import Alert from '../global/Alert'
import Modal from '../global/Modal'

import axios from '../../utils/axios'
import getLabel from '../../constants/textMultiLang'



function ModalActionGenre(props) {

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarPack, setSnackbarPack] = useState([])
    const [snackbarMessage, setSnackbarMessage] = useState(undefined)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState({})

    const { location, data, handleSubmitGlobal } = props
    const defaultValues = {
        name_fr: { value: data.name && data.name.fr ? data.name.fr : '', messageError: '', isRequired: true },
        name_en: { value: data.name && data.name.en ? data.name.en : '', messageError: '', isRequired: true },
        name_he: { value: data.name && data.name.he ? data.name.he : '', messageError: '', isRequired: true },
    }

    const [values, setValues] = useState(defaultValues)
    const { lang } = useSelector(state => state.app)

    const {
        SAVE,
        CANCEL,
    } = getLabel(lang, [
        'SAVE',
        'CANCEL',
    ])

    useEffect(() => {
        if(!data)
            history.push(location.pathname)
    }, [data])

    const handleClose = () => {
        history.push(location.pathname)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setOpenAlert(false)
        const status = await handleSubmitGlobal(values, data._id, setAlertMessage, setOpenAlert)
        if(status && !status.novalid)
            handleClose()
        else if(status && status.novalid)
            setValues(status.validation)
    }
    
    const content = 
        <>
            <EditGenreForm genreData={data} values={values} setValues={setValues} handleSubmit={handleSubmit} />
            <Alert open={openAlert} setOpen={setOpenAlert} message={alertMessage} />
        </>
    

    return (
        <>
            {data && data.name &&
                <Modal 
                    dialogTitle={
                        <>{data.name && data.name[lang] ? data.name[lang] : ''}</>
                    }
                    dialogContent={content}
                    buttons={[
                        { label: CANCEL, type: 'button' },
                        { label: SAVE, type: 'button', func: () => { document.getElementById('edit-genre-form').requestSubmit() }, keepModal: true }
                    ]}
                    blockBackdropClick={true}
                    isCustomOpen={true}
                    customOpen={true}
                    customClose={handleClose}
                    closeButton={true}
                />
            }
        </>
    )
}

ModalActionGenre.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object,
    handleSubmitGlobal: PropTypes.func,
}





export default function Genres(props) {
    const [delValue, setDelValue] = useState('')
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarPack, setSnackbarPack] = useState([])
    const [snackbarMessage, setSnackbarMessage] = useState(undefined)
    const [isLoaded, setIsLoaded] = useState(false)
    const [genresData, setGenresData] = useState([])

    const { lang } = useSelector(state => state.app)

    const { location } = props
    const querySearch = new URLSearchParams(location.search)
    const queryEdit = querySearch.get('edit') || false


    const {
        GENRES,
        NAME,
        FIRST_NAME,
        BIRTH_YEAR,
        DEATH_YEAR,
        CITY,
        COUNTRY,
        ACTION,
        EDIT,
        COMPLETE_FIELD,
        REGISTERED_SUCCESSFULLY,
        CANCEL,
        DELETE,
        ATTENTION,
        WANT_DELETE_GENRE,
    } = getLabel(lang, [
        'GENRES',
        'NAME',
        'FIRST_NAME',
        'BIRTH_YEAR',
        'DEATH_YEAR',
        'CITY',
        'COUNTRY',
        'ACTION',
        'EDIT',
        'COMPLETE_FIELD',
        'REGISTERED_SUCCESSFULLY',
        'CANCEL',
        'DELETE',
        'ATTENTION',
        'WANT_DELETE_GENRE',
    ])

    const messageAPI = getLabel(lang, ['MISSING_DATA', 'GENRE_ATTACHED_PAINTING_DONT_REMOVE', 'INTERNAL_ERROR'])

    const headCells = [
        {
            id: 'name_fr',
            numeric: false,
            disablePadding: false,
            label: NAME + ' fr',
            sortDefault: 'asc',
        },
        {
            id: 'name_en',
            numeric: false,
            disablePadding: false,
            label: NAME + ' en',
        },
        {
            id: 'name_he',
            numeric: false,
            disablePadding: false,
            label: NAME + ' he',
        },
        {
            id: 'actions',
            numeric: false,
            label: ACTION,
            disablePadding: false,
            disableColumnSort: true,
        },
    ]

    const getGenres = async () => {
        const res = await axios.getGenres()
        if (res.code === 200)
            setGenresData(res.data)
        else 
            setGenresData([])
    }

    const updateGenresData = id => {
        setGenresData(prevGenresData => (prevGenresData.filter(item => item._id !== id)))
    }

    useEffect(() => {
        async function start() {
            await getGenres()
            setIsLoaded(true)
        }
        start()
    }, [])

    useEffect(() => {
        if(delValue) {
            async function handleSubmit() {
                const res = await axios.deleteGenre({id: delValue })
                if(res.code === 201) {
                    handleSetSnackbarPack({ message: REGISTERED_SUCCESSFULLY, severity: 'success' })
                    updateGenresData(delValue)
                } else {
                    handleSetSnackbarPack({ message: messageAPI[res.message], severity: 'error' })
                }
            }
            handleSubmit()
            setDelValue('')
        }
    }, [delValue])

    useEffect(() => {
        if (snackbarPack.length && !snackbarMessage) {
          // Set a new snack when we don't have an active one
          setSnackbarMessage({ ...snackbarPack[0] })
          setSnackbarPack(prev => prev.slice(1))
          setOpenSnackbar(true)
        } else if (snackbarPack.length && snackbarMessage && openSnackbar) {
          // Close an active snack when a new one is added
          setOpenSnackbar(false)
        }
    }, [snackbarPack, snackbarMessage, openSnackbar])

    const handleSetSnackbarPack = message => setSnackbarPack(prev => [...prev, { ...message, key: new Date().getTime() }])

    const handleExited = () => setSnackbarMessage(undefined)

    
    function isValidForm(values) {
        let status = true
        const newValues = values
        for (const property in values) {
            // console.log(property + ': ' + values[property])
            if(!values[property].size && values[property].size !== 0) {
                if(values[property].isRequired && ((typeof values[property].value === 'object' && Object.keys(values[property].value).length === 0 && !values[property].value.size) || (typeof values[property].value === 'string' && !values[property].value.trim()))) {
                    status = false
                    newValues[property].messageError = COMPLETE_FIELD
                }
            }
        }
        if(!status) 
            return { novalid: true, validation: {...values, ...newValues}}
        else{
            return { novalid: false }
        }
    }

    function buildFormdata(values) {
        const formData = new FormData()
        for (const property in values) {
            // console.log(property + ': ' + values[property])
            if(!values[property].isFile || (values[property].value && values[property].value.size && values[property].value.size > 0))
                formData.append(property, typeof values[property].value ==='string' ? values[property].value.trim() : values[property].value)
        }
        return formData
    }

    const handleSubmit = async (values, id, setAlertMessage, setOpenAlert) => {
        const isInvalidFormObject = isValidForm(values)
        if(isInvalidFormObject.novalid)
            return isInvalidFormObject
        const formData = buildFormdata(values)
        formData.append('id', id)
        const res = await axios.updateGenre(formData)
        if(res.code === 200) {
            setSnackbarMessage({ message: REGISTERED_SUCCESSFULLY, severity: 'success' })
            setOpenSnackbar(true)
            getGenres()
            return true
        } else {
            setAlertMessage({ message: messageAPI[res.message], severity: 'error' })
            setOpenAlert(true)
            return false
        }
    }

    const deleteFile = id => e => {
        setConfirmOpen(id)
    }

    const deleteConfirm = id => e => {
        setDelValue(id)
    }

    function buildActionsList(id) {
        return (
            <Box>
                <IconButton onClick={() => history.push(`${location.pathname}?edit=${id}`)} title={EDIT}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={deleteFile(id)} title={DELETE} sx={{ '&:hover, :focus': { color: '#d10303', backgroundColor: alpha('#d10303', .3) } }}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </Box>
        )
    }

    const buildDatas = () => {        
        return genresData.map((genre) => {
            const name_fr = genre.name && genre.name.fr ? genre.name.fr : ''
            const name_en = genre.name && genre.name.en ? genre.name.en : ''
            const name_he = genre.name && genre.name.he ? genre.name.he : ''
            const actionsList = buildActionsList(genre._id)

            return {
                name_fr,
                name_en,
                name_he,
                actions: actionsList,
            }
        })
    }
   
    return (
        <Box sx={{ padding: '40px 0px' }}>
            <Typography component='h1' variant='h4' align='center'>{GENRES}</Typography>
            <AddGenreForm getGenres={getGenres} />
            <Table datas={buildDatas()} headCells={headCells} />
            {isLoaded && queryEdit && <ModalActionGenre location={location} handleSubmitGlobal={handleSubmit} data={genresData.filter(p => p._id === queryEdit)[0]} />}
            <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} handleExited={handleExited} message={snackbarMessage} />
            {/* confirm delete modal */}
            <Modal 
                dialogTitle={<></>}
                dialogContent={
                    <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                        <Box><WarningAmberOutlinedIcon color='error' fontSize='large' /></Box>
                        <Typography component='h2' variant='h5'>{ATTENTION}</Typography><br />
                        <Typography>{WANT_DELETE_GENRE}</Typography>
                    </Box>
                }
                buttons={[{ label: CANCEL, func: deleteConfirm('') }, { label: DELETE, type: 'button', func: deleteConfirm(confirmOpen) }]}
                isCustomOpen={true}
                customOpen={Boolean(confirmOpen)}
                customClose={() => setConfirmOpen(false)}
                closeButton={true}
            />
        </Box>
    )
}