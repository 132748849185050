import React from 'react'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import getLabel from '../../constants/textMultiLang'


export default function EditCurrentForm(props) {
    const { lang } = useSelector(state => state.app)
    let { values, setValues, handleSubmit } = props

    const {
        NAME,
        START_YEAR,
        END_YEAR,
    } = getLabel(lang, [
        'NAME',
        'START_YEAR',
        'END_YEAR',
    ])
 
    const handleChange = prop => e => {
        const newValues = values
        newValues[prop].value = e.target.value
        newValues[prop].messageError = ''
        setValues({...newValues})
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box
                component='form'
                id={'edit-current-form'}
                sx={{ textAlign: 'center' }}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
            >
                <div>
                <TextField
                    autoFocus
                    label={`${NAME} fr`}
                    name='name_fr'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.name_fr.value}
                    onChange={handleChange('name_fr')}
                    error={Boolean(values.name_fr.messageError)}
                    helperText={values.name_fr.messageError}
                />
                <TextField
                    label={`${NAME} en`}
                    name='name_en'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.name_en.value}
                    onChange={handleChange('name_en')}
                    error={Boolean(values.name_en.messageError)}
                    helperText={values.name_en.messageError}
                />
                <TextField
                    label={`${NAME} he`}
                    name='name_he'
                    type='text'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.name_he.value}
                    onChange={handleChange('name_he')}
                    error={Boolean(values.name_he.messageError)}
                    helperText={values.name_he.messageError}
                />
                </div>
                <TextField
                    label={`${START_YEAR}`}
                    name='startYear'
                    type='number'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.startYear.value}
                    onChange={handleChange('startYear')}
                    error={Boolean(values.startYear.messageError)}
                    helperText={values.startYear.messageError}
                />
                <TextField
                    label={`${END_YEAR}`}
                    name='endYear'
                    type='number'
                    variant="standard"
                    required
                    sx={{ m: 2 }}
                    value={values.endYear.value}
                    onChange={handleChange('endYear')}
                    error={Boolean(values.endYear.messageError)}
                    helperText={values.endYear.messageError}
                />
                <Box component='input' sx={{ display: 'none' }} type='submit' />
            </Box>
        </Box>
    )
}