import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FilledInput from '@mui/material/FilledInput'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

import Alert from './global/Alert'

import { HOST, DEFAULT_LANG_CODE } from '../config'
import getLabel from "../constants/textMultiLang";
import { login } from "../redux/actions/user"


export default function Login(props) {
    const dispatch = useDispatch()
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState({})
    const { lang } = useSelector(state => state.app)
    const { isLoggedIn } = useSelector(state => state.auth)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang

    const getQuery = value => {
        const query = new URLSearchParams(props.location.search)
        let result = query.get(value)
        if (result && value === 'redirect')
            result = atob(result)
        return result
    }

    if (isLoggedIn)
        return <Redirect to={getQuery('redirect') || urlLang + "/"} />;

    const {
        BOUBLIL_COLLECTION,
        NAME_COMPLET_BLACK_LOGO,
        UNE_ERREUR_S_EST_PRODUITE,
        ADRESSE_MAIL_MDP_INCORRECTE,
        EMAIL,
        PASSWORD,
        LOGIN,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'NAME_COMPLET_BLACK_LOGO',
        'UNE_ERREUR_S_EST_PRODUITE',
        'ADRESSE_MAIL_MDP_INCORRECTE',
        'EMAIL',
        'PASSWORD',
        'LOGIN',
    ])

    const handleLogin = e => {
        e.preventDefault()
        setOpenAlert(false)
        const formData = new FormData(e.currentTarget)
        dispatch(login(formData))
        .then((res) => {
            if (res.code === 401) {
                setOpenAlert(true)
                setAlertMessage({ severity: 'error', message: ADRESSE_MAIL_MDP_INCORRECTE })
                return false
            } else if (res.code === 200) {
                return true
            }
        })
        .catch(() => {
            setOpenAlert(true)
            setAlertMessage({ severity: 'error', message: UNE_ERREUR_S_EST_PRODUITE })
            return false
        })
    }


    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${HOST}images/site/navBarBg.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Box sx={{backgroundColor: 'RGBA(0, 0, 0, .7)', height: '100%'}}></Box>
            </Grid>
            <Grid xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ mx: 'auto', maxWidth: '500px' }}>
                <Box dir='ltr' sx={{ my: 8, mx: 4 }}>
                    <Box sx={{ width: '15rem' }}>
                        <Box
                            component="img"
                            sx={{ mb: 5 }}
                            src={`${HOST}images/site/${NAME_COMPLET_BLACK_LOGO}`}
                            alt={BOUBLIL_COLLECTION}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {LOGIN}
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleLogin} sx={{ mt: 1 }}>
                        <FormControl sx={{ margin: '8px 0' }} variant='filled' size='small' fullWidth required>
                            <InputLabel htmlFor='email-input' sx={{ padding: '0 1px', fontStyle: 'italic' }}>{EMAIL}</InputLabel>
                            <FilledInput
                                id='email'
                                type='email'
                                name='email'
                                autoComplete="login"
                                required
                                autoFocus
                            />
                        </FormControl>
                        <FormControl sx={{ margin: '8px 0' }} variant='filled' size='small' fullWidth required>
                            <InputLabel htmlFor='password-input' sx={{ padding: '0 1px', fontStyle: 'italic' }}>{PASSWORD}</InputLabel>
                            <FilledInput
                                id='password-input'
                                type='password'
                                name="password"
                                autoComplete="current-password"
                                required
                            />
                        </FormControl>
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {LOGIN}
                        </Button>
                        <Alert open={openAlert} setOpen={setOpenAlert} message={alertMessage} />
                        <Typography sx={{ mt: 5 }} variant="body2" color="text.secondary" align="center">
                            <span>&copy; Copyright </span>
                            <Link color="inherit" target='_blank' href="https://www.boublilcollection.com">Boublil Collection</Link>
                            <span> 2022 - {new Date().getFullYear()}</span>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}