import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useTheme } from '@mui/material/styles'

import ModalPreviousImage from '../painting/ModalPreviousImage'
import { ZoomInIcon } from '../../theme/icons'

import { HOST, LANG_ARRAY_CODE_DIR_RTL, DEFAULT_LANG_CODE, PAGES_ROUTES_ARRAY } from '../../config'
import getLabel from '../../constants/textMultiLang'
import { convertToUrl } from '../../utils/app'



function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`,
    }
}


export default function ListPaintings(props) {
    const targetRef = useRef()
    const theme = useTheme()
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    const [scale, setScale] = useState(0)
    const [openModal, setOpenModal] = useState(0)
    const { lang } = useSelector(state => state.app)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang
    const { paintingsData } = props
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })

    const {
        OPEN_PICTURE_PREVIEW
    } = getLabel(lang, [
        'OPEN_PICTURE_PREVIEW'
    ])

    const getLangPath = (pageName) => {
        return PAGES_ROUTES_ARRAY.filter(e => e.constName === pageName)[0].link[lang]
    }

    const scaleImage = indexID => () => {
        setScale(indexID)
    }

    const handleToggleModal = indexID => () => {
        setOpenModal(indexID)
    }

    const BuildLink = ({ title, link, indexID }) => {
        return (
            <Typography component='h3'>
                <Link
                    component={LinkRouter}
                    to={link || '/'}
                    underline='none'
                    onMouseOver={scaleImage(indexID)}
                    onMouseLeave={scaleImage(0)}
                    onFocus={scaleImage(indexID)}
                    onBlur={scaleImage(0)}
                >
                    <Typography component='span' variant='h6'>
                        {title}
                    </Typography>
                </Link>
            </Typography>
        )
    }

    let cols = 0
    if(matchesLG) cols = 4
    else if(matchesMD) cols = 3
    else if(matchesSM) cols = 2
    else cols = 1

    const getDimensions = useCallback(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetWidth / (cols * 2)
            })
        }
    }, [cols])

    useEffect(() => {
        window.addEventListener('resize', getDimensions)
        getDimensions()
        return () => window.removeEventListener('resize', getDimensions)
    }, [getDimensions])

    return (
        <ImageList
            ref={targetRef}
            sx={{
                margin: 'auto',
                width: '100%',
                maxWidth: { xs: '300px', sm: '500px', md: '100%' },
                // height: 450,
                // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                transform: 'translateZ(0)',
                '& .scale-image img': { transform: 'scale(1.08)' }
            }}
            rowHeight={dimensions.height}
            gap={1}
            cols={cols}
        >
            {paintingsData.map((item, index) => {
                const cols = 1
                const rows = 3
                const indexID = index + 1

                return (
                    <ImageListItem key={index} cols={cols} rows={rows} className={scale === indexID ? 'scale-image' : ''} sx={{ overflow: 'hidden' }}>
                        <Box
                            component='img'
                            {...srcset(`${HOST}images/${item.thumbLink}`, dimensions.width, dimensions.height, rows, cols)}
                            alt={item.name[lang]}
                            loading="lazy"
                            sx={{ transform: 'scale(1)', transition: 'transform .3s linear', objectFit: 'cover' }}
                        />
                        <ImageListItemBar
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.7) ' + (LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 100 : 0) + '%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                alignItems: 'end',
                                '& .MuiImageListItemBar-title': {
                                    marginBottom: '5px',
                                    textOverflow: 'initial',
                                    overflow: 'hidden',
                                    whiteSpace: 'normal',
                                    '& .MuiTypography-root': { lineHeight: 1.1 },
                                    '& .MuiLink-root:after': { content: '""', position: 'absolute', top: targetRef ? '-1000px' : 0, bottom: 0, left: 0, right: 0 }
                                },
                            }}
                            title={<BuildLink title={item.name[lang]} link={`${urlLang}${getLangPath('PAINTING_PAGE')}/${item.uuid}/${convertToUrl(item.name[lang])}`} indexID={indexID} />}
                            subtitle={(item.artist.firstName || '') + (item.artist.firstName && item.artist.name && ' ') + (item.artist.name || '')}
                            position="bottom"
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'white' }}
                                    aria-label={OPEN_PICTURE_PREVIEW + ' - ' + item.name[lang]}
                                    onClick={handleToggleModal(indexID)}
                                >
                                    <ZoomInIcon />
                                </IconButton>
                            }
                            actionPosition="right"
                        />
                        <ModalPreviousImage openModal={openModal && openModal === indexID} dataModal={{ ...item, img: `${HOST}images/${item.thumbLink}`, indexID }} handleToggleModal={handleToggleModal} />
                    </ImageListItem>
                )
            })}
        </ImageList>
    )
}