import React, { useEffect, useState } from 'react'
import ReactSelect, { components} from 'react-select'





export default function Select(props) {
    const [selectedOption, setSelectedOption] = useState(null)
    const onChangeInput = props.onChange
    const { options, success } = props
    const multiple = props.multiple ? props.multiple : false
    // const success = props.success

    const onChange = e => {
        console.log(e)
        let select = {target: {value: multiple ?  e.map(item => item.value).join('§§') : e.value}}
        setSelectedOption(e)
        onChangeInput(select)
    }

    useEffect(() => {
        if(props.defaultValue) {
            let defaultValue = props.defaultValue
            if(typeof defaultValue === 'string') defaultValue = props.defaultValue.split('§§')
            defaultValue = defaultValue.map(val => options.filter(item => item.value === val.trim())[0])
            if(!multiple) defaultValue = defaultValue[0]
            const onChange = e => {
                console.log(e)
                let select = {target: {value: multiple ?  e.map(item => item.value).join('§§') : e.value}}
                setSelectedOption(e)
                onChangeInput(select)
            }
            onChange(defaultValue)
        }
    }, [multiple, onChangeInput, options, props.defaultValue])

    useEffect(() => {
        if(success)
            setSelectedOption('')
    }, [success])

    const customComponents = {
        NoOptionsMessage: (props) => {
            return (
                <components.NoOptionsMessage {...props}>
                    {'CONST'}
                </components.NoOptionsMessage>
            )
        },
    }

    const customStylesSelect = {
        NoOptionsMessage: (provided) => ({
            ...provided
        }),
        // menu: (provided, state) => ({
        //     ...provided,
        //     width: state.selectProps.width,
        //     borderBottom: '1px dotted pink',
        //     color: state.selectProps.menuColor,
        //     padding: 20,
        // }),
        
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? 'red' : 'hsl(0, 0%, 80%)',
            boxShadow: state.isFocused ? 'red' : '(0 0 0 1px red)',
            '&:hover': {
                borderColor: state.isFocused ? 'red' : 'hsl(0, 0%, 80%)',
            }
        }),
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: '2.2em',
            overflowY: 'auto',
            overflowX: 'auto'
        }),
        
        mutipleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            console.log(provided)
        
            return { ...provided, opacity, transition };
        }
    }

    

    return (
        <div style={{width: '20em', maxWidth: '100%'}}>
            <ReactSelect
                className='select3input'
                value={selectedOption}
                onChange={onChange}
                options={options}
                isMulti={multiple}
                closeMenuOnSelect={!multiple}
                placeholder={'CONST'}
                // defaultValue={{ label: 2002, value: 2002 }}
                blurInputOnSelect={false}
                styles={customStylesSelect}
                components={customComponents}
                // width='100%'
            />
        </div>
    )
}