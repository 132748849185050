import axios from 'axios';

import { HOST } from '../config'

axios.defaults.withCredentials = true

export function authHeader() {
  const token = JSON.parse(localStorage.getItem('token'))
  if (token) {
    return { Authorization: 'Bearer ' + token }
  } else {
    return {}
  }
}


const API_URL = HOST + 'api/'
const API_URL_USER = API_URL + 'auth/'
const API_URL_FILE = API_URL + 'files/'
const API_URL_ARTIST = API_URL + 'cms/artist'
const API_URL_GENRE = API_URL + 'cms/genre'
const API_URL_CURRENT = API_URL + 'cms/current'
const API_URL_CMS_PAINTING = API_URL + 'cms/painting'
const API_URL_CMS_PAINTING_TOOGLE_ONLINE = API_URL + 'cms/painting/toogleOnline'
const API_URL_STATUS_LABELS = API_URL + 'cms/statusLabels'
const API_URL_PAINTING = API_URL + 'paintings/'
const API_URL_SEARCH_DATA = API_URL_PAINTING + 'searchData'
const API_URL_GET_FILES_DIRECTORY = API_URL + 'cms/getFilesDirectory'

const register = (username, email, password) => {
  return axios.post(
    API_URL_USER + 'signup',
    { username, email, password }
  )
}

const login = async data => {
  try {
    const res = await axios.post(API_URL_USER + 'signin', data)
    if (res.data.token) {
      localStorage.setItem('token', JSON.stringify(res.data.token))
    }
    return res.data;
  } catch (err) {
    return { code: 500, status: 'error', message: err.message }
  }
}

const logout = async () => {
  try {
    const res = await axios.post(API_URL_USER + 'logout', { headers: authHeader() })
    localStorage.removeItem("token")
    return res
  } catch (err) {
    return { data: { code: 500, status: 'error', message: err.message } }
  }
}

const getUser = async () => {
  try {
    const res = await axios.get(API_URL_USER + 'user', { headers: authHeader() })
    return res
  } catch (err) {
    return { data: { code: 500, status: 'error', message: err.message } }
  }
}

const setArtist = async data => {
  try {
    const res = await axios.post(
      API_URL_ARTIST,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const getArtists = async () => {
  try {
    const res = await axios.get(API_URL_ARTIST, { headers: authHeader() })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const deleteArtist = async data => {
  try {
    const res = await axios.delete(
      API_URL_ARTIST, {
      headers: authHeader(),
      data
    })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const updateArtist = async data => {
  try {
    const res = await axios.put(
      API_URL_ARTIST,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const setGenre = async data => {
  try {
    const res = await axios.post(
      API_URL_GENRE,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const getGenres = async () => {
  try {
    const res = await axios.get(API_URL_GENRE, { headers: authHeader() })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const deleteGenre = async data => {
  try {
    const res = await axios.delete(
      API_URL_GENRE, {
      headers: authHeader(),
      data
    })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const updateGenre = async data => {
  try {
    const res = await axios.put(
      API_URL_GENRE,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const setCurrent = async data => {
  try {
    const res = await axios.post(
      API_URL_CURRENT,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const getCurrents = async () => {
  try {
    const res = await axios.get(API_URL_CURRENT, { headers: authHeader() })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const deleteCurrent = async data => {
  try {
    const res = await axios.delete(
      API_URL_CURRENT, {
      headers: authHeader(),
      data
    })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const updateCurrent = async data => {
  try {
    const res = await axios.put(
      API_URL_CURRENT,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const setPainting = async data => {
  try {
    const res = await axios.post(
      API_URL_CMS_PAINTING,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const getPaintings = async () => {
  try {
    const res = await axios.get(API_URL_CMS_PAINTING, { headers: authHeader() })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const deletePainting = async data => {
  try {
    const res = await axios.delete(
      API_URL_CMS_PAINTING, {
      headers: authHeader(),
      data
    })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const updatePainting = async data => {
  try {
    const res = await axios.put(
      API_URL_CMS_PAINTING,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const toogleOnline = async data => {
  try {
    const res = await axios.put(
      API_URL_CMS_PAINTING_TOOGLE_ONLINE,
      data,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const getStatusLabels = async () => {
  try {
    const res = await axios.get(API_URL_STATUS_LABELS, { headers: authHeader() })
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const getPaintingByUuid = async uuidPainting => {
  try {
    const res = await axios.get(
      API_URL_PAINTING + uuidPainting,
      // { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

// const getPaintingById = async paintingId => {
//   try {
//     const res = await axios.get(
//       API_URL_PAINTING + paintingId,
//       // { headers: authHeader() }
//     )
//     return res.data
//   } catch (err) {
//     console.log(err)
//     return { code: 500, status: 'error', message: err.message }
//   }
// }

const getAllPaintings = async (data = {}) => {
  try {
    const res = await axios.get(
      API_URL_PAINTING,
      { params: { ...data } }
      // { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const getSearchData = async () => {
  try {
    const res = await axios.get(
      API_URL_SEARCH_DATA,
      // { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}

const getFilesDirectory = async () => {
  try {
    const res = await axios.get(
      API_URL_GET_FILES_DIRECTORY,
      { headers: authHeader() }
    )
    return res.data
  } catch (err) {
    console.log(err)
    return { code: 500, status: 'error', message: err.message }
  }
}




const setFileKey = key => {
  return axios.post(API_URL_FILE + 'setFileKey', { key })
}

const getFile = async (path, key) => {
  try {
    const res = await axios.get(API_URL_FILE + path + '?key=' + key)
    return res
  } catch (error) {
    return { status: 'error', message: error.message }
  }
}

const getFileBlob = async (path, key) => {
  const config = { responseType: 'blob' }
  try {
    const res = await axios.get(API_URL_FILE + path + '?key=' + key, config)
    return res
  } catch (error) {
    return { status: 'error', message: error.message }
  }
}






const getPublicContent = () => {
  return axios.get(API_URL + "all")
}

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() })
}

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() })
}

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() })
}

const toExport = {
  authHeader,
  register,
  login,
  getUser,
  logout,
  setArtist,
  getArtists,
  deleteArtist,
  updateArtist,
  setGenre,
  getGenres,
  deleteGenre,
  updateGenre,
  setCurrent,
  getCurrents,
  deleteCurrent,
  updateCurrent,
  setPainting,
  getPaintings,
  deletePainting,
  updatePainting,
  toogleOnline,
  getStatusLabels,
  // getPaintingById,
  getPaintingByUuid,
  getAllPaintings,
  getSearchData,
  getFilesDirectory,
  setFileKey,
  getFile,
  getFileBlob,
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
}

export default toExport