import getLabel from "../../constants/textMultiLang"
import Swal from 'sweetalert2'

import './dialog.css'




export const alert = (status, title) => {
  Swal.fire({
    // position: 'top-end',
    icon: status,
    title,
    showConfirmButton: false,
    timer: 4000
  })
}

export const confirm = async (lang, title, text) => {
  const { YES, CANCEL } = getLabel(lang, ['YES', 'CANCEL'])
  const result = await Swal.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    // confirmButtonColor: '#d33',
    // cancelButtonColor: '#ffffff',
    confirmButtonText: YES,
    cancelButtonText: CANCEL,
    customClass: {
      confirmButton: 'btn-primary',
      cancelButton: 'btn-secondary'
    }
  })
  return result
}

export const modal = async (title, html) => {
  // const { YES, CANCEL } = getLabel(lang, ['YES', 'CANCEL'])
  const result = await Swal.fire({
    title,
    html,
    icon: 'warning',
    showCancelButton: true,
    // confirmButtonColor: '#d33',
    // cancelButtonColor: '#ffffff',
    confirmButtonText: 'YES',
    cancelButtonText: 'CANCEL',
    // reverseButtons: true,
    customClass: {
      confirmButton: 'btn-primary',
      cancelButton: 'btn-secondary'
    }
  })
  return result
}

export const toast = Swal.mixin({
    toast: true,
    position: 'bottom-right',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true
})

















// export default function Popup({popupOpen, setPopupOpen, children}) {
//     const [isOpen, setIsOpen] = useState(false)

//     const close = () => {
//         // setIsOpen(false)
//         setIsOpen(false)
//         setTimeout(() => {
//             setPopupOpen({})
            
//         }, 300)
//     }
//     useEffect(() => {
//         if(children) {
//             setIsOpen(true)
//             // setTimeout(() => {
//             //     close()
//             // }, 10000)
//         }
//     }, [children])

//     return (
//         <div className={`container-popup ${children && isOpen ? 'active' : ''}`} role="dialog">
//             <div className={`message ${popupOpen.status}`}>
//                 <span>{children}</span>
//                 <button type="button" className='btn btn-close' onClick={close} aria-hidden={!isOpen} aria-expanded={isOpen} aria-label="Close"></button>
//             </div>
//         </div>
//     )

// }