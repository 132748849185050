import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FilledInput from '@mui/material/FilledInput'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { ArrowDoubleRightIcon } from '../../theme/icons'

import { LANG_ARRAY_CODE_DIR_RTL } from '../../config'
import getLabel from '../../constants/textMultiLang'

import axios from '../../utils/axios'


export default function CommentairesForm() {
    const [values, setValues] = useState({
        message: '',
        name: '',
        firstName: '',
        email: '',
        // showPassword: false,
    })
    const { lang } = useSelector(state => state.app)

    const {
        NAME,
        FIRST_NAME,
        EMAIL,
        MESSAGE,
        SEND
    } = getLabel(lang, [
        'NAME',
        'FIRST_NAME',
        'EMAIL',
        'MESSAGE',
        'SEND'
    ]) 
    
    const handleChange = prop => e => {
        setValues({ ...values, [prop]: e.target.value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        setValues({ message: '', name: '', firstName: '', email: '' })
    }

    return (
        <Box
            component='form'
            id="form-comment"
            aria-labelledby="form-comment-title"
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
        >
            <TextField
                id='message-input'
                label={MESSAGE}
                size='small'
                multiline
                rows={4}
                value={values.message}
                onChange={handleChange('message')}
                variant='filled'
                required
                sx={{ margin: '8px 0', '& label': { fontStyle: 'italic' } }}
                fullWidth
                // error
                // helperText='Incorrect entry.'
            />
            <FormControl sx={{ margin: '8px 0' }} variant='filled' size='small' fullWidth required>
                <InputLabel htmlFor='name-input' sx={{ padding: '0 1px', fontStyle: 'italic' }}>{NAME}</InputLabel>
                <FilledInput
                    id='name-input'
                    type='text'
                    value={values.name}
                    onChange={handleChange('name')}
                />
            </FormControl>
            <FormControl sx={{ margin: '8px 0' }} variant='filled' size='small' fullWidth required>
                <InputLabel htmlFor='firstName-input' sx={{  padding: '0 1px', fontStyle: 'italic' }}>{FIRST_NAME}</InputLabel>
                <FilledInput
                    id='firstName-input'
                    type='text'
                    value={values.firstName}
                    onChange={handleChange('firstName')}
                />
            </FormControl>
            <FormControl sx={{ margin: '8px 0', minWidth: '0' }} variant='filled' size='small' fullWidth required>
                <InputLabel htmlFor='email-input' sx={{  padding: '0 1px', fontStyle: 'italic' }}>{EMAIL}</InputLabel>
                <FilledInput
                    id='email-input'
                    type='email'
                    value={values.email}
                    onChange={handleChange('email')}
                />
            </FormControl>
            <Button
                variant='contained'
                color='primary'
                type='submit'
                size='large'
                endIcon={<ArrowDoubleRightIcon transform={ LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } />}
                sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', width: '100%', justifyContent: 'space-between', borderRadius: '0 0 3rem 0', '&:hover': { color: 'rgb(250 250 250 / 80%)' } }}
            >
                <Typography component='span'>{SEND}</Typography>
            </Button>
        </Box>
    )
}