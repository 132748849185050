import React from 'react'
// import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link as LinkRouter } from 'react-router-dom'

import { HOST, DEFAULT_LANG_CODE } from '../../config'

import getLabel from '../../constants/textMultiLang'

import { styled, useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Toolbar from '@mui/material/Toolbar'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import DropdownLang from './DropdownLang'
import AccountMenu from './AccountMenu'
import { DrawerHamburger } from '../cms/Drawer'



const Header = styled((props) => ( <AppBar {...props} /> )) (
    ({ theme }) => ({
        // boxShadow: '0px 2px 4px -1px rgb(255 255 255 / 20%), 0px 4px 5px 0px rgb(255 255 255 / 14%), 0px 1px 10px 0px rgb(255 255 255 / 12%)',
        '& .MuiButton-root:hover, & .MuiIconButton-root:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)'
        },
        '& .navBar-container': {
            '& nav .MuiList-root .MuiLink-root': {
                padding: 0,
                '& .label': {
                    padding: 0
                },
            },
        },
        '& .navBar-container .navBar .MuiList-root': {
            padding: 0,
            '& .MuiListItem-root': {
                paddingTop: 0,
                paddingBottom: 0,
            },
            '& .MuiLink-root': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '& .label': {
                    padding: '30px 0',
                    transition: 'padding 300ms linear'
                },
            },
        },
    })
);

export default function NavBar(props) {
    const theme = useTheme()
    const { lang } = useSelector(state => state.app)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang
    const { user, handleDrawerToggle } = props
    

    const {
        BOUBLIL_COLLECTION,
        NAME_COMPLET_WHITE_LOGO,
        NAME_BC_WHITE_LOGO,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'NAME_COMPLET_WHITE_LOGO',
        'NAME_BC_WHITE_LOGO',
    ])

    
    return (
        <Header 
            sx={{ position: 'static' }} 
            color='primary' elevation={3}  
        >

            {/* NavBar globale */}
            <Box className='navBar-container'>
                <Container maxWidth={false}>
                    <Toolbar disableGutters sx={{ alignItems: 'stretch' }}>
                        {/* Logo */}
                        <Link component={LinkRouter} to={`${urlLang}/`} sx={{ display: { lg: 'none' }, width: { xs: '2.5rem', sm: '11rem' }, marginRight: '40px' }} title={BOUBLIL_COLLECTION}>
                            <picture>
                                <source media={theme.breakpoints.up('sm').replace('@media ', '')} type="image/svg+xml" srcSet={`${HOST}images/site/${NAME_COMPLET_WHITE_LOGO}`} />
                                <source media={theme.breakpoints.up('xs').replace('@media ', '')} type="image/svg+xml" srcSet={`${HOST}images/site/${NAME_BC_WHITE_LOGO}`} />
                                <img src={`${HOST}images/site/${NAME_COMPLET_WHITE_LOGO}`} alt={BOUBLIL_COLLECTION} />
                            </picture>
                        </Link>
                        <Box sx={{ flexGrow: 1 }} />
                        {/* Menu droite */}
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <DropdownLang />
                            <AccountMenu user={user} />
                            <DrawerHamburger handleDrawerToggle={handleDrawerToggle} />
                            {/* <MenuMobile urlLang={urlLang} /> */}
                        </Box>
                        {/* Fin Menu droite */}
                    </Toolbar>
                </Container>
            </Box>
            {/* Fin NavBar globale */}
        </Header>
    )
}