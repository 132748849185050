import React, { useState } from 'react'
import { useSelector } from 'react-redux'


import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FilledInput from '@mui/material/FilledInput'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { ArrowDoubleRightIcon } from '../../theme/icons'

import { LANG_ARRAY_CODE_DIR_RTL } from '../../config'
import getLabel from '../../constants/textMultiLang'

import axios from '../../utils/axios'


const CommentairesForm = () => {
    const [values, setValues] = useState({
        comment: '',
        name: '',
        firstName: '',
        email: '',
        // showPassword: false,
    })
    const { lang } = useSelector(state => state.app)

    const {
        NAME,
        FIRST_NAME,
        EMAIL,
        COMMENT,
        SEND
    } = getLabel(lang, [
        'NAME',
        'FIRST_NAME',
        'EMAIL',
        'COMMENT',
        'SEND'
    ]) 
    
    const handleChange = prop => e => {
        setValues({ ...values, [prop]: e.target.value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        setValues({ comment: '', name: '', firstName: '', email: '' })
    }

    return (
        <Box
            component='form'
            id="form-comment"
            aria-labelledby="form-comment-title"
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
        >
            <Grid container columnGap={3}>
                <Grid xs={12} md={7}>
                    <TextField
                        id='comment-input'
                        label={COMMENT}
                        size='small'
                        multiline
                        rows={4}
                        value={values.comment}
                        onChange={handleChange('comment')}
                        variant='filled'
                        required
                        sx={{ margin: '8px 0', '& label': { fontStyle: 'italic' } }}
                        fullWidth
                        // error
                        // helperText='Incorrect entry.'
                    />
                </Grid>
                <Grid xs={12} md={3} container sx={{ flexDirection: { sm: 'row', md: 'column' }, justifyContent: 'space-between' }}>
                    <Grid xs={12} sm={5} md={12}>
                        <FormControl sx={{ margin: '8px 0' }} variant='filled' size='small' fullWidth required>
                            <InputLabel htmlFor='name-input' sx={{ padding: '0 1px', fontStyle: 'italic' }}>{NAME}</InputLabel>
                            <FilledInput
                                id='name-input'
                                type='text'
                                value={values.name}
                                onChange={handleChange('name')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sm={5} md={12}>
                        <FormControl sx={{ margin: '8px 0' }} variant='filled' size='small' fullWidth required>
                            <InputLabel htmlFor='firstName-input' sx={{  padding: '0 1px', fontStyle: 'italic' }}>{FIRST_NAME}</InputLabel>
                            <FilledInput
                                id='firstName-input'
                                type='text'
                                value={values.firstName}
                                onChange={handleChange('firstName')}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container alignItems='center' columnGap={3} sx={{ justifyContent: { xs: 'space-between', md: 'flex-start' } }}>
                <Grid container xs={12} sm={5} md={7}>
                    <FormControl sx={{ margin: '8px 0', minWidth: '0' }} variant='filled' size='small' fullWidth required>
                        <InputLabel htmlFor='email-input' sx={{  padding: '0 1px', fontStyle: 'italic' }}>{EMAIL}</InputLabel>
                        <FilledInput
                            id='email-input'
                            type='email'
                            value={values.email}
                            onChange={handleChange('email')}
                        />
                    </FormControl>
                </Grid>
                <Grid sm={5} md={3} sx={{ flexGrow: 1 }}>
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        size='large'
                        endIcon={<ArrowDoubleRightIcon transform={ LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } />}
                        sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', width: '100%', justifyContent: 'space-between', borderRadius: '0 0 3rem 0', '&:hover': { color: 'rgb(250 250 250 / 80%)' } }}
                    >
                        <Typography component='span'>{SEND}</Typography>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CommentairesForm