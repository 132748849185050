import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
  } from "../../constants/actionTypes"


  import axios from "../../utils/axios"


  export const register = (username, email, password) => (dispatch) => {
    return axios.register(username, email, password).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        })
        return Promise.resolve()
      },
      (error) => {
        // const message =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString()
        dispatch({
          type: REGISTER_FAIL,
        })
        return Promise.reject()
      }
    )
  }

  export const login = data => dispatch => {
    return axios.login(data).then(
      (data) => {
          if(data.code === 200 && data.user) {
              dispatch({
                  type: LOGIN_SUCCESS,
                  payload: { user: data.user },
              })
          } else {
              dispatch({
                  type: LOGIN_FAIL,
              })
          }
          return Promise.resolve(data)
      },
      (error) => {
        // const message =
        //     (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //     error.message ||
        //     error.toString()
            dispatch({
              type: LOGIN_FAIL,
            })
        return Promise.reject();
      }
    )
  }

  export const getUser = () => (dispatch) => {
    return axios.getUser().then(
      (data) => {
        if(data.data.code === 200 && data.data.user) {
          dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: data.data.user },
          })
      } else {
          dispatch({
              type: LOGIN_FAIL,
              payload: { user: null },
          })
      }
        return Promise.resolve(data)
      },(error) => {
        // const message =
        //     (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //     error.message ||
        //     error.toString()
            dispatch({
              type: LOGIN_FAIL,
            })
        return Promise.reject()
      }
    )
  }

  export const logout = () => (dispatch) => {
    return axios.logout().then(
      (data) => {
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        })
        return Promise.resolve(data)
      },
      (error) => {
        // const message =
        //     (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //     error.message ||
        //     error.toString()
        return Promise.reject()
      }
    )
  }