import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { NavLink, Link as LinkRouter } from 'react-router-dom'

import { HOST, DEFAULT_LANG_CODE, PAGES_SITE_NAV, RS_LINKS } from '../../config'

import getLabel from '../../constants/textMultiLang'

import { styled, useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Toolbar from '@mui/material/Toolbar'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import DropdownLang from './DropdownLang'
import SearchBar from './SearchBar'
import MenuMobile from './MenuMobile'



const Header = styled((props) => ( <AppBar {...props} /> )) (
    ({ theme }) => ({
        // transition: 'padding ease 200ms, background-color 300ms linear, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        transition: 'ease 200ms',
        '&:not(.onScroll)': {
            backgroundColor: 'transparent',
        },
        '&.onScroll': {
            boxShadow: '0px 2px 4px -1px rgb(255 255 255 / 20%), 0px 4px 5px 0px rgb(255 255 255 / 14%), 0px 1px 10px 0px rgb(255 255 255 / 12%)',
            '& .MuiButton-root:hover, & .MuiIconButton-root:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)'
            },
            '& .navBar-container': {
                borderBottom: 'transparent',
                '& nav .MuiList-root .MuiLink-root': {
                    padding: 0,
                    '& .label': {
                        padding: 0
                    },
                },
            }
        },
        '&.onScroll.onScrollDown': {
            '& .sr-container-container': {
                display: 'none',
            },
            '& .navBar-container': {
                borderTop: 'transparent',
            }
        },
        '& .sr-container-container': {
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
            '& .sr-container': {
                borderLeft: theme.palette.secondary.main + ' solid 1px',
            },
        },
        '& .navBar-container': {
            [theme.breakpoints.up('md')]: {
                borderTop: theme.palette.secondary.main + ' solid 1px',
                borderBottom: theme.palette.secondary.main + ' solid 1px',
            },
        },
        '& .navBar-container .navBar .MuiList-root': {
            padding: 0,
            '& .MuiListItem-root': {
                paddingTop: 0,
                paddingBottom: 0,
            },
            '& .MuiLink-root': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '& .label': {
                    padding: '30px 0',
                    transition: 'padding 300ms linear'
                },
                '& .listBar': {
                    display: 'block',
                    backgroundColor: theme.palette.secondary.main,
                    width: 0,
                    height: '3px',
                },
            },
            '& .MuiLink-root.active': {
                '& .listBar': {
                    width: '100%',
                },
            },
        },
        '& ul a:hover, & a.Mui-focusVisible': {
            outline: 0,
            opacity: .7
        }
        // width: 300,
        // color: theme.palette.success.main,
        // '& .MuiSlider-thumb': {
        //   '&:hover, &.Mui-focusVisible': {
        //     boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
        //   },
        //   '&.Mui-active': {
        //     boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
        //   },
        // },
    })
);

export default function NavBar(props) {
    const theme = useTheme()
    const { lang } = useSelector(state => state.app)

    const { fake/*, window*/ } = props

    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang
    const triggerOnScroll = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        // target: window ? window() : undefined
    })

    const triggerOnScrollDown = useScrollTrigger({
        // disableHysteresis: true,
        threshold: 0,
        // target: window ? window() : undefined
    })

    const {
        FOLLOW_US,
        FOLLOW_US_ON,
        BOUBLIL_COLLECTION,
        NAME_COMPLET_WHITE_LOGO,
        NAME_BC_WHITE_LOGO,
        MAIN_MENU
    } = getLabel(lang, [
        'FOLLOW_US',
        'FOLLOW_US_ON',
        'BOUBLIL_COLLECTION',
        'NAME_COMPLET_WHITE_LOGO',
        'NAME_BC_WHITE_LOGO',
        'MAIN_MENU'
    ])

    const pages = PAGES_SITE_NAV.map(page => {
        page.name = getLabel(lang, [page.constName])[page.constName]
        return page
    })
    
    const RSLinks = RS_LINKS.map(page => {
        page.name = getLabel(lang, [page.constName])[page.constName]
        return page
    })
    
    
    return (
        <Header 
            component={fake ? 'div' : 'header'}
            sx={{ position: `${fake ? 'static' : 'fixed'}`, visibility: `${fake ? 'hidden' : 'visible'}` }} 
            color='primary' elevation={triggerOnScroll ? 4 : 0}  
            className={`${triggerOnScroll && !fake && 'onScroll'} ${triggerOnScrollDown && !fake && 'onScrollDown'}`} 
            aria-hidden={fake}
        >
            {/* RS container */}
            <Container className='sr-container-container'>
                <Grid container justifyContent='end' alignItems='center'>
                    <Grid container justifyContent='end' alignItems='center' className='sr-container'>
                        <Grid sx={{padding: '0 70px'}}>
                            <Typography>{FOLLOW_US}:</Typography>
                        </Grid>
                        <Grid>
                            <List sx={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
                                {RSLinks.map((page) => (
                                    <ListItem key={page.name}>
                                        <Link color='inherit' underline="none" target='blank' aria-label={FOLLOW_US_ON + page.name} rel='noopener noreferrer' href={`https://www.${page.link}`} noWrap>
                                            {page.name}
                                        </Link>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {/* Fin RS container */}

            {/* NavBar globale */}
            <Box className='navBar-container'>
                <Container>
                    <Toolbar disableGutters sx={{ alignItems: 'stretch' }}>
                        {/* Logo complet */}
                        <Link component={LinkRouter} to={`${urlLang}/`} sx={{ width: { xs: '2.5rem', sm: '11rem' }, marginRight: '40px' }} title={BOUBLIL_COLLECTION}>
                            <picture>
                                <source media={theme.breakpoints.up('sm').replace('@media ', '')} type="image/svg+xml" srcSet={`${HOST}images/site/${NAME_COMPLET_WHITE_LOGO}`} />
                                <source media={theme.breakpoints.up('xs').replace('@media ', '')} type="image/svg+xml" srcSet={`${HOST}images/site/${NAME_BC_WHITE_LOGO}`} />
                                <img src={`${HOST}images/site/${NAME_COMPLET_WHITE_LOGO}`} alt={BOUBLIL_COLLECTION} />
                            </picture>
                        </Link>
                        

                        {/* Desktop menu */}
                        <Box component={fake ? 'div' : 'nav'} className='navBar' aria-label={fake ? '' : MAIN_MENU} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <List sx={{ display: 'flex' }}>
                                {pages.map((page) => (
                                    <ListItem key={page.name}>
                                        <Link 
                                            component={NavLink}
                                            exact
                                            activeClassName='active'
                                            color='inherit'
                                            underline='none'
                                            noWrap
                                            to={urlLang + page.link[lang]}
                                        >
                                        <Typography component='span' sx={{ height: '3px' }} />
                                        <Typography component='span' className='label'>
                                            {page.name}
                                        </Typography>
                                            <Typography component='span' className='listBar' />
                                        </Link>
                                        
                                </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box sx={{ flexGrow: { xs: 1, md: 0 } }} />

                        {/* Menu droite */}
                        <Box sx={{display: 'flex', /* flexDirection: { xs: 'column', md: 'row' }, */ alignItems: 'center'}}>
                            <Box sx={{ display: { xs: 'none', md: 'block', } }}>
                                <DropdownLang fake={fake} />
                            </Box>
                            <SearchBar fake={fake} />
                            <MenuMobile urlLang={urlLang} pages={pages} RSLinks={RSLinks} fake={fake} />
                        </Box>
                        {/* Fin Menu droite */}
                    </Toolbar>
                </Container>
            </Box>
            {/* Fin NavBar globale */}
        </Header>
    )
}