import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import MuiAlert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'

import getLabel from '../../constants/textMultiLang';


export default function Alert(props) {
    const { lang } = useSelector(state => state.app)
    const { open, setOpen, message } = props

    const {
        CLOSE_ALERT,
    } = getLabel(lang, [
        'CLOSE_ALERT',
    ])


    return (
        <Collapse in={open}>
            <MuiAlert
                action={
                    <IconButton
                        aria-label={CLOSE_ALERT}
                        color='inherit'
                        size='small'
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize='inherit' />
                    </IconButton> 
                }
                severity={message.severity}>{message.message}
            </MuiAlert>
        </Collapse>
    )
}

Alert.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
}