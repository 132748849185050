import React, { useState, useRef, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../../redux/store'


import { setLanguage } from '../../utils/app'
import { setLang } from '../../redux/actions/app'
import { LANG_ARRAY, LANG_ARRAY_CODE_DIR_RTL } from '../../config'

import getLabel from '../../constants/textMultiLang'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Grow from '@mui/material/Grow';
import SvgIcon from '@mui/material/SvgIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { visuallyHidden } from '@mui/utils';






const DropdownLang = (props) => {
    const dispatch = useDispatch()
    const theme = useTheme()

    let { lang } = useSelector(state => state.app)

    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const { fake } = props

    const CHOOSE_LANG = {}
    LANG_ARRAY.forEach(e => {
        CHOOSE_LANG[e.code] = getLabel(e.code, [ 'CHOOSE_LANG' ]).CHOOSE_LANG
    })

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return
        setOpen(false)
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false)
            anchorRef.current.focus()

        prevOpen.current = open
    }, [open])


    const handleLang = e => {
        e.preventDefault()
        handleToggle()
        const newLang = e.target.lang
        if(newLang === lang)
            return
        theme.direction = LANG_ARRAY_CODE_DIR_RTL.includes(newLang) ? 'rtl' : 'ltr'
        localStorage.setItem('lang', newLang)
        const userLang = setLanguage(history)
        dispatch(setLang(userLang))
    }

    return (
        <Box sx={{position: 'relative'}}>
            <Button
                color='inherit'
                ref={anchorRef}
                id={`lang-button${!fake ? '' : '-fake'}`}
                aria-controls='menu-lang'
                aria-expanded={open}
                aria-haspopup='true'
                onClick={handleToggle}
            >
                {LANG_ARRAY.map(item => (
                    <Typography 
                        component='span'
                        key={item.code}
                        sx={visuallyHidden}
                        lang={item.code}
                        className='notranslate'
                    >
                        {`${CHOOSE_LANG[item.code]}: ${item.lang}`}
                    </Typography>
                ))}
                <Typography component='span' className='notranslate' aria-hidden='true'>
                   {LANG_ARRAY.filter (e => e.code === lang)[0].code}
                </Typography>
                <SvgIcon sx={{ transition: 'transform 300ms', transform: `rotate(${open ? 180 : 0 }deg)` }}>
                    <ExpandMoreIcon />
                </SvgIcon>
            </Button>
            {!fake && (
            <Popper
                sx={{ width: '100%' }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                keepMounted
            >
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{
                    transformOrigin: placement === 'top',
                }}
                >
                    <Paper 
                        sx={{ maxHeight: 'calc(100vh - 60px)', overflow: 'auto' }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id="menu-lang"
                                aria-labelledby="lang-button"
                                onKeyDown={handleListKeyDown}
                            >
                                {LANG_ARRAY.map((item) => (
                                    <MenuItem
                                        key={item.code}
                                        className='notranslate'
                                        lang={item.code}
                                        aria-label={item.lang}
                                        aria-hidden='true'
                                        onClick={handleLang}
                                        aria-current={lang === item.code}
                                        selected={lang === item.code}
                                    >
                                        {item.code.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
            </Popper>
            )}
        </Box>
    )

}

export default DropdownLang