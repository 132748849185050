import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'



import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'



import NavBar from './headers/NavBar'
import Banner from './headers/Banner'
import Footer from './footer/Footer'

import { HOST } from '../config'
import getLabel from '../constants/textMultiLang'


export default function NotFound() {
    const { lang } = useSelector(state => state.app)

    const {
        BOUBLIL_COLLECTION,
        NOT_FOUND_PAGE,
        NOT_FOUND_IMG,
        PAGE_NOT_EXIST,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'NOT_FOUND_PAGE',
        'NOT_FOUND_IMG',
        'PAGE_NOT_EXIST',
    ])

    useEffect(() => {
        document.title = `${NOT_FOUND_PAGE} | ${BOUBLIL_COLLECTION}`
    }, [NOT_FOUND_PAGE, BOUBLIL_COLLECTION])

    return (
        <>
            <Helmet>
                <meta name="description" content="Boublil Collection Description Page not found" />
                <meta name="twitter:title" content="Page not found | Boublil Collection" />
                <meta name="twitter:description" content="Boublil Collection Description Page not found" />
                <meta property="og:title" content="Page not found | Boublil Collection" />
                <meta property="og:description" content="Boublil Collection Description Page not found" />
            </Helmet>
            <NavBar />
            <Banner page={NOT_FOUND_PAGE} component='h1' />
            <Box component='section' sx={{ padding: { xs: '50px 0', md: '100px 0' } }}>
                <Container>
                    <Grid container justifyContent='space-around' alignItems='center'>
                        <Grid>
                            <Typography sx={{ marginBottom: '40px' }}>
                                <Typography variant='h4' component='span'>Ouuuuuuups !</Typography><br />
                                {PAGE_NOT_EXIST}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Box component='img' src={`${HOST}images/site/404_img.png`} alt={NOT_FOUND_IMG} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </>
    )
}