const textMultiLang = { en: {}, fr: {}, he: {}, }

/* NAVBAR */
textMultiLang.en.FOLLOW_US = 'Follow us'
textMultiLang.fr.FOLLOW_US = 'Suivez nous'
textMultiLang.he.FOLLOW_US = 'עיקבו'

textMultiLang.en.FOLLOW_US_ON = 'Follow us on '
textMultiLang.fr.FOLLOW_US_ON = 'Suivez nous sur '
textMultiLang.he.FOLLOW_US_ON = 'עיקבו ב'

textMultiLang.en.FACEBOOK = 'Facebook'
textMultiLang.fr.FACEBOOK = 'Facebook'
textMultiLang.he.FACEBOOK = 'פייסבוק'

textMultiLang.en.INSTAGRAM = 'Instagram'
textMultiLang.fr.INSTAGRAM = 'Instagram'
textMultiLang.he.INSTAGRAM = 'אינסטגרם'

textMultiLang.en.TWITTER = 'Twitter'
textMultiLang.fr.TWITTER = 'Twitter'
textMultiLang.he.TWITTER = 'טוויטר'

textMultiLang.en.WHATSAPP = 'WhatsApp'
textMultiLang.fr.WHATSAPP = 'WhatsApp'
textMultiLang.he.WHATSAPP = 'וואטסאפ'

textMultiLang.en.TELEGRAM = 'Telegram'
textMultiLang.fr.TELEGRAM = 'Telegram'
textMultiLang.he.TELEGRAM = 'טלגרם'

textMultiLang.en.MVDJ = 'The Jerusalem Museum'
textMultiLang.fr.MVDJ = 'Le Musée de Jérusalem'
textMultiLang.he.MVDJ = 'מוזיאון ירושלים'

textMultiLang.en.NAME_COMPLET_WHITE_LOGO = 'logo_complet_white.svg'
textMultiLang.fr.NAME_COMPLET_WHITE_LOGO = 'logo_complet_white.svg'
textMultiLang.he.NAME_COMPLET_WHITE_LOGO = 'logo_complet_white.svg'

textMultiLang.en.NAME_COMPLET_BLACK_LOGO = 'logo_complet_black.svg'
textMultiLang.fr.NAME_COMPLET_BLACK_LOGO = 'logo_complet_black.svg'
textMultiLang.he.NAME_COMPLET_BLACK_LOGO = 'logo_complet_black.svg'

textMultiLang.en.NAME_BC_WHITE_LOGO = 'logo_BC_white.png'
textMultiLang.fr.NAME_BC_WHITE_LOGO = 'logo_BC_white.png'
textMultiLang.he.NAME_BC_WHITE_LOGO = 'logo_BC_white.png'

textMultiLang.en.OPEN_MENU = 'Open menu'
textMultiLang.fr.OPEN_MENU = 'Ouvrir le menu'
textMultiLang.he.OPEN_MENU = 'פתח תפריט'

textMultiLang.en.CLOSE_MENU = 'Close menu'
textMultiLang.fr.CLOSE_MENU = 'Fermer le menu'
textMultiLang.he.CLOSE_MENU = 'סגור תפריט'

textMultiLang.en.OPEN_ACCOUNT_OPTIONS = 'Open account options'
textMultiLang.fr.OPEN_ACCOUNT_OPTIONS = 'Ouvrir les options de compte'
textMultiLang.he.OPEN_ACCOUNT_OPTIONS = 'פתח אפשרויות חשבון'


textMultiLang.en.PROFILE_MENU = 'Profile menu'
textMultiLang.fr.PROFILE_MENU = 'Menu du compte'
textMultiLang.he.PROFILE_MENU = 'תפריט פרופיל'

textMultiLang.en.ACCOUNT = 'My account'
textMultiLang.fr.ACCOUNT = 'Mon compte'
textMultiLang.he.ACCOUNT = 'חשבון שלי'

textMultiLang.en.LOGOUT = 'Logout'
textMultiLang.fr.LOGOUT = 'Déconnexion'
textMultiLang.he.LOGOUT = 'יציאה מהחשבון'

textMultiLang.en.CHOOSE_LANG = 'Choose your language. The selected language is'
textMultiLang.fr.CHOOSE_LANG = 'Choisissez votre langue. La langue sélectionnée est'
textMultiLang.he.CHOOSE_LANG = 'בחר שפה. השפה שנבחרה היא'

// ALL
textMultiLang.en.BOUBLIL_COLLECTION = 'Boublil Collection'
textMultiLang.fr.BOUBLIL_COLLECTION = 'Boublil Collection'
textMultiLang.he.BOUBLIL_COLLECTION = 'בובליל קולקשיין'

textMultiLang.en.MAIN_MENU = 'Main menu'
textMultiLang.fr.MAIN_MENU = 'Menu principal'
textMultiLang.he.MAIN_MENU = 'תפריט ראשי'

textMultiLang.en.CLOSE = 'Close'
textMultiLang.fr.CLOSE = 'Fermer'
textMultiLang.he.CLOSE = 'סגור'

textMultiLang.en.CLOSE_ALERT = 'Close alert'
textMultiLang.fr.CLOSE_ALERT = 'Fermer l\'alerte'
textMultiLang.he.CLOSE_ALERT = 'סגור את ההודעה'

textMultiLang.en.ATTENTION = 'Attention !!'
textMultiLang.fr.ATTENTION = 'Attention !!'
textMultiLang.he.ATTENTION = 'זהירות !!'

textMultiLang.en.WANT_DELETE_FILE = 'Do you want to delete this file ?'
textMultiLang.fr.WANT_DELETE_FILE = 'Voulez-vous supprimer ce fichier ?'
textMultiLang.he.WANT_DELETE_FILE = 'רצונך למחוק את הקובץ ?'

textMultiLang.en.WANT_DELETE_ARTIST = 'Do you want to delete this artist ?'
textMultiLang.fr.WANT_DELETE_ARTIST = 'Voulez-vous supprimer cet artiste ?'
textMultiLang.he.WANT_DELETE_ARTIST = 'רצונך למחוק את האמן ?'

textMultiLang.en.WANT_DELETE_GENRE = 'Do you want to delete this genre ?'
textMultiLang.fr.WANT_DELETE_GENRE = 'Voulez-vous supprimer ce genre ?'
textMultiLang.he.WANT_DELETE_GENRE = 'רצונך למחוק את הז\'אנר ?'

textMultiLang.en.WANT_DELETE_CURRENT = 'Do you want to delete this current ?'
textMultiLang.fr.WANT_DELETE_CURRENT = 'Voulez-vous supprimer ce courant ?'
textMultiLang.he.WANT_DELETE_CURRENT = 'רצונך למחוק את התקופה ?'

textMultiLang.en.WANT_ABLE_TO_REVERT = 'You won\'t be able to revert this !'
textMultiLang.fr.WANT_ABLE_TO_REVERT = 'Vous ne pourrez pas revenir en arrière !'
textMultiLang.he.WANT_ABLE_TO_REVERT = 'לא תוכל לבטל את הפעולה !'

textMultiLang.en.OK = 'OK'
textMultiLang.fr.OK = 'OK'
textMultiLang.he.OK = 'אישור'

textMultiLang.en.YES = 'Yes'
textMultiLang.fr.YES = 'Oui'
textMultiLang.he.YES = 'כן'

textMultiLang.en.NO = 'No'
textMultiLang.fr.NO = 'Non'
textMultiLang.he.NO = 'לא'

textMultiLang.en.TEXT = 'Text'
textMultiLang.fr.TEXT = 'Texte'
textMultiLang.he.TEXT = 'טקסט'

textMultiLang.en.UNE_ERREUR_S_EST_PRODUITE = 'An error has occurred'
textMultiLang.fr.UNE_ERREUR_S_EST_PRODUITE = 'Une erreur s\'est produite'
textMultiLang.he.UNE_ERREUR_S_EST_PRODUITE = 'אירעה שגיאה'

textMultiLang.en.MSG_NOT_SUPPORT_AUDIO_TAG = 'Your browser does not support the audio tag'
textMultiLang.fr.MSG_NOT_SUPPORT_AUDIO_TAG = 'Votre navigateur ne supporte pas la balise audio'
textMultiLang.he.MSG_NOT_SUPPORT_AUDIO_TAG = 'הדפדפן שלך אינו תומך בתג האודיו'

textMultiLang.en.JOSE_BOUBLIL = 'Jose Boublil'
textMultiLang.fr.JOSE_BOUBLIL = 'José Boublil'
textMultiLang.he.JOSE_BOUBLIL = 'ישועה בובליל'

// FORM
textMultiLang.en.COMPLETE_FIELD = 'Please complete this field'
textMultiLang.fr.COMPLETE_FIELD = 'Veuillez renseigner ce champ'
textMultiLang.he.COMPLETE_FIELD = 'נא למלא שדה זה'

textMultiLang.en.COMPLETE_ATTACH_FILE1 = 'Please attach a'
textMultiLang.fr.COMPLETE_ATTACH_FILE1 = 'Veuillez joindre un fichier'
textMultiLang.he.COMPLETE_ATTACH_FILE1 = 'נא לצרף קובץ'

textMultiLang.en.COMPLETE_ATTACH_FILE2 = ' file'
textMultiLang.fr.COMPLETE_ATTACH_FILE2 = ''
textMultiLang.he.COMPLETE_ATTACH_FILE2 = ''

textMultiLang.en.ATTACH_FILE_AUDIO = 'n audio'
textMultiLang.fr.ATTACH_FILE_AUDIO = ' audio'
textMultiLang.he.ATTACH_FILE_AUDIO = ' שמע'

textMultiLang.en.CE_CHAMPS_EST_OBLIGATOIRE = 'This field is required'
textMultiLang.fr.CE_CHAMPS_EST_OBLIGATOIRE = 'Ce champs est obligatoire'
textMultiLang.he.CE_CHAMPS_EST_OBLIGATOIRE = 'שדה חובה'

textMultiLang.en.ADRESSE_MAIL_MDP_INCORRECTE = 'The email address or password is incorrect'
textMultiLang.fr.ADRESSE_MAIL_MDP_INCORRECTE = 'L\'adresse email ou le mote de passe est incorrecte'
textMultiLang.he.ADRESSE_MAIL_MDP_INCORRECTE = 'כתובת האימייל או הסיסמה שגוייה'

textMultiLang.en.ATTACH_AUDIO_FILE = 'Please attach an audio file'
textMultiLang.fr.ATTACH_AUDIO_FILE = 'Veuillez joindre un fichier audio'
textMultiLang.he.ATTACH_AUDIO_FILE = 'אנא צרף קובץ שמע'

textMultiLang.en.REGISTERED_SUCCESSFULLY = 'Registered successfully'
textMultiLang.fr.REGISTERED_SUCCESSFULLY = 'Enregistré avec succès'
textMultiLang.he.REGISTERED_SUCCESSFULLY = 'נשמר בהצלחה'

textMultiLang.en.LOGIN = 'Login'
textMultiLang.fr.LOGIN = 'Connexion'
textMultiLang.he.LOGIN = 'התחברות'

textMultiLang.en.MESSAGE = 'Message'
textMultiLang.fr.MESSAGE = 'Message'
textMultiLang.he.MESSAGE = 'הודעה'

textMultiLang.en.COMMENT = 'Comment'
textMultiLang.fr.COMMENT = 'Commentaire'
textMultiLang.he.COMMENT = 'תגובה'

textMultiLang.en.NAME = 'Name'
textMultiLang.fr.NAME = 'Nom'
textMultiLang.he.NAME = 'שם'

textMultiLang.en.FIRST_NAME = 'First name'
textMultiLang.fr.FIRST_NAME = 'Prénom'
textMultiLang.he.FIRST_NAME = 'שם פרטי'

textMultiLang.en.EMAIL = 'Email'
textMultiLang.fr.EMAIL = 'Email'
textMultiLang.he.EMAIL = 'אימייל'

textMultiLang.en.PASSWORD = 'Password'
textMultiLang.fr.PASSWORD = 'Mot de passe'
textMultiLang.he.PASSWORD = 'סיסמא'

textMultiLang.en.FILE = 'File'
textMultiLang.fr.FILE = 'Fichier'
textMultiLang.he.FILE = 'קובץ'

textMultiLang.en.PERIOD = 'Period'
textMultiLang.fr.PERIOD = 'Période'
textMultiLang.he.PERIOD = 'תקופה'

textMultiLang.en.START_YEAR = 'Start year'
textMultiLang.fr.START_YEAR = 'Année début'
textMultiLang.he.START_YEAR = 'שנת התחלה'

textMultiLang.en.END_YEAR = 'End year'
textMultiLang.fr.END_YEAR = 'Année fin'
textMultiLang.he.END_YEAR = 'שנת סוף'

textMultiLang.en.CHOOSE_FILE = 'Choose a file'
textMultiLang.fr.CHOOSE_FILE = 'Choisissez un fichier'
textMultiLang.he.CHOOSE_FILE = 'בחר קובץ'

textMultiLang.en.OR_DRAG_IT_HERE = 'or drag it here'
textMultiLang.fr.OR_DRAG_IT_HERE = 'ou faites-le glisser ici'
textMultiLang.he.OR_DRAG_IT_HERE = 'או גרור אותו כאן'

textMultiLang.en.DELETE_FILE = 'Delete file'
textMultiLang.fr.DELETE_FILE = 'Supprimer le fichier'
textMultiLang.he.DELETE_FILE = 'מחק קובץ'

textMultiLang.en.ADD = 'Add'
textMultiLang.fr.ADD = 'Ajouter'
textMultiLang.he.ADD = 'הוסף'

textMultiLang.en.SELECT = 'Select'
textMultiLang.fr.SELECT = 'Sélectionner'
textMultiLang.he.SELECT = 'בחר'

textMultiLang.en.CANCEL = 'Cancel'
textMultiLang.fr.CANCEL = 'Annuler'
textMultiLang.he.CANCEL = 'ביטול'

textMultiLang.en.MODIFY = 'Modify'
textMultiLang.fr.MODIFY = 'Modifier'
textMultiLang.he.MODIFY = 'שינוי'

textMultiLang.en.SAVE = 'Save'
textMultiLang.fr.SAVE = 'Enregister'
textMultiLang.he.SAVE = 'שמור'

textMultiLang.en.DELETE = 'Delete'
textMultiLang.fr.DELETE = 'Supprimer'
textMultiLang.he.DELETE = 'מחק'

textMultiLang.en.SEND = 'Send'
textMultiLang.fr.SEND = 'Envoyer'
textMultiLang.he.SEND = 'שלח'

textMultiLang.en.SEARCH = 'Search'
textMultiLang.fr.SEARCH = 'Rechercher'
textMultiLang.he.SEARCH = 'חפש'

textMultiLang.en.SEARCH2 = 'Search'
textMultiLang.fr.SEARCH2 = 'Recherche'
textMultiLang.he.SEARCH2 = 'חיפוש'

textMultiLang.en.ADVANCED_SEARCH = 'Advanced search'
textMultiLang.fr.ADVANCED_SEARCH = 'Recherche avancée'
textMultiLang.he.ADVANCED_SEARCH = 'חיפוש מתקדם'

textMultiLang.en.NO_OPTION = 'No option'
textMultiLang.fr.NO_OPTION = 'Aucune option'
textMultiLang.he.NO_OPTION = 'רשימה ריקה'


// PAGE NOT FOUND
textMultiLang.en.NOT_FOUND_PAGE = 'Page not found'
textMultiLang.fr.NOT_FOUND_PAGE = 'Page non trouvée'
textMultiLang.he.NOT_FOUND_PAGE = 'דף לא נמצא'

textMultiLang.en.NOT_FOUND_IMG = 'Not found image'
textMultiLang.fr.NOT_FOUND_IMG = 'Image page non trouvée'
textMultiLang.he.NOT_FOUND_IMG = 'תמונה דף לא נמצא'

textMultiLang.en.PAGE_NOT_EXIST = 'The page you are looking for cannot be found...'
textMultiLang.fr.PAGE_NOT_EXIST = 'La page que vous recherchez semble introuvable...'
textMultiLang.he.PAGE_NOT_EXIST = 'הדף המבוקש לא נמצא...'


// HOME PAGE
textMultiLang.en.HOME_PAGE = 'Home page'
textMultiLang.fr.HOME_PAGE = 'Accueil'
textMultiLang.he.HOME_PAGE = 'דף הבית'

textMultiLang.en.DESCRIPTION_HOME_PAGE = 'Description Home page'
textMultiLang.fr.DESCRIPTION_HOME_PAGE = 'Description Home page'
textMultiLang.he.DESCRIPTION_HOME_PAGE = 'Description Home page'

textMultiLang.en.DETAIL_OF_PAINTING = 'Detail of the painting'
textMultiLang.fr.DETAIL_OF_PAINTING = 'Détail de l\'œuvre'
textMultiLang.he.DETAIL_OF_PAINTING = 'פירוט הציור'

textMultiLang.en.MR_BOUBLIL = 'Mr Boublil'
textMultiLang.fr.MR_BOUBLIL = 'Mr Boublil'
textMultiLang.he.MR_BOUBLIL = 'ישועה בובליל'

textMultiLang.en.CONTACT_ME = 'Contact me'
textMultiLang.fr.CONTACT_ME = 'Me contacter'
textMultiLang.he.CONTACT_ME = 'צור איתי קשר'


// PAINTING PAGE
textMultiLang.en.PAINTING_PAGE = 'Painting'
textMultiLang.fr.PAINTING_PAGE = 'L\'œuvre'
textMultiLang.he.PAINTING_PAGE = 'הציור'

textMultiLang.en.DESCRIPTION_PAINTING_PAGE = 'Description Painting page'
textMultiLang.fr.DESCRIPTION_PAINTING_PAGE = 'Description Painting page'
textMultiLang.he.DESCRIPTION_PAINTING_PAGE = 'Description Painting page'

textMultiLang.en.ARTIST = 'Artist'
textMultiLang.fr.ARTIST = 'Artiste'
textMultiLang.he.ARTIST = 'צייר'

textMultiLang.en.GENRE = 'Genre'
textMultiLang.fr.GENRE = 'Genre'
textMultiLang.he.GENRE = 'ז\'אנר'

textMultiLang.en.CURRENT = 'Current'
textMultiLang.fr.CURRENT = 'Courant'
textMultiLang.he.CURRENT = 'תקופה'

textMultiLang.en.LISTEN_DESCRIPTION = 'Listen'
textMultiLang.fr.LISTEN_DESCRIPTION = 'Ecouter'
textMultiLang.he.LISTEN_DESCRIPTION = 'האזנה'

textMultiLang.en.SHARE_PAGE = 'Share'
textMultiLang.fr.SHARE_PAGE = 'Partager'
textMultiLang.he.SHARE_PAGE = 'שיתפו'

textMultiLang.en.ON = 'on '
textMultiLang.fr.ON = 'sur '
textMultiLang.he.ON = 'ב'

textMultiLang.en.BY = 'by '
textMultiLang.fr.BY = 'par '
textMultiLang.he.BY = 'ב'

textMultiLang.en.BY2 = 'By '
textMultiLang.fr.BY2 = 'Par '
textMultiLang.he.BY2 = 'מ-'

textMultiLang.en.SHARE_PAGE_WHATSAPP_TEXT = 'Message. Link: '
textMultiLang.fr.SHARE_PAGE_WHATSAPP_TEXT = 'Message. Lien: '
textMultiLang.he.SHARE_PAGE_WHATSAPP_TEXT = 'הודעה. קישור: '

textMultiLang.en.SHARE_PAGE_TELEGRAM_TEXT = 'Message'
textMultiLang.fr.SHARE_PAGE_TELEGRAM_TEXT = 'Message'
textMultiLang.he.SHARE_PAGE_TELEGRAM_TEXT = 'הודעה'

textMultiLang.en.SHARE_PAGE_EMAIL_TEXT = 'Message. Link: '
textMultiLang.fr.SHARE_PAGE_EMAIL_TEXT = 'Message. Lien: '
textMultiLang.he.SHARE_PAGE_EMAIL_TEXT = 'הודעה. קישור: '

textMultiLang.en.SHARE_PAGE_EMAIL_SUBJECT = 'Subject'
textMultiLang.fr.SHARE_PAGE_EMAIL_SUBJECT = 'Sujet'
textMultiLang.he.SHARE_PAGE_EMAIL_SUBJECT = 'נושא'

textMultiLang.en.DESCRIPTION = 'Description'
textMultiLang.fr.DESCRIPTION = 'Description'
textMultiLang.he.DESCRIPTION = 'תיאור'

textMultiLang.en.CITATION = 'Citation'
textMultiLang.fr.CITATION = 'Citation'
textMultiLang.he.CITATION = 'ציטוט'

textMultiLang.en.PAINTING_COMMENTS = 'Comments'
textMultiLang.fr.PAINTING_COMMENTS = 'Les commentaires'
textMultiLang.he.PAINTING_COMMENTS = 'תגובות'

textMultiLang.en.POST_COMMENT = 'Post a comment'
textMultiLang.fr.POST_COMMENT = 'Poster un commentaires'
textMultiLang.he.POST_COMMENT = 'פרסם תגובה'

textMultiLang.en.DISCOVER = 'Discover'
textMultiLang.fr.DISCOVER = 'Découvrir'
textMultiLang.he.DISCOVER = 'לגלות'

textMultiLang.en.THE_PAINTINGS_FOLLOW = 'The paintings'
textMultiLang.fr.THE_PAINTINGS_FOLLOW = 'Les œuvres'
textMultiLang.he.THE_PAINTINGS_FOLLOW = 'את הציורים'

textMultiLang.en.ALL_THE_PAINTINGS = 'All the paintings'
textMultiLang.fr.ALL_THE_PAINTINGS = 'Toutes les œuvres'
textMultiLang.he.ALL_THE_PAINTINGS = 'כל הציורים'

textMultiLang.en.PICTURE_PREVIEW = 'Picture preview'
textMultiLang.fr.PICTURE_PREVIEW = 'Aperçu de l\'image'
textMultiLang.he.PICTURE_PREVIEW = 'הצגת תמונה'

textMultiLang.en.OPEN_PICTURE_PREVIEW = 'Open picture preview'
textMultiLang.fr.OPEN_PICTURE_PREVIEW = 'Ouvrir l\'aperçu de l\'image'
textMultiLang.he.OPEN_PICTURE_PREVIEW = 'הצגת תמונה'



// PAINTINGS PAGE
textMultiLang.en.PAINTINGS_PAGE = 'Paintings'
textMultiLang.fr.PAINTINGS_PAGE = 'Les œuvres'
textMultiLang.he.PAINTINGS_PAGE = 'הציורים'

textMultiLang.en.DESCRIPTION_PAINTINGS_PAGE = 'Description Paintings page'
textMultiLang.fr.DESCRIPTION_PAINTINGS_PAGE = 'Description Paintings page'
textMultiLang.he.DESCRIPTION_PAINTINGS_PAGE = 'Description Paintings page'

textMultiLang.en.PAGINATION_NAVIGATION = 'Pagination navigation'
textMultiLang.fr.PAGINATION_NAVIGATION = 'Nagination de pagination'
textMultiLang.he.PAGINATION_NAVIGATION = 'ניווט דפים'

textMultiLang.en.GO_TO_PREVIOUS_PAGE = 'Go to previous page'
textMultiLang.fr.GO_TO_PREVIOUS_PAGE = 'Aller à la page précédente'
textMultiLang.he.GO_TO_PREVIOUS_PAGE = 'חזור לדף הקודם'

textMultiLang.en.GO_TO_NEXT_PAGE = 'Go to next page'
textMultiLang.fr.GO_TO_NEXT_PAGE = 'Aller à la page suivante'
textMultiLang.he.GO_TO_NEXT_PAGE = 'עבור לדף הבא'

textMultiLang.en.GO_START_ELLIPSIS_PAGE = 'Go to start-ellipsis page'
textMultiLang.fr.GO_START_ELLIPSIS_PAGE = 'Aller vers les pages précédentes. Points de suspension'
textMultiLang.he.GO_START_ELLIPSIS_PAGE = 'חזור לדפים הקודמים. נקודות השעיה'

textMultiLang.en.GO_END_ELLIPSIS_PAGE = 'Go to end-ellipsis page'
textMultiLang.fr.GO_END_ELLIPSIS_PAGE = 'Aller vers les pages suivantes. Points de suspension'
textMultiLang.he.GO_END_ELLIPSIS_PAGE = 'עבור לדפים הבאים. נקודות השעיה'

textMultiLang.en.PAGE = 'Page'
textMultiLang.fr.PAGE = 'Page'
textMultiLang.he.PAGE = 'דף'

textMultiLang.en.PAINTINGS_FOUND = 'nbr paintings found'
textMultiLang.fr.PAINTINGS_FOUND = 'nbr œuvres trouvées'
textMultiLang.he.PAINTINGS_FOUND = 'נמצאו nbr  ציורים'

textMultiLang.en.PAINTING_FOUND = '1 painting found'
textMultiLang.fr.PAINTING_FOUND = '1 œuvre trouvée'
textMultiLang.he.PAINTING_FOUND = 'נמצא 1  ציור'

textMultiLang.en.PAINTING_NOT_FOUND = '0 painting found'
textMultiLang.fr.PAINTING_NOT_FOUND = '0 œuvre trouvée'
textMultiLang.he.PAINTING_NOT_FOUND = 'לא נמצא ציור'

// CONTACT PAGE
textMultiLang.en.CONTACT_PAGE = 'Contact'
textMultiLang.fr.CONTACT_PAGE = 'Contact'
textMultiLang.he.CONTACT_PAGE = 'צור קשר'

textMultiLang.en.DESCRIPTION_CONTACT_PAGE = 'Description Contact page'
textMultiLang.fr.DESCRIPTION_CONTACT_PAGE = 'Description Contact page'
textMultiLang.he.DESCRIPTION_CONTACT_PAGE = 'Description Contact page'

textMultiLang.en.INFORMATIONS = 'Informations'
textMultiLang.fr.INFORMATIONS = 'Informations'
textMultiLang.he.INFORMATIONS = 'מידע כללי'

textMultiLang.en.WRITING = 'Writing'
textMultiLang.fr.WRITING = 'Rédaction'
textMultiLang.he.WRITING = 'צור קשר'

textMultiLang.en.SUPPORT = 'Support'
textMultiLang.fr.SUPPORT = 'Support technique'
textMultiLang.he.SUPPORT = 'תמיכה טכנית'

textMultiLang.en.ADDRESS = 'Address'
textMultiLang.fr.ADDRESS = 'Adresse'
textMultiLang.he.ADDRESS = 'כתובת'

textMultiLang.en.TEL = 'Tel'
textMultiLang.fr.TEL = 'Tél'
textMultiLang.he.TEL = 'טל'

textMultiLang.en.POST_MESSAGE = 'Send message'
textMultiLang.fr.POST_MESSAGE = 'Envoyer un message'
textMultiLang.he.POST_MESSAGE = 'שלח הודעה'

// COOKIE PAGE
textMultiLang.en.COOKIE_PAGE = 'Cookie'
textMultiLang.fr.COOKIE_PAGE = 'Cookie'
textMultiLang.he.COOKIE_PAGE = 'עוגיות'

textMultiLang.en.DESCRIPTION_COOKIE_PAGE = 'Description cookie page'
textMultiLang.fr.DESCRIPTION_COOKIE_PAGE = 'Description cookie page'
textMultiLang.he.DESCRIPTION_COOKIE_PAGE = 'Description cookie page'

// PRIVACY PAGE
textMultiLang.en.PRIVACY_POLICY_PAGE = 'Privacy policy'
textMultiLang.fr.PRIVACY_POLICY_PAGE = 'Politique de confidentialité'
textMultiLang.he.PRIVACY_POLICY_PAGE = 'מדיניות פרטיות'

textMultiLang.en.DESCRIPTION_PRIVACY_POLICY_PAGE = 'Description privacy policy page'
textMultiLang.fr.DESCRIPTION_PRIVACY_POLICY_PAGE = 'Description privacy policy page'
textMultiLang.he.DESCRIPTION_PRIVACY_POLICY_PAGE = 'Description privacy policy page'

// LEGAL NOTICE PAGE
textMultiLang.en.LEGAL_NOTICE_PAGE = 'Legal notice'
textMultiLang.fr.LEGAL_NOTICE_PAGE = 'Mentions légales'
textMultiLang.he.LEGAL_NOTICE_PAGE = 'הבהרות-משפטיות'

textMultiLang.en.DESCRIPTION_LEGAL_NOTICE_PAGE = 'Description legal notice page'
textMultiLang.fr.DESCRIPTION_LEGAL_NOTICE_PAGE = 'Description legal notice page'
textMultiLang.he.DESCRIPTION_LEGAL_NOTICE_PAGE = 'Description legal notice page'

// ACCESSIBILITY PAGE
textMultiLang.en.ACCESSIBILITY_PAGE = 'Accessibility'
textMultiLang.fr.ACCESSIBILITY_PAGE = 'Accessibilité'
textMultiLang.he.ACCESSIBILITY_PAGE = 'נגישות'

textMultiLang.en.DESCRIPTION_ACCESSIBILITY_PAGE = 'Description accessibility page'
textMultiLang.fr.DESCRIPTION_ACCESSIBILITY_PAGE = 'Description accessibility page'
textMultiLang.he.DESCRIPTION_ACCESSIBILITY_PAGE = 'Description accessibility page'

// ABOUT PAGE
textMultiLang.en.ABOUT_PAGE = 'About'
textMultiLang.fr.ABOUT_PAGE = 'A propos'
textMultiLang.he.ABOUT_PAGE = 'אודות'

textMultiLang.en.DESCRIPTION_ABOUT_PAGE = 'Description about page'
textMultiLang.fr.DESCRIPTION_ABOUT_PAGE = 'Description about page'
textMultiLang.he.DESCRIPTION_ABOUT_PAGE = 'Description about page'

// LECTEUR AUDIO
textMultiLang.en.PLAYER = 'Audio player'
textMultiLang.fr.PLAYER = 'Lecteur audio'
textMultiLang.he.PLAYER = 'נגן אודיו'

textMultiLang.en.PROGRESS_CONTROL = 'Progress bar control'
textMultiLang.fr.PROGRESS_CONTROL = 'Control de la barre de progression'
textMultiLang.he.PROGRESS_CONTROL = 'שליטה בסרגל ההתקדמות'

textMultiLang.en.VOLUME_CONTROL = 'Volume control'
textMultiLang.fr.VOLUME_CONTROL = 'Control du volume'
textMultiLang.he.VOLUME_CONTROL = 'בקרת עוצמת הקול'

textMultiLang.en.PLAY = 'Play'
textMultiLang.fr.PLAY = 'Jouer'
textMultiLang.he.PLAY = 'האזנה'

textMultiLang.en.PAUSE = 'Pause'
textMultiLang.fr.PAUSE = 'Pause'
textMultiLang.he.PAUSE = 'הפסקה'

textMultiLang.en.REWIND = 'Rewind'
textMultiLang.fr.REWIND = 'Saut en arrière'
textMultiLang.he.REWIND = 'קפוץ אחורה'

textMultiLang.en.FORWARD = 'Forward'
textMultiLang.fr.FORWARD = 'Saut en avant'
textMultiLang.he.FORWARD = 'קפוץ קדימה'

textMultiLang.en.PREVIOUS = 'Previous track'
textMultiLang.fr.PREVIOUS = 'Piste précédente'
textMultiLang.he.PREVIOUS = 'רצועה קודמת'

textMultiLang.en.NEXT = 'Next track'
textMultiLang.fr.NEXT = 'Piste suivante'
textMultiLang.he.NEXT = 'רצועה הבאה'

textMultiLang.en.LOOP = 'Loop playback'
textMultiLang.fr.LOOP = 'Lecture en boucle'
textMultiLang.he.LOOP = 'השמעת לולאה'

textMultiLang.en.LOOP_OFF = 'Loop playback'
textMultiLang.fr.LOOP_OFF = 'Désactiver la lecture en boucle'
textMultiLang.he.LOOP_OFF = 'הפסק לולאה'

textMultiLang.en.VOLUME = 'Mute'
textMultiLang.fr.VOLUME = 'Muet'
textMultiLang.he.VOLUME = 'להשתיק'

textMultiLang.en.VOLUME_MUTE = 'Turn on the sound'
textMultiLang.fr.VOLUME_MUTE = 'Allumer le sond'
textMultiLang.he.VOLUME_MUTE = 'הפעת הקול'


// CMS PAGE
textMultiLang.en.TREE_NAVIGATION = 'Tree navigation'
textMultiLang.fr.TREE_NAVIGATION = 'Arborescence de navigation'
textMultiLang.he.TREE_NAVIGATION = 'מבנה ניווט'

textMultiLang.en.DASHBOARD = 'Dashboard'
textMultiLang.fr.DASHBOARD = 'Dashboard'
textMultiLang.he.DASHBOARD = 'דשבורד'

textMultiLang.en.ARTISTS = 'Artist'
textMultiLang.fr.ARTISTS = 'Auteurs'
textMultiLang.he.ARTISTS = 'אמנים'

textMultiLang.en.GENRES = 'Genres'
textMultiLang.fr.GENRES = 'Genres'
textMultiLang.he.GENRES = 'ז\'אנרים'

textMultiLang.en.CURRENTS = 'Currents'
textMultiLang.fr.CURRENTS = 'Courants'
textMultiLang.he.CURRENTS = 'תקופות'

textMultiLang.en.PAINTINGS = 'Paintings'
textMultiLang.fr.PAINTINGS = 'Tableaux'
textMultiLang.he.PAINTINGS = 'ציורים'

textMultiLang.en.COMMENTS = 'Comments'
textMultiLang.fr.COMMENTS = 'Commentaires'
textMultiLang.he.COMMENTS = 'תגובות'

textMultiLang.en.SITE_MANAGEMENT = 'Site management'
textMultiLang.fr.SITE_MANAGEMENT = 'Gestion du site'
textMultiLang.he.SITE_MANAGEMENT = 'ניהול האתר'

textMultiLang.en.ACCOUNTS = 'Accounts'
textMultiLang.fr.ACCOUNTS = 'Comptes'
textMultiLang.he.ACCOUNTS = 'חשבונות'

textMultiLang.en.ACCESS_PROFILES = 'Access profiles'
textMultiLang.fr.ACCESS_PROFILES = 'Profils d\'accès'
textMultiLang.he.ACCESS_PROFILES = 'פרופילים'

textMultiLang.en.ADMIN = 'Admin'
textMultiLang.fr.ADMIN = 'Admin'
textMultiLang.he.ADMIN = 'ניהול'

textMultiLang.en.ADD_PAINTING = 'Add painting'
textMultiLang.fr.ADD_PAINTING = 'Ajouter un tableau'
textMultiLang.he.ADD_PAINTING = 'הוסף ציור'

textMultiLang.en.ADD_NEW_PAINTING = 'Add new painting'
textMultiLang.fr.ADD_NEW_PAINTING = 'Ajouter un nouveau tableau'
textMultiLang.he.ADD_NEW_PAINTING = 'הוסף ציור חדש'

textMultiLang.en.ADD_ARTIST = 'Add artist'
textMultiLang.fr.ADD_ARTIST = 'Ajouter un artiste'
textMultiLang.he.ADD_ARTIST = 'הוסף אמן'

textMultiLang.en.ADD_NEW_ARTIST = 'Add new artist'
textMultiLang.fr.ADD_NEW_ARTIST = 'Ajouter un nouvel artiste'
textMultiLang.he.ADD_NEW_ARTIST = 'הוסף אמן חדש'

textMultiLang.en.ADD_GENRE = 'Add genre'
textMultiLang.fr.ADD_GENRE = 'Ajouter un genre'
textMultiLang.he.ADD_GENRE = 'הוסף ז\'אנר'

textMultiLang.en.ADD_NEW_GENRE = 'Add new genre'
textMultiLang.fr.ADD_NEW_GENRE = 'Ajouter un nouvel genre'
textMultiLang.he.ADD_NEW_GENRE = 'הוסף ז\'אנר חדש'

textMultiLang.en.ADD_CURRENT = 'Add current'
textMultiLang.fr.ADD_CURRENT = 'Ajouter un courant'
textMultiLang.he.ADD_CURRENT = 'הוסף תקופה'

textMultiLang.en.ADD_NEW_CURRENT = 'Add new current'
textMultiLang.fr.ADD_NEW_CURRENT = 'Ajouter un nouvel courant'
textMultiLang.he.ADD_NEW_CURRENT = 'הוסף תקופה חדשה'

textMultiLang.en.INSERT_ONE_ONLY = 'Please insert only 1 file'
textMultiLang.fr.INSERT_ONE_ONLY = 'Veuillez n\'insérer seulement 1 fichier'
textMultiLang.he.INSERT_ONE_ONLY = 'נא להכניס רק קובץ אחד'

textMultiLang.en.FILE_TOO_BIG = 'The file is too big'
textMultiLang.fr.FILE_TOO_BIG = 'Le fichier est trop lourd'
textMultiLang.he.FILE_TOO_BIG = 'הקובץ גדול מדאי'

textMultiLang.en.FORMAT_NOT_ACCEPTED = 'The format is not accepted'
textMultiLang.fr.FORMAT_NOT_ACCEPTED = 'Le format n\'est pas accepté'
textMultiLang.he.FORMAT_NOT_ACCEPTED = 'הקובץ לא תקין'

textMultiLang.en.IMAGE = 'Image'
textMultiLang.fr.IMAGE = 'Image'
textMultiLang.he.IMAGE = 'תמונה'

textMultiLang.en.ARTMYN_ID = 'Artmyn ID'
textMultiLang.fr.ARTMYN_ID = 'ID Artmyn'
textMultiLang.he.ARTMYN_ID = 'מזהה Artmyn'

textMultiLang.en.BIRTH_YEAR = 'Birth year'
textMultiLang.fr.BIRTH_YEAR = 'Année de naissance'
textMultiLang.he.BIRTH_YEAR = 'שנת לידה'

textMultiLang.en.DEATH_YEAR = 'Death year'
textMultiLang.fr.DEATH_YEAR = 'Année de décès'
textMultiLang.he.DEATH_YEAR = 'שנת מוות'

textMultiLang.en.CITY = 'City'
textMultiLang.fr.CITY = 'Ville'
textMultiLang.he.CITY = 'עיר'

textMultiLang.en.COUNTRY = 'Country'
textMultiLang.fr.COUNTRY = 'Pays'
textMultiLang.he.COUNTRY = 'ארץ'

textMultiLang.en.STATUS = 'Status'
textMultiLang.fr.STATUS = 'Etat'
textMultiLang.he.STATUS = 'מצב'

textMultiLang.en.ONLINE = 'Online'
textMultiLang.fr.ONLINE = 'En ligne'
textMultiLang.he.ONLINE = 'מקוון'

textMultiLang.en.OFFLINE = 'Offline'
textMultiLang.fr.OFFLINE = 'Hors ligne'
textMultiLang.he.OFFLINE = 'לא מקוון'

textMultiLang.en.ACTION = 'Action'
textMultiLang.fr.ACTION = 'Action'
textMultiLang.he.ACTION = 'פעולה'

textMultiLang.en.ACTIONS = 'Actions'
textMultiLang.fr.ACTIONS = 'Actions'
textMultiLang.he.ACTIONS = 'פעולות'

textMultiLang.en.VIEW_PAINTING = 'View the painting'
textMultiLang.fr.VIEW_PAINTING = 'Voir l\'œuvre'
textMultiLang.he.VIEW_PAINTING = 'לראות את הציור'

textMultiLang.en.PUT = 'Put'
textMultiLang.fr.PUT = 'Mettre'
textMultiLang.he.PUT = 'לשים במצב'

textMultiLang.en.PUT_ONLINE = 'Put online'
textMultiLang.fr.PUT_ONLINE = 'Mettre en ligne'
textMultiLang.he.PUT_ONLINE = 'לשים במצב מקוון'

textMultiLang.en.PUT_OFFLINE = 'Put offline'
textMultiLang.fr.PUT_OFFLINE = 'Mettre hors ligne'
textMultiLang.he.PUT_OFFLINE = 'לשים במצב לא מקוון'

textMultiLang.en.PUT_ONLINE_OFFLINE = 'Put online/offline'
textMultiLang.fr.PUT_ONLINE_OFFLINE = 'Mettre en ligne/hors ligne'
textMultiLang.he.PUT_ONLINE_OFFLINE = 'לשים במצב מקוון/לא מקוון'

textMultiLang.en.EDIT = 'Edit'
textMultiLang.fr.EDIT = 'Editer'
textMultiLang.he.EDIT = 'עריכה'

textMultiLang.en.LIST_ARTISTS = 'Artists list'
textMultiLang.fr.LIST_ARTISTS = 'Liste auteurs'
textMultiLang.he.LIST_ARTISTS = 'רשימת אמנים'

textMultiLang.en.LIST_GENRES = 'Genres list'
textMultiLang.fr.LIST_GENRES = 'Liste genres'
textMultiLang.he.LIST_GENRES = 'רשימת ז\'נרים'

textMultiLang.en.LIST_CURRENTS = 'Currents list'
textMultiLang.fr.LIST_CURRENTS = 'Liste courants'
textMultiLang.he.LIST_CURRENTS = 'רשימת תקופות'

textMultiLang.en.LIST_PAINTINGS = 'Paintings list'
textMultiLang.fr.LIST_PAINTINGS = 'Liste tableaux'
textMultiLang.he.LIST_PAINTINGS = 'רשימת ציורים'

textMultiLang.en.LIST_EMPTY = 'The list is empty'
textMultiLang.fr.LIST_EMPTY = 'La liste est vide'
textMultiLang.he.LIST_EMPTY = 'הרשימה ריקה'

textMultiLang.en.OFFLINE = 'Offline'
textMultiLang.fr.OFFLINE = 'Hors ligne'
textMultiLang.he.OFFLINE = 'לא מקוון'

textMultiLang.en.ONLINE = 'Online'
textMultiLang.fr.ONLINE = 'En ligne'
textMultiLang.he.ONLINE = 'מקוון'

textMultiLang.en.TITLE_DESCRIPTION_CITATION = 'Title, description & quote'
textMultiLang.fr.TITLE_DESCRIPTION_CITATION = 'Titre, description & citation'
textMultiLang.he.TITLE_DESCRIPTION_CITATION = 'כותרת, תיאור וציטוט'

textMultiLang.en.ARTIST_GENRE_CURRENT = 'Artist, genre & current'
textMultiLang.fr.ARTIST_GENRE_CURRENT = 'Artiste, genre & courant'
textMultiLang.he.ARTIST_GENRE_CURRENT = 'אמן ז\'אנר ותקופה'

textMultiLang.en.AUDIO_DESCRIPTION = 'Audio description'
textMultiLang.fr.AUDIO_DESCRIPTION = 'Description audio'
textMultiLang.he.AUDIO_DESCRIPTION = 'תיאור אודיו'

textMultiLang.en.ADDITIONAL_INFORMATIONS = 'Additional information'
textMultiLang.fr.ADDITIONAL_INFORMATIONS = 'Informations supplémentaires'
textMultiLang.he.ADDITIONAL_INFORMATIONS = 'מידע נוסף'


// RETURN FROM BACKEND
textMultiLang.en.MISSING_DATA = 'Missing data'
textMultiLang.fr.MISSING_DATA = 'Données manquantes'
textMultiLang.he.MISSING_DATA = 'מידע חסר'

textMultiLang.en.ARTMYN_ID_EXIST = 'This Artmyn ID already exists in the collection'
textMultiLang.fr.ARTMYN_ID_EXIST = 'Le ID Artmyn existe déjà dans la collection'
textMultiLang.he.ARTMYN_ID_EXIST = 'ה-ID Artmyn כבר קיים באוסף'

textMultiLang.en.PAINTING_NOT_FOUND_ARTMYN = 'The painting was not found at Artmyn'
textMultiLang.fr.PAINTING_NOT_FOUND_ARTMYN = 'Le tableau n\'a pas été trouvé chez Artmyn'
textMultiLang.he.PAINTING_NOT_FOUND_ARTMYN = 'הציור לא נמצא ב-Artmyn'

textMultiLang.en.PAINTING_NOT_FOUND_COLLECTION = 'The painting was not found in the collection'
textMultiLang.fr.PAINTING_NOT_FOUND_COLLECTION = 'Le tableau n\'a pas été trouvé dans la collection'
textMultiLang.he.PAINTING_NOT_FOUND_COLLECTION = 'הציור לא נמצא באוסף'

textMultiLang.en.ARTIST_ATTACHED_PAINTING_DONT_REMOVE = 'This artist is attached to at least one painting. It is not possible to delete it'
textMultiLang.fr.ARTIST_ATTACHED_PAINTING_DONT_REMOVE = 'L\'artiste est relié à un tableau. Il n\'est pas possible de le supprimer'
textMultiLang.he.ARTIST_ATTACHED_PAINTING_DONT_REMOVE = 'האמן מצורף לפחות לציור אחד. לא ניתן למחוק אותו'

textMultiLang.en.GENRE_ATTACHED_PAINTING_DONT_REMOVE = 'This genre is attached to at least one painting. It is not possible to delete it'
textMultiLang.fr.GENRE_ATTACHED_PAINTING_DONT_REMOVE = 'Le genre est relié à un tableau au moins. Il n\'est pas possible de le supprimer'
textMultiLang.he.GENRE_ATTACHED_PAINTING_DONT_REMOVE = 'הז\'אנר מצורף לפחות לציור אחד. לא ניתן למחוק אותו'

textMultiLang.en.CURRENT_ATTACHED_PAINTING_DONT_REMOVE = 'This current is attached to at least one painting. It is not possible to delete it'
textMultiLang.fr.CURRENT_ATTACHED_PAINTING_DONT_REMOVE = 'Le courant est relié à un tableau au moins. Il n\'est pas possible de le supprimer'
textMultiLang.he.CURRENT_ATTACHED_PAINTING_DONT_REMOVE = 'התקופה מצורפת לפחות לציור אחד. לא ניתן למחוק אותה'

textMultiLang.en.IMAGE_FILE_NOT_FOUND = 'The image file was not found'
textMultiLang.fr.IMAGE_FILE_NOT_FOUND = 'Le fichier image n\'a pas été trouvé'
textMultiLang.he.IMAGE_FILE_NOT_FOUND = 'קובץ התמונה לא נמצא'

textMultiLang.en.IMAGE_FILE_NOT_IMPORTED = 'The image file could not be imported'
textMultiLang.fr.IMAGE_FILE_NOT_IMPORTED = 'Le fichier image n\'a pas pu être importé'
textMultiLang.he.IMAGE_FILE_NOT_IMPORTED = 'לא ניתן לייבא את קובץ התמונה'

textMultiLang.en.IMAGE_FILE_NOT_SAVED = 'The image file could not be saved'
textMultiLang.fr.IMAGE_FILE_NOT_SAVED = 'Le fichier image n\'a pas pu être enregistré'
textMultiLang.he.IMAGE_FILE_NOT_SAVED = 'לא ניתן לשמור את קובץ התמונה'

textMultiLang.en.SAVED_SUCCESSFULLY = 'Data saved successfully'
textMultiLang.fr.SAVED_SUCCESSFULLY = 'Les données ont été enregistrées avec succès'
textMultiLang.he.SAVED_SUCCESSFULLY = 'הנתונים נשמרו בהצלחה'

textMultiLang.en.BUT_OFFLINE_EMPTY_FIELDS = 'but was taken offline because not all fields are filled in'
textMultiLang.fr.BUT_OFFLINE_EMPTY_FIELDS = 'mais a été mis hors ligne car tous les champs ne sont pas renseignés'
textMultiLang.he.BUT_OFFLINE_EMPTY_FIELDS = 'אבל הועבר למצב לא מקוון מכיוון שלא כל השדות מלאים'

textMultiLang.en.INTERNAL_ERROR = 'An internal error occurred'
textMultiLang.fr.INTERNAL_ERROR = 'Une erreur interne s\'est produite'
textMultiLang.he.INTERNAL_ERROR = 'אירעה שגיאה פנימית'

textMultiLang.en.CONTAINS_EMPTY_FIELDS = 'The painting contains empty fields. All fields must be filled in to put it online'
textMultiLang.fr.CONTAINS_EMPTY_FIELDS = 'L\'œuvre contient des champs vides. Tous les champs doivent être renseignés pour la mettre en ligne'
textMultiLang.he.CONTAINS_EMPTY_FIELDS = 'הציור מכיל שדות ריקים. יש למלא את כל השדות כדי לשים במצב מקוון'



export default function getlabel(lang, listConst) {
    switch (lang) {
        case 'en':
        case 'fr':
        case 'he':
            break
        default:
            lang = 'fr'
            break
    }
    const labels = {}
    const labelInnexistant = {}
    listConst.forEach(label => {
        if(textMultiLang[lang][label] || textMultiLang[lang][label] === '') {
            labels[label] = textMultiLang[lang][label]
        } else {
            labels[label] = ''
            labelInnexistant[lang] = label
            console.log('label Innexistant', labelInnexistant)
            // A envoyer au serveur dans les logs
        }
        return
    })
    return labels
}