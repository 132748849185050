import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet-async"

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import NavBar from './headers/NavBar'
import Banner from './headers/Banner'
import Footer from './footer/Footer'

import getLabel from "../constants/textMultiLang";




export default function Cookie() {
    const ref = useRef()
    const { lang } = useSelector(state => state.app);

    const {
        BOUBLIL_COLLECTION,
        COOKIE_PAGE,
        DESCRIPTION_COOKIE_PAGE,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'COOKIE_PAGE',
        'DESCRIPTION_COOKIE_PAGE',
    ])

    useEffect(() => {
        document.title = `${COOKIE_PAGE} | ${BOUBLIL_COLLECTION}`
    }, [BOUBLIL_COLLECTION, COOKIE_PAGE])

    // A activer si cookiebot est actif
    // useEffect(() => {
    //     let refCurrent = null
    //     if (ref.current) {
    //         refCurrent = ref.current
    //     }
    //     const script = document.createElement('script')
    //     script.id = 'CookieDeclaration'
    //     script.src = 'https://consent.cookiebot.com/db60a280-8c5a-44b0-bd08-2f576c14ae7a/cd.js'
    //     script.async = true
    //     refCurrent.appendChild(script)
      
    //     return () => {
    //         refCurrent.removeChild(script)
    //     }
    // }, [])

    return (
        <>
            <Helmet>
                <meta name="description" content={DESCRIPTION_COOKIE_PAGE} />
                <meta name="twitter:title" content={`${COOKIE_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta name="twitter:description" content={DESCRIPTION_COOKIE_PAGE} />
                <meta property="og:title" content={`${COOKIE_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta property="og:description" content={DESCRIPTION_COOKIE_PAGE} />
            </Helmet>
            <NavBar />
            <Banner page={COOKIE_PAGE} component='h1' />
            <Box>
                <Container>
                    <Box id='declaration-container' ref={ref}></Box>
                </Container>
            </Box>
            <Footer />
        </>
    )
}