import React, { useState, useRef, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Link as LinkRouter } from 'react-router-dom'


import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import IconButton from '@mui/material/IconButton'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Grow from '@mui/material/Grow'
import SvgIcon from '@mui/material/SvgIcon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ClickAwayListener from '@mui/material/ClickAwayListener'


import { LANG_ARRAY, DEFAULT_LANG_CODE } from '../../config'
import getLabel from '../../constants/textMultiLang'
import { logout } from '../../redux/actions/user'




export default function AccountMenu(props) {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
    const { lang } = useSelector(state => state.app)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang
    const { user } = props

    const {
        OPEN_ACCOUNT_OPTIONS,
        PROFILE_MENU,
        ACCOUNT,
        LOGOUT
    } = getLabel(lang, [
        'OPEN_ACCOUNT_OPTIONS',
        'PROFILE_MENU',
        'ACCOUNT',
        'LOGOUT'
    ])


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return
        setOpen(false)
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    const toLogout = (e) => {
        e.preventDefault()
        dispatch(logout())
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false)
            anchorRef.current.focus()

        prevOpen.current = open
    }, [open])


    return (
        <Box sx={{position: 'relative'}}>
            <IconButton
                color='inherit'
                ref={anchorRef}
                id={'account-button'}
                aria-controls='menu-account'
                aria-expanded={open}
                aria-haspopup='true'
                aria-label={OPEN_ACCOUNT_OPTIONS}
                onClick={handleToggle}
                sx={{ width: '1.5em', height: '1.5em', border: 'rgba(255, 255, 255, .5) 1px solid' }}
            >
                <Typography>{user.firstName.charAt(0) + user.name.charAt(0)}</Typography>
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="top-end"
                transition
                disablePortal
                keepMounted
                
            >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                >
                    <Paper 
                        sx={{ maxHeight: 'calc(100vh - 60px)', overflow: 'auto',
                            // mt: 1.5,
                            // '&:before': {
                            //     transition: 'none',
                            //     mt: 1.5,
                            //     content: '""',
                            //     display: 'block',
                            //     position: 'absolute',
                            //     top: 0,
                            //     right: 14,
                            //     width: 10,
                            //     height: 10,
                            //     bgcolor: 'background.paper',
                            //     transform: 'translateY(-50%) rotate(45deg)',
                            //     zIndex: 0,
                            // },
                        }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id='menu-account'
                                aria-label={PROFILE_MENU}
                                onKeyDown={handleListKeyDown}
                                onClick={handleClose}
                            >
                                <MenuItem
                                >
                                    <Link component={LinkRouter} underline='none' to={`${urlLang}/profile`}>{ACCOUNT}</Link>
                                </MenuItem>
                                <MenuItem
                                >
                                    <Link underline='none' href="#" onClick={toLogout}>{LOGOUT}</Link>
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
            </Popper>
        </Box>
    )

}