import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router'
import { history } from '../../redux/store'
import { Helmet } from 'react-helmet-async'

import { useTheme } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import { LANG_ARRAY_CODE_WITHOUT_DEFAULT_LANG, DEFAULT_LANG_CODE } from '../../config'
import getLabel from '../../constants/textMultiLang'


import Dash from './Dash'
import Artists from './Artists'
import Genres from './Genres'
import Currents from './Currents'
import Paintings from './paintings/Paintings'
import Profile from './Profile'
import NavBarCms from '../headers/NavBarCms'
import Drawer from './Drawer'

let langRoutes = '(/' + LANG_ARRAY_CODE_WITHOUT_DEFAULT_LANG.join('|/') + '|)'

export default function Cms(props) {
    const theme = useTheme()
    
    const { user } = useSelector(state => state.auth)
    const { lang } = useSelector(state => state.app)
    const [mobileOpen, setMobileOpen] = useState(false)

    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang

    const { location } = props

    const {
        BOUBLIL_COLLECTION,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
    ])

    useEffect(() => {
        document.title = `CMS | ${BOUBLIL_COLLECTION}`
    }, [BOUBLIL_COLLECTION])


    if (!user || (user && user.profil && user.profil !== 1)) {
        const redirectEncode = btoa(history.location.pathname + history.location.search).replace(/=/gi, '')
        return <Redirect to={`${urlLang}/login?redirect=${redirectEncode}`} />
    }
    
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <>
            <Helmet>
                <meta name="description" content={`CMS ${BOUBLIL_COLLECTION}`} />
                <meta name="twitter:title" content={`CMS | ${BOUBLIL_COLLECTION}`} />
                <meta name="twitter:description" content={`CMS ${BOUBLIL_COLLECTION}`} />
                <meta property="og:title" content={`CMS | ${BOUBLIL_COLLECTION}`} />
                <meta property="og:description" content={`CMS ${BOUBLIL_COLLECTION}`} />
            </Helmet>
            <Grid container sx={{ height: '100vh', overflow: 'hidden' }}>
                <Grid component='aside'>
                    <Drawer lang={lang} urlLang={urlLang} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} routesJson={routesJson} />
                </Grid>
                <Grid container wrap='nowrap' direction='column' flexGrow={1} sx={{ height: '100%' }}>
                    <Grid>
                        <NavBarCms user={user} handleDrawerToggle={handleDrawerToggle} />
                    </Grid>
                    <Grid flexGrow={1} sx={{ overflow: 'auto' }}>
                        <Container maxWidth={false}>
                            <Switch>
                                <Route exact path={`${langRoutes}/`} render={() => <Dash routesJson={routesJson} />} />
                                <Route exact path={`${langRoutes}/site-management/artists`} render={() => <Artists routesJson={routesJson} location={location} />} />
                                <Route exact path={`${langRoutes}/site-management/genres`} render={() => <Genres routesJson={routesJson} location={location} />} />
                                <Route exact path={`${langRoutes}/site-management/currents`} render={() => <Currents routesJson={routesJson} location={location} />} />
                                <Route exact path={`${langRoutes}/site-management/paintings`} render={() => <Paintings routesJson={routesJson} location={location} />} />
                                <Route exact path={`${langRoutes}/site-management/painting/:id`} render={() => <Dash routesJson={routesJson} />} />
                                <Route exact path={`${langRoutes}/site-management/comments`} render={() => <Dash routesJson={routesJson} />} />
                                <Route exact path={`${langRoutes}/admin/accounts`} render={() => <Dash routesJson={routesJson} />} />
                                <Route exact path={`${langRoutes}/admin/access`} render={() => <Dash routesJson={routesJson} />} />
                                <Route exact path={`${langRoutes}/profile`} render={() => <Profile routesJson={routesJson} />} />
                                <Redirect to={urlLang} />
                            </Switch>
                        </Container>
                    </Grid>
                    <Grid component='footer' sx={{ textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
                        <Container>
                            <Typography sx={{ color: 'transparent' }}>
                                &nbsp;
                                {/* &copy; Developed by  */}
                                {/* <Link color='inherit' href='mailto:webmaster@nat-digital.com'>Nat Digital</Link> */}
                            </Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export const routesJson = 
[
    {
        "type": "treeItem",
        "label": "DASHBOARD",
        "path": "/",
        "icon": "DashboardIcon",
        "accessProfil": [1],
    },
    {
        "type": "tree",
        "label": "SITE_MANAGEMENT",
        "path": "/site-management",
        "accessProfil": [1],
        "defaultExpanded": true,
        "items": [
            {
                "type": "treeItem",
                "label": "ARTISTS",
                "path": "/site-management/artists",
                "icon": "PersonPinCircleOutlinedIcon",
                "accessProfil": [1],
            },
            {
                "type": "treeItem",
                "label": "GENRES",
                "path": "/site-management/genres",
                "icon": "SellOutlinedIcon",
                    "accessProfil": [1],
            },
            {
                "type": "treeItem",
                "label": "CURRENTS",
                "path": "/site-management/currents",
                "icon": "CalendarMonthOutlinedIcon",
                "accessProfil": [1],
            },
            {
                "type": "treeItem",
                "label": "PAINTINGS",
                "path": "/site-management/paintings",
                "icon": "BrushOutlinedIcon",
                "accessProfil": [1],
            },
            {
                "type": "treeItem",
                "label": "COMMENTS",
                "path": "/site-management/comments",
                "icon": "SmsOutlinedIcon",
                "accessProfil": [1],
            }
        ]
    },
    {
        "type": "tree",
        "label": "ADMIN",
        "path": "/admin",
        "accessProfil": [1],
        "defaultExpanded": true,
        "items": [
            {
                "type": "treeItem",
                "label": "ACCOUNTS",
                "path": "/admin/accounts",
                "icon": "ManageAccountsIcon",
                "accessProfil": [1],
            },
            {
                "type": "treeItem",
                "label": "ACCESS_PROFILES",
                "path": "/admin/access",
                "icon": "AdminPanelSettingsIcon",
                "accessProfil": [1],
            }
        ]
    }
]