import {
  APP_LOAD,
  SET_LANG,
  SET_THEME_MODE,
  SET_OPEN_DRAWER
} from "../../constants/actionTypes"


export const setAppLoad = () => dispatch => {
  dispatch({
    type: APP_LOAD
  })
}

export const setLang = lang => dispatch => {
  dispatch({
    type: SET_LANG,
    payload: lang
  })
}

export const setTheme = themeMode => dispatch => {
  dispatch({
    type: SET_THEME_MODE,
    payload: themeMode
  })
}

export const setOpenDrawer = status => dispatch => {
  dispatch({
    type: SET_OPEN_DRAWER,
    payload: status
  })
}