import React from 'react'
import PropTypes from 'prop-types'
// import Button from '@mui/material/Button'
import MuiSnackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
// import IconButton from '@mui/material/IconButton'
// import CloseIcon from '@mui/icons-material/Close';


export default function Snackbar(props) {
    const { open, setOpen, message, handleExited } = props
    const messageText = (message && message.message) || ''
    const duration = (message && message.duration) || 6000
    const severity = (message && message.severity) || null

    const transitionProps = { }
    if(handleExited) {
        transitionProps.onExited = handleExited
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    }

    // const action = (
    //     <>
    //         <Button color='secondary' size='small' onClick={handleClose}>
    //             UNDO
    //         </Button>
    //         <IconButton
    //             size='small'
    //             aria-label='close'
    //             color='inherit'
    //             onClick={handleClose}
    //         >
    //             <Alert fontSize='small' />
    //         </IconButton>
    //     </>
    // )

    return (
        <MuiSnackbar
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
            TransitionProps={transitionProps}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            // message='Note archived'
            // action={action}
        >
            <Alert onClose={handleClose} elevation={6} variant='filled' severity={severity} sx={{ width: '100%' }}>
                {messageText}
            </Alert>
        </MuiSnackbar>
    )
}

Snackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    message: PropTypes.object,
}