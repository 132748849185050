import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import NavBar from './headers/NavBar'
import Banner from './headers/Banner'
import Footer from './footer/Footer'

import getLabel from '../constants/textMultiLang'




export default function Accessibility() {
    const { lang } = useSelector(state => state.app)

    const {
        BOUBLIL_COLLECTION,
        ACCESSIBILITY_PAGE,
        DESCRIPTION_ACCESSIBILITY_PAGE,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'ACCESSIBILITY_PAGE',
        'DESCRIPTION_ACCESSIBILITY_PAGE',
    ])

    useEffect(() => {
        document.title = `${ACCESSIBILITY_PAGE} | ${BOUBLIL_COLLECTION}`
    }, [BOUBLIL_COLLECTION, ACCESSIBILITY_PAGE])


    return (
        <>
            <Helmet>
                <meta name='description' content={DESCRIPTION_ACCESSIBILITY_PAGE} />
                <meta name='twitter:title' content={`${ACCESSIBILITY_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta name='twitter:description' content={DESCRIPTION_ACCESSIBILITY_PAGE} />
                <meta property='og:title' content={`${ACCESSIBILITY_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta property='og:description' content={DESCRIPTION_ACCESSIBILITY_PAGE} />
            </Helmet>
            <NavBar />
            <Banner page={ACCESSIBILITY_PAGE} component='h1' />
            <Box>
                <Container>
                    <Box></Box>
                </Container>
            </Box>
            <Footer />
        </>
    )
}