// import article from './reducers/article';
// import articleList from './reducers/articleList';
// import auth from './auth';
import app from './app';
import auth from './user';
import { combineReducers } from 'redux';
// import common from './reducers/common';
// import editor from './reducers/editor';
// import home from './reducers/home';
// import profile from './reducers/profile';
// import settings from './reducers/settings';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router'

const createRootReducer = (history) => combineReducers({ 
  // auth,
  app,
  auth,
  router : connectRouter(history), 
  // router: routerReducer
}) 

export default createRootReducer
