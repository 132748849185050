import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { history } from '../../../redux/store'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import EditIcon from '@mui/icons-material/Edit'
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined'
import MenuIcon from '@mui/icons-material/Menu'

import Input, { buildSelectOptions } from '../../form/Input'
import Form from '../../form/form'
import AddPaintingForm from '../../form/AddPaintingForm'
import EditPaintingForm from '../../form/EditPaintingForm'
import Table from '../../global/Table'
import MenuSelect from '../../global/MenuSelect'
import Modal from '../../global/Modal'
import Snackbar from '../../global/Snackbar'
import { RotatingLines } from 'react-loader-spinner'

import { alpha } from '@mui/material/styles'

import axios from '../../../utils/axios'
import { HOST } from '../../../config'
import getLabel from '../../../constants/textMultiLang'
import { confirm, toast } from '../../global/dialog'



function ModalActionPainting(props) {
    const { lang } = useSelector(state => state.app)
    
    const { id, location, action, data, updatePaintingsData } = props

    useEffect(() => {
        if(!data)
            history.push(location.pathname)
    }, [data])
    

    let maxWidth = 'lg'
    let content = ''

    if(action === 'view') {
        maxWidth = 'md'
        content =
            <Box
                component='iframe'
                src={`https://artmyn.com/embed/${data.artmynId}?face=clean&auto_start=0&auto_play=0&t_id=*&cartel=0&partner=1&share=0&close=1&tutorial=1`}
                title="portrait de jeune homme"
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                sx={{ width: '100%', height: 'calc(100vh - 200px)', border: 'none' }}
            />
    } else if(action === 'edit') {
        // maxWidth = false
        maxWidth = 'md'
        content = <EditPaintingForm updatePaintingsData={updatePaintingsData} paintingData={data} />
    }


    return (
        <>
        {data && data.name &&
            <Modal 
            dialogTitle={
                <>{data.name[lang]}</>
                // <Box sx={{ textAlign: 'end', padding: '10px' }}>
                //     <IconButton
                //         color='inherit'
                //         aria-label={CLOSE_MENU}
                //         aria-controls=''
                //         aria-expanded={true}
                //         onClick={() => history.push(location.pathname)}
                //     >
                //         <CloseIcon fontSize='large' />
                //     </IconButton>
                // </Box>
            }
            dialogContent={content}
            height='100%'
            maxWidth={maxWidth}
            fullWidth={true}
            buttons={[]}
            blockBackdropClick={true}
            isCustomOpen={true}
            customOpen={true}
            customClose={() => history.push(location.pathname)}
            closeButton={true}
        />}
        </>
    )
}

ModalActionPainting.propTypes = {
    id: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    action: PropTypes.string.isRequired,
    data: PropTypes.object,
}


export default function Paintings(props) {

    const [isLoaded, setIsLoaded] = useState(false)
    
    const [statusLabels, setSatusLabels] = useState({})
    const [paintingsData, setPaintingsData] = useState([])
    const [artistsOptions, setArtistsOptions] = useState([])
    const [genresOptions, setGenresOptions] = useState([])
    const [currentsOptions, setCurrentsOptions] = useState([])
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarPack, setSnackbarPack] = useState([])
    const [snackbarMessage, setSnackbarMessage] = useState(undefined)
    const { lang } = useSelector(state => state.app)

    const { location } = props
    const querySearch = new URLSearchParams(location.search)
    const queryEdit = querySearch.get('edit') || false
    const queryView = querySearch.get('view') || false

    const {
        PAINTINGS,
        IMAGE,
        // UNE_ERREUR_S_EST_PRODUITE,
        NAME,
        ARTMYN_ID,
        ARTIST,
        CURRENT,
        GENRE,
        STATUS,
        ACTION,
        ACTIONS,
        VIEW_PAINTING,
        PUT,
        EDIT,
        FILE,
        // CHOOSE_FILE,
        DELETE_FILE,
        ADD,
        // FILE_TOO_BIG,
        // FORMAT_NOT_ACCEPTED,
        REGISTERED_SUCCESSFULLY,
        // CANCEL,
        MODIFY,
        SAVE,
        DELETE,
        WANT_DELETE_FILE,
        WANT_ABLE_TO_REVERT,
        LIST_PAINTINGS,
        LIST_EMPTY,
        OFFLINE,
        ONLINE,
        INTERNAL_ERROR,
    } = getLabel(lang, [
        'PAINTINGS',
        'IMAGE',
        // 'UNE_ERREUR_S_EST_PRODUITE',
        'NAME',
        'ARTMYN_ID',
        'ARTIST',
        'CURRENT',
        'GENRE',
        'STATUS',
        'ACTION',
        'ACTIONS',
        'VIEW_PAINTING',
        'PUT',
        'EDIT',
        'FILE',
        // 'CHOOSE_FILE',:
        'DELETE_FILE',
        'ADD',
        // 'FILE_TOO_BIG',
        // 'FORMAT_NOT_ACCEPTED',
        'REGISTERED_SUCCESSFULLY',
        // 'CANCEL',
        'MODIFY',
        'SAVE',
        'DELETE',
        'WANT_DELETE_FILE',
        'WANT_ABLE_TO_REVERT',
        'LIST_PAINTINGS',
        'LIST_EMPTY',
        'OFFLINE',
        'ONLINE',
        'INTERNAL_ERROR',
    ])

    const messageAPI = getLabel(lang, ['SAVED_SUCCESSFULLY', 'MISSING_DATA', 'CONTAINS_EMPTY_FIELDS', 'INTERNAL_ERROR'])

    const headCells = [
        {
            id: 'image',
            numeric: false,
            disablePadding: 'none',
            label: IMAGE,
            disableColumnSort: true,
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: NAME,
            scope: 'row',
            sortDefault: 'asc',
        },
        {
            id: 'artmynID',
            numeric: false,
            label: ARTMYN_ID,
            disablePadding: false,
        },
        {
            id: 'artist',
            numeric: false,
            label: ARTIST,
            disablePadding: false,
        },
        {
            id: 'current',
            numeric: false,
            label: CURRENT,
            disablePadding: false,
        },
        {
            id: 'genre',
            numeric: false,
            label: GENRE,
            disablePadding: false,
        },
        {
            id: 'status',
            numeric: false,
            label: STATUS,
            disablePadding: false,
        },
        {
            id: 'actions',
            numeric: false,
            label: ACTION,
            disablePadding: false,
            disableColumnSort: true,
        },
    ]
    
    const statusObject = { ONLINE, OFFLINE }

    useEffect(() => {
        if (snackbarPack.length && !snackbarMessage) {
          // Set a new snack when we don't have an active one
          setSnackbarMessage({ ...snackbarPack[0] })
          setSnackbarPack(prev => prev.slice(1))
          setOpenSnackbar(true)
        } else if (snackbarPack.length && snackbarMessage && openSnackbar) {
          // Close an active snack when a new one is added
          setOpenSnackbar(false)
        }
    }, [snackbarPack, snackbarMessage, openSnackbar])

    const handleSetSnackbarPack = message => setSnackbarPack(prev => [...prev, { ...message, key: new Date().getTime() }])

    const handleExited = () => setSnackbarMessage(undefined)

    function buildActionsList(id, status) {
        async function setOnlineToogle() {
            const res = await axios.toogleOnline({ id, status: status === 'OFFLINE' ? 1 : 0 })
            if(res.code === 201) {
                setPaintingsData(prevPaintingsData => prevPaintingsData.map(item => item._id === id ? { ...item, status: res.data.newDoc.status} : item))
                handleSetSnackbarPack({ message: res.message ? messageAPI[res.message] : null, severity: 'success' })
            } else {
                handleSetSnackbarPack({ message: res.message ? messageAPI[res.message] : INTERNAL_ERROR, severity: 'error', duration: 10000 })
            }
        }

        return [
            {
                html:
                <Box
                    sx={{
                        px: t => t.spacing(2),
                        py: t => t.spacing(.75),
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiSvgIcon-root': {
                            fontSize: 18,
                            marginRight: t => t.spacing(1.5),
                        }
                    }}
                >
                    <VisibilityOutlinedIcon />
                    {VIEW_PAINTING}
                </Box>,
                function: () => history.push(`${location.pathname}?view=${id}`)
            },
            {
                html:
                <Box
                    sx={{
                        px: t => t.spacing(2),
                        py: t => t.spacing(.75),
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiSvgIcon-root': {
                            fontSize: 18,
                            marginRight: t => t.spacing(1.5),
                        }
                    }}
                >
                    <CloudSyncOutlinedIcon />
                    {`${PUT} ${status === 'ONLINE' ? statusObject.OFFLINE.toLowerCase() : statusObject.ONLINE.toLowerCase()}`}
                </Box>,
                function: setOnlineToogle
            },
            {
                html:
                <Box
                    sx={{
                        px: t => t.spacing(2),
                        py: t => t.spacing(.75),
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiSvgIcon-root': {
                            fontSize: 18,
                            marginRight: t => t.spacing(1.5),
                        }
                    }}
                >
                    <EditIcon />
                    {EDIT}
                </Box>,
                function: () => history.push(`${location.pathname}?edit=${id}`)
            }
        ]
    }

    const langStatusLabels = { OFFLINE, ONLINE }
    
    const getStatusLabels = async () => {
        const res = await axios.getStatusLabels()
        if (res.code === 200) {
            const labelsObject = {}
            res.data.forEach(l => labelsObject[l.labelID] = l.labelCode)
            setSatusLabels(labelsObject)
        } else 
            setSatusLabels({})
    }

    const getPaintings = async () => {
        const res = await axios.getPaintings()
        if (res.code === 200)
            setPaintingsData(res.data)
        else 
            setPaintingsData([])
    }

    const getArtists = async () => {
        const res = await axios.getArtists()
        if (res.code === 200)
            return res.data
        else 
            return []
    }

    const getGenres = async () => {
        const res = await axios.getGenres()
        if (res.code === 200)
            return res.data
        else 
            return []
    }

    const getCurrents = async () => {
        const res = await axios.getCurrents()
        if (res.code === 200)
            return res.data
        else 
            return []
    }

    const updatePaintingsData = newDoc => {
        setPaintingsData(prevPaintingsData => (prevPaintingsData.map(item => item._id === newDoc._id ? newDoc : item)))
    }

    
    useEffect(() => {
        async function fetchData() {
            await getStatusLabels()
            await getPaintings()
            // buildSelectOptions(await getArtists(), setArtistsOptions)
            // buildSelectOptions(await getGenres(), setGenresOptions)
            // buildSelectOptions(await getCurrents(), setCurrentsOptions)
            setIsLoaded(true)
        }
        fetchData()
    }, [setArtistsOptions, setGenresOptions, setCurrentsOptions, setIsLoaded])

    const buildDatas = () => {
        return paintingsData.map((painting) => {
            const thumbLink = painting.thumbLink || ''
            const name = painting.name && painting.name[lang] ? painting.name[lang] : ''
            const artistName = painting.artist && painting.artist.name ? painting.artist.name : ''
            const artistFirstName = painting.artist && painting.artist.firstName ? painting.artist.firstName : ''
            const genre = painting.genre && painting.genre.name ? painting.genre.name[lang] : ''
            const current = painting.current && painting.current.name ? painting.current.name[lang] : ''
            const status = langStatusLabels[statusLabels[painting.status]] || painting.status
            const colorStatus = statusLabels[painting.status] === 'ONLINE' ? 'success' : 'error'
            const actionsList = buildActionsList(painting._id, statusLabels[painting.status])

            return {
                image: <Box sx={{ width: '2.7rem', height: '2.7rem' }}><Box component='img'  src={HOST + 'images/' + thumbLink} alt={name} /></Box>,
                name,
                artmynID: painting.artmynId,
                artist: `${artistName}${artistName && artistFirstName && ' '}${artistFirstName}`,
                current,
                genre,
                status: <Box component='span' sx={{ color: t => t.palette[colorStatus].main, backgroundColor: t => alpha(t.palette[colorStatus].main, .1), padding: '0.3rem 0.5rem', borderRadius: '8px', whiteSpace: 'nowrap' }}>{status}</Box>,
                actions: <MenuSelect buttonLabel={<MenuIcon />} actionsList={actionsList} />,
            }
        })
    }


    return (        
        <Box sx={{ padding: '40px 0px' }}>
            <Typography component='h1' variant='h4' align='center'>{PAINTINGS}</Typography>
            <AddPaintingForm getPaintings={getPaintings} />
            <Table datas={buildDatas()} headCells={headCells} />
            {isLoaded && queryEdit && <ModalActionPainting id={queryEdit} location={location} action='edit' updatePaintingsData={updatePaintingsData} data={paintingsData.filter(p => p._id === queryEdit)[0]} />}
            {isLoaded && queryView && <ModalActionPainting id={queryView} location={location} action='view' data={paintingsData.filter(p => p._id === queryView)[0]} />}
            <Snackbar open={openSnackbar} setOpen={setOpenSnackbar} handleExited={handleExited} message={snackbarMessage} />
        </Box>
    )
}