import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'

import { CloseModalIcon } from '../../theme/icons'

import getLabel from '../../constants/textMultiLang'


export default function ModalPreviousImage(props) {
    const { lang } = useSelector(state => state.app)
    const { openModal, dataModal, handleToggleModal } = props
    const descriptionElementRef = useRef(null);

    const {
        PICTURE_PREVIEW,
        CLOSE
    } = getLabel(lang, [
        'PICTURE_PREVIEW',
        'CLOSE'
    ])

    useEffect(() => {
        if (Boolean(openModal)) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openModal])

    return (
        <>
            <Dialog
                open={Boolean(openModal)}
                onClose={handleToggleModal(0)}
                aria-label={PICTURE_PREVIEW}
                aria-labelledby={'modalPreviousImageTitle-' + dataModal.indexID}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.85)' }, '& .MuiPaper-root': { backgroundColor: 'transparent', color: '#fff' } }}
            >
                <DialogTitle
                    component='h4'
                    id={'modalPreviousImageTitle-' + dataModal.indexID}
                    sx={{ padding: '0 0 20px 0' }}
                >
                    {dataModal.name[lang]}<br />
                    <Typography component="span">
                        {(dataModal.artist.firstName || '') + (dataModal.artist.firstName && dataModal.artist.name && ' ') + (dataModal.artist.name || '')}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ overflow: 'hidden', padding: 0 }}>
                    <Box
                        component='img'
                        src={dataModal.img}
                        loading="lazy"
                        alt={dataModal.name[lang]}
                        sx={{ maxHeight: { xs: '30vh', md: '50vh' } }}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <IconButton
                        aria-label={CLOSE}
                        size='large'
                        color='inherit'
                        onClick={handleToggleModal(0)}
                    >
                        <CloseModalIcon fontSize='large' />
                    </IconButton>
                </DialogActions>
            </Dialog>
        </>
    )
}