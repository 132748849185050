import React, { useEffect } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { useTheme } from '@mui/material/styles'

import NavBar from './headers/NavBar'
import Banner from './headers/Banner'
import Footer from './footer/Footer'

import { HOST, DEFAULT_LANG_CODE, LANG_ARRAY_CODE_DIR_RTL, PAGES_ROUTES_ARRAY } from '../config'
import getLabel from '../constants/textMultiLang'

import { ArrowDoubleRightIcon } from '../theme/icons'



export default function About() {
    const theme = useTheme()
    const { lang } = useSelector(state => state.app)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang

    const {
        BOUBLIL_COLLECTION,
        ABOUT_PAGE,
        DESCRIPTION_ABOUT_PAGE,
        JOSE_BOUBLIL,
        CONTACT_ME,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'ABOUT_PAGE',
        'JOSE_BOUBLIL',
        'DESCRIPTION_ABOUT_PAGE',
        'CONTACT_ME',
    ])

    const getLangPath = (pageName) => {
        return PAGES_ROUTES_ARRAY.filter(e => e.constName === pageName)[0].link[lang]
    }

    useEffect(() => {
        document.title = `${ABOUT_PAGE} | ${BOUBLIL_COLLECTION}`
    }, [BOUBLIL_COLLECTION, ABOUT_PAGE])


    return (
        <>
            <Helmet>
                <meta name='description' content={DESCRIPTION_ABOUT_PAGE} />
                <meta name='twitter:title' content={`${ABOUT_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta name='twitter:description' content={DESCRIPTION_ABOUT_PAGE} />
                <meta property='og:title' content={`${ABOUT_PAGE} | ${BOUBLIL_COLLECTION}`} />
                <meta property='og:description' content={DESCRIPTION_ABOUT_PAGE} />
            </Helmet>
            <NavBar />
            <Banner page={ABOUT_PAGE} component='h1' />
            <Box>
                <Container>
                    <Grid container sx={{ padding: '100px 0' }}>
                        <Grid md={6} sx={{ margin: 'auto' }}>
                            <Box sx={{ maxWidth: { xs: '100%', md: '50%' }, margin: 'auto', paddingBottom: '50px' }}>
                                <picture>
                                    <source media={theme.breakpoints.up('sm').replace('@media ', '')} type="image/svg+xml" srcSet={`${HOST}images/site/jose_photo.svg`} />
                                    <source media={theme.breakpoints.up('xs').replace('@media ', '')} type="image/svg+xml" srcSet={`${HOST}images/site/jose_photo.svg`} />
                                    <source media={theme.breakpoints.up('sm').replace('@media ', '')} type="image/png" srcSet={`${HOST}images/site/jose_photo.png`} />
                                    <source media={theme.breakpoints.up('xs').replace('@media ', '')} type="image/png" srcSet={`${HOST}images/site/jose_photo.png`} />
                                    <Box component='img' src={`${HOST}images/site/jose_photo.svg`} alt={JOSE_BOUBLIL} />
                                </picture>
                            </Box>
                        </Grid>
                        <Grid container md={6} direction='column' justifyContent='space-evenly'>
                            <Grid>
                                <Typography gutterBottom>
                                    Lorem ipsum dolor sit amet, consectetur dolore adipisicing eli sed
                                    do eiusmod tempor incididunt uAt labore et dolore si magna aliqua.
                                    Ut enim ad minim veniam, quis ester nostrud e exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo ut consequat in Duis aute irure
                                    dolor in ut mag. Ut enim ad minim veniam, quis ester nostrud epse
                                </Typography>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur ester adipisicing eli sed do
                                    eiusmod tempor incididunt ut labore et dolore si magna aliqua.
                                    Ut enim ad minim veniam, quis ester nostrud e exercitation
                                </Typography>
                            </Grid>
                            <Grid>
                            <Box sx={{ textAlign: 'end', paddingTop: '50px' }}>
                                <Button
                                    component={LinkRouter}
                                    to={urlLang + getLangPath('CONTACT_PAGE')}
                                    variant='contained'
                                    color='primary'
                                    size='large'
                                    endIcon={<ArrowDoubleRightIcon transform={ LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)' } />}
                                    sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', justifyContent: 'space-between', borderRadius: '0 0 3rem 0' }}
                                >
                                    <Typography component='span' sx={{ paddingRight: '40px', textAlign: 'start' }}>{CONTACT_ME}</Typography>
                                </Button>
                            </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </>
    )
}