import React from 'react'
import { useSelector } from 'react-redux'
import { Link as LinkRouter, NavLink } from 'react-router-dom'
// import PropTypes from 'prop-types'



import { useTheme, alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'

import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined'
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined'
import SellOutlinedIcon from '@mui/icons-material/SellOutlined'
import DashboardIcon from '@mui/icons-material/Dashboard'



import { HOST, DEFAULT_LANG_CODE } from '../../config'
import getLabel from '../../constants/textMultiLang'

const drawerWidth = 240

export function DrawerHamburger(props) {
    const { lang } = useSelector(state => state.app)
    const { handleDrawerToggle } = props
    const { OPEN_MENU, } = getLabel(lang, [ 'OPEN_MENU', ])

    return (
        <Box>
            <IconButton
                color="inherit"
                aria-label={OPEN_MENU}
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { lg: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
        </Box>
    )
}

export default function Drawer(props) {
    const theme = useTheme()
    const { user } = useSelector(state => state.auth)
    const { lang } = useSelector(state => state.app)
    const { mobileOpen, handleDrawerToggle, routesJson } = props
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang
    
    const {
        BOUBLIL_COLLECTION,
        NAME_COMPLET_WHITE_LOGO,
        NAME_BC_WHITE_LOGO,
        MAIN_MENU,
        CLOSE_MENU,
        TREE_NAVIGATION,
        DASHBOARD,
        PAINTINGS,
        ARTISTS,
        GENRES,
        CURRENTS,
        COMMENTS,
        ACCOUNTS,
        ACCESS_PROFILES,
        ADMIN,
        SITE_MANAGEMENT,
    } = getLabel(lang, [
        'BOUBLIL_COLLECTION',
        'NAME_COMPLET_WHITE_LOGO',
        'NAME_BC_WHITE_LOGO',
        'MAIN_MENU',
        'CLOSE_MENU',
        'TREE_NAVIGATION',
        'DASHBOARD',
        'PAINTINGS',
        'ARTISTS',
        'GENRES',
        'CURRENTS',
        'COMMENTS',
        'ACCOUNTS',
        'ACCESS_PROFILES',
        'ADMIN',
        'SITE_MANAGEMENT',
    ])

    const routesLabels = { DASHBOARD, PAINTINGS, ARTISTS, GENRES, CURRENTS, COMMENTS, ACCOUNTS, ACCESS_PROFILES, ADMIN, SITE_MANAGEMENT, }
    const routesIcons = { ManageAccountsIcon: <ManageAccountsIcon />, AdminPanelSettingsIcon: <AdminPanelSettingsIcon />, SmsOutlinedIcon: <SmsOutlinedIcon />, CalendarMonthOutlinedIcon: <CalendarMonthOutlinedIcon />, BrushOutlinedIcon: <BrushOutlinedIcon />, PersonPinCircleOutlinedIcon: <PersonPinCircleOutlinedIcon />, SellOutlinedIcon: <SellOutlinedIcon />, DashboardIcon: <DashboardIcon /> }

    const buildTreeItem = (treeItem) => {
        return treeItem.map(route => {
            if(!route.accessProfil.includes(user.profil)) return false

            const props = {
                key: route.label,
                nodeId: route.label,
                className: `MuiTreeItem-${route.type === 'tree' ? 'parent' : 'child'}`,
                label: route.type === 'treeItem'
                    ? <Link component={NavLink} underline='none' color='inherit' to={urlLang + route.path} exact activeClassName='active' sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}><Typography component='span' className='MuiTreeItem-iconContainer'>{routesIcons[route.icon]}</Typography><Typography component='span' className='MuiTreeItem-label'>{routesLabels[route.label]}</Typography></Link>
                    : routesLabels[route.label],
            }

            return (
                <TreeItem { ...props }>
                    {route.type === 'tree' && buildTreeItem(route.items)}
                </TreeItem>
            )
        })
    }

    const content = (
        <div>
            <TreeView
                aria-label={TREE_NAVIGATION}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ExpandMoreIcon />}
                defaultExpanded={routesJson.map(r => r.defaultExpanded ? r.label : null).filter(v => v)}
                sx={{ marginTop: '20px', flexGrow: 1, overflowY: 'auto',
                    '& .MuiTreeItem-root': {
                        '& .MuiTreeItem-content': { boxSizing: 'border-box', 
                            '& .MuiSvgIcon-root': { transition: 'transform .2s linear' },
                            '&.Mui-expanded .MuiSvgIcon-root': { transform: 'rotate(-180deg)' },
                        },
                        '&.MuiTreeItem-parent > .MuiTreeItem-content': {
                            py: 1, backgroundColor: alpha(theme.palette.primary.contrastText, .3),
                            flexDirection: 'row-reverse',
                            '&:hover': { color: alpha(theme.palette.primary.contrastText, .7), '& .MuiSvgIcon-root': { color: alpha(theme.palette.primary.contrastText, .7), }, },
                        },
                        '&.MuiTreeItem-child .MuiTreeItem-content': {
                            p: 0,
                            '& .MuiTreeItem-label': { p: 0 },
                            '& .MuiLink-root': {
                                p: 1,
                                '& .MuiTreeItem-iconContainer': { mr: 1 },
                            },
                            '& > .MuiTreeItem-iconContainer': { display: 'none' },
                            '& >:hover, .active': { backgroundColor: alpha(theme.palette.primary.contrastText, .15), },
                            '&:hover .active': { backgroundColor: alpha(theme.palette.primary.contrastText, 0), },
                        },
                        '& .MuiTreeItem-group': { ml: 0 }
                    },
                }}
            >
                {buildTreeItem(routesJson)}
            </TreeView>
        </div>
    )


    return (
        <Box
            component='nav'
            sx={{ width: { lg: drawerWidth }, flexGrow: 1, flexShrink: { lg: 0 } }}
            aria-label={MAIN_MENU}
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <MuiDrawer
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    disablePortal: true,
                    autoFocus: true
                }}
                sx={{
                    display: { xs: 'block', lg: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, maxWidth: '100%', color: theme.palette.primary.contrastText, backgroundColor: theme.palette.primary.main,
                        '& .MuiSvgIcon-root': { color: theme.palette.primary.contrastText }
                    },
                }}
            >
                <Box sx={{ textAlign: 'end', padding: '10px' }}>
                    <IconButton
                        color='inherit'
                        aria-label={CLOSE_MENU}
                        aria-controls='drawer-mobile'
                        aria-expanded={mobileOpen}
                        onClick={handleDrawerToggle}
                    >
                        <CloseIcon fontSize='large' />
                    </IconButton>
                    <Divider sx={{ borderColor: alpha(theme.palette.primary.contrastText, .3) }} />
                </Box>
                {content}
            </MuiDrawer>
            <MuiDrawer
                variant='permanent'
                sx={{
                    display: { xs: 'none', lg: 'block' }, height: '100%',
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, color: theme.palette.primary.contrastText, backgroundColor: theme.palette.primary.main, overflow: 'hidden',
                        '& .MuiSvgIcon-root': { color: theme.palette.primary.contrastText }
                    },
                }}
                open
            >
                {/* Logo */}
                <Box sx={{ padding: '5px 0 0 5px', backgroundColor: theme.palette.primary.main }}>
                    <Link component={LinkRouter} color='primary' to={`${urlLang}/`} sx={{ display: 'block', width: '10rem' }} title={BOUBLIL_COLLECTION}>
                        <picture>
                            <source media={theme.breakpoints.up('sm').replace('@media ', '')} type='image/svg+xml' srcSet={`${HOST}images/site/${NAME_COMPLET_WHITE_LOGO}`} />
                            <source media={theme.breakpoints.up('xs').replace('@media ', '')} type='image/svg+xml' srcSet={`${HOST}images/site/${NAME_BC_WHITE_LOGO}`} />
                            <img src={`${HOST}images/site/${NAME_COMPLET_WHITE_LOGO}`} alt={BOUBLIL_COLLECTION} />
                        </picture>
                    </Link>
                    <Divider sx={{ borderColor: alpha(theme.palette.primary.contrastText, .3) }} />
                </Box>
                <Box sx={{ overflow: 'auto', '&::-webkit-scrollbar': { width: '0.4em' }, '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(255,255,255,.5)', borderRadius: '20px' } }}>
                    {content}
                </Box>
                <Box>
                    <Typography sx={{ color: 'transparent' }}>
                        &nbsp;
                        {/* &copy; Developed by  */}
                        {/* <Link color='inherit' href='mailto:webmaster@nat-digital.com'>Nat Digital</Link> */}
                    </Typography>
                </Box>
            </MuiDrawer>
        </Box>
    )
}