import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'

import { history } from '../../redux/store'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FilledInput from '@mui/material/FilledInput'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
// import Chip from '@mui/material/Chip'

import { ArrowDoubleRightIcon } from '../../theme/icons'

import { DEFAULT_LANG_CODE, LANG_ARRAY_CODE_DIR_RTL, PAGES_ROUTES_ARRAY } from '../../config'
import getLabel from '../../constants/textMultiLang'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    disableScrollLock: true,
    // keepMounted: true,
    disablePortal: true,
    hideBackdrop: false,
    disableEnforceFocus: true,
    sx: {
        // '& .MuiBackdrop-root': { position: 'static' },
        position: 'absolute',
        top: '100%',
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            position: 'static'
        },
    },
}


export default function SearchForm(props) {
    const [searchValues, setSearchValues] = useState({ text: '', artists: '', genres: '', currents: '', })
    const [values, setValues] = useState({ text: '', artists: [], genres: [], currents: [], })
    const { lang } = useSelector(state => state.app)
    const urlLang = lang === DEFAULT_LANG_CODE ? '' : '/' + lang
    const { searchData, query } = props
    const textQuery = query.get('text') || ''
    const artistQuery = query.get('artists')
    const genreQuery = query.get('genres')
    const currentQuery = query.get('currents')

    const {
        TEXT,
        ARTIST,
        GENRE,
        CURRENT,
        SEARCH,
        ADVANCED_SEARCH,
    } = getLabel(lang, [
        'TEXT',
        'ARTIST',
        'GENRE',
        'CURRENT',
        'SEARCH',
        'ADVANCED_SEARCH',
    ])

    const getLangPath = (pageName) => {
        return PAGES_ROUTES_ARRAY.filter(e => e.constName === pageName)[0].link[lang]
    }

    const handleChange = prop => e => {
        const { target: { value }, } = e
        let valueTemp = value
        if (prop !== 'text')
            valueTemp = typeof value === 'string' ? value.split(',') : value
        setSearchValues({
            ...searchValues,
            [prop]: prop !== 'text' ? searchData[prop].map(data => valueTemp.includes(`${(typeof data.name === 'object' ? data.name[lang] : data.name)}${(data.firstName ? ' ' + data.firstName : '')}`) && data._id).filter(Boolean).join(',') : valueTemp
        })
        setValues({
            ...values,
            [prop]: valueTemp
        })
    }

    const handleSearch = e => {
        e.preventDefault()
        let str = []
        for (const v in searchValues)
            if (searchValues.hasOwnProperty(v) && searchValues[v]) {
                str.push(encodeURIComponent(v) + "=" + encodeURIComponent(searchValues[v]));
            }
        const uri = str.join("&")
        history.push(urlLang + getLangPath('PAINTINGS_PAGE') + (uri && '?' + uri))
    }

    useEffect(() => {
        let querySearch = {}
        let queryValues = {}

        function setData(key, propQuery) {
            queryValues[key] = []
            if(propQuery) {
                searchData[key].forEach(data => {
                    if(propQuery.split(',').includes(data._id)) {
                        queryValues[key].push(`${(typeof data.name === 'object' ? data.name[lang] : data.name)}${(data.firstName ? ' ' + data.firstName : '')}`)
                        querySearch[key] = `${querySearch[key] ? querySearch[key] +  ','  : ''}${data._id}`
                    }
                })
            } else {
                querySearch[key] = ''
            }
        }
        
        if(textQuery) {
            querySearch.text = textQuery
            queryValues.text = textQuery
        } else {
            querySearch.text = ''
            queryValues.text = ''
        }

        setData('artists', artistQuery)
        setData('genres', genreQuery)
        setData('currents', currentQuery)
        
        setValues(prevValue => ({
            ...prevValue,
            ...queryValues,
        }))
        setSearchValues(prevSearchValues => ({
            ...prevSearchValues,
            ...querySearch,
        }))
    }, [searchData, artistQuery, textQuery, currentQuery, genreQuery])


    return (
        <Box component='form' role='search' aria-label={ADVANCED_SEARCH} noValidate onSubmit={handleSearch}>
            <FormControl variant='filled' size='small' fullWidth sx={{ my: 1 }}>
                <InputLabel htmlFor='name' sx={{ padding: '0 2px', fontStyle: 'italic' }}>{TEXT}</InputLabel>
                <FilledInput
                    id='name'
                    type='text'
                    value={values.text}
                    onChange={handleChange('text')}
                />
            </FormControl>
            <FormControl variant="filled" size='small' fullWidth sx={{ my: 1 }}>
                <InputLabel id='select-artists-label' sx={{ padding: '0 2px', fontStyle: 'italic' }}>{ARTIST}</InputLabel>
                <Select
                    labelId='select-artists-label'
                    id='select-artists'
                    multiple
                    value={values.artists}
                    onChange={handleChange('artists')}
                    renderValue={selected => selected.join(', ')}
                    // <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    //     {selected.map((value) => (
                    //         <Chip color="primary" key={value} label={value} />
                    //     ))}
                    // </Box>
                    MenuProps={MenuProps}
                >
                    {searchData.artists && searchData.artists.map((artist) => (
                        <MenuItem key={artist._id} value={`${artist.name} ${artist.firstName}`}>
                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fill: '#000' } }} checked={values.artists.indexOf(`${artist.name} ${artist.firstName}`) > -1} />
                            <ListItemText primary={`${artist.name} ${artist.firstName}`} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            
            <FormControl variant="filled" size='small' fullWidth sx={{ my: 1 }}>
                <InputLabel id='select-genres-label' sx={{ padding: '0 2px', fontStyle: 'italic' }}>{GENRE}</InputLabel>
                <Select
                    labelId='select-genres-label'
                    id='select-genres'
                    multiple
                    value={values.genres}
                    onChange={handleChange('genres')}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {searchData.genres && searchData.genres.map((genre) => (
                        <MenuItem key={genre._id} value={genre.name[lang]}>
                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fill: '#000' } }} checked={values.genres.indexOf(genre.name[lang]) > -1} />
                            <ListItemText primary={genre.name[lang]} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="filled" size='small' fullWidth sx={{ my: 1 }}>
                <InputLabel id='select-currents-label' sx={{ padding: '0 2px', fontStyle: 'italic' }}>{CURRENT}</InputLabel>
                <Select
                    labelId='select-currents-label'
                    id='select-currents'
                    multiple
                    value={values.currents}
                    onChange={handleChange('currents')}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {searchData.currents && searchData.currents.map((current) => (
                        <MenuItem key={current._id} value={current.name[lang]}>
                            <Checkbox sx={{ '& .MuiSvgIcon-root': { fill: '#000' } }} checked={values.currents.indexOf(current.name[lang]) > -1} />
                            <ListItemText primary={current.name[lang]} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                variant='contained'
                color='secondary'
                type='submit'
                size='large'
                endIcon={<ArrowDoubleRightIcon transform={LANG_ARRAY_CODE_DIR_RTL.includes(lang) ? 'scaleX(-1)' : 'scaleX(1)'} />}
                sx={{ margin: '8px 0', padding: '12px 35px 12px 22px', width: '100%', justifyContent: 'space-between', borderRadius: '0 0 3rem 0', '&:hover': { color: 'rgb(250 250 250 / 80%)' } }}
            >
                <Typography component='span'>{SEARCH}</Typography>
            </Button>
        </Box>
    )
}

SearchForm.propTypes = {
    searchData: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
}