import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../../redux/store'


import { setLanguage } from '../../utils/app'
import { setLang } from '../../redux/actions/app'
import { LANG_ARRAY, LANG_ARRAY_CODE_DIR_RTL } from '../../config'

import getLabel from '../../constants/textMultiLang'

import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { visuallyHidden } from '@mui/utils';







const AccordionLang = (props) => {
    const dispatch = useDispatch()
    const theme = useTheme()

    const [expanded, setExpanded] = useState(false)
    let { lang } = useSelector(state => state.app)
  
    const CHOOSE_LANG = {}
    LANG_ARRAY.forEach(e => {
        CHOOSE_LANG[e.code] = getLabel(e.code, [ 'CHOOSE_LANG' ]).CHOOSE_LANG
    })

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab' || event.key === 'Escape')
            setExpanded(false)
    }

    const handleLang = e => {
        e.preventDefault()
        const newLang = e.target.lang
        if(newLang === lang)
            return
        theme.direction = LANG_ARRAY_CODE_DIR_RTL.includes(newLang) ? 'rtl' : 'ltr'
        localStorage.setItem('lang', newLang)
        const userLang = setLanguage(history)
        dispatch(setLang(userLang))
    }
  
    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0} sx={{ color: 'inherit' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='menu-lang-accordion'
                aria-expanded={Boolean(expanded)}
                id='lang-button-accordion'
                sx={{ width: '100%', padding: '8px', justifyContent: 'flex-end' }}
            >
                {LANG_ARRAY.map(item => (
                    <Typography
                        component='span'
                        key={item.code}
                        sx={visuallyHidden}
                        lang={item.code}
                    >
                        {`${CHOOSE_LANG[item.code]}: ${item.lang}`}
                    </Typography>
                ))}
                <Typography component='span' className='notranslate' sx={{ width: '100%', flexShrink: 0, textAlign: 'end', paddingRight: '8px' }} aria-hidden='true'>
                   {LANG_ARRAY.filter (e => e.code === lang)[0].lang}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <MenuList
                aria-labelledby='lang-button-accordion'
                autoFocusItem={Boolean(expanded)}
                onKeyDown={handleListKeyDown}
            >
                {LANG_ARRAY.map((item) => (
                    <MenuItem                        
                        key={item.code}
                        className='notranslate'
                        lang={item.code}
                        onClick={handleLang}
                        aria-current={lang === item.code}
                        selected={lang === item.code}
                        sx={{ justifyContent: 'flex-end', textAlign: 'end' }}
                    >
                        {item.lang}
                    </MenuItem>
                ))}
            </MenuList>
            </AccordionDetails>
        </Accordion>
    );
}

export default AccordionLang