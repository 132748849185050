import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../../constants/actionTypes"

  
  const initialState =  { userLoad: false, isLoggedIn: false, user: null }

  export default function user (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case REGISTER_SUCCESS:
        return {
          ...state,
          isLoggedIn: false,
        }
      case REGISTER_FAIL:
        return {
          ...state,
          isLoggedIn: false
        }
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
          userLoad: true
        }
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          userLoad: true
        }
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        }
      default:
        return state;
    }
  }