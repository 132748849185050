import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import { RotatingLines } from 'react-loader-spinner'
import Select from './Select'

import getLabel from "../../constants/textMultiLang"

import { checkMime, newID } from '../../utils/app'

import './form.css'


export const buildSelectOptions = (data, setState) => {
    const options = data.map(item => {
        return {value: item._id, label: item.name}
    })
    setState(options)
}


export default function Input(props) {
    const input = useRef()
    const dropRef = useRef()
    const [inputValue, setInputValue] = useState(props.inputValue ? props.inputValue : '')
    const [errorMessage, setErrorMessage] = useState('')
    const [isDrag, setIsDrag] = useState(false)
    const [id, setId] = useState('')
    const type = props.type ? props.type : 'text'
    const label = props.label ? props.label : ''
    const name = props.name ? props.name : ''
    const value = props.value ? props.value : ''
    const optionsSelect = props.optionsSelect ? props.optionsSelect : ''
    const multiple = props.multiple ? props.multiple : false
    const rows = props.rows ? props.rows : 1
    const dataList = props.list ? props.list : ''
    const autoFocus = props.autoFocus ? props.autoFocus : false
    const autoComplete = props.autoComplete ? props.autoComplete : ''
    const isRequired = props.isRequired ? props.isRequired : false
    const errorMessageCustom = props.errorMessage ? props.errorMessage : ''
    const accept = props.accept ? props.accept : ''
    const maxSize = props.maxSize ? props.maxSize : ''
    const inline = props.inline ? 'inline' : ''
    const onChangecustom = props.onChange ? props.onChange : ''
    const setFormValues = props.setFormValues ? props.setFormValues : null
    const validationStatus = props.validationStatus ? props.validationStatus : null
    const setValidationStatus = props.setValidationStatus ? props.setValidationStatus : null
    const isSubmit = props.isSubmit ? props.isSubmit : false
    const setIsSubmit = props.setIsSubmit ? props.setIsSubmit : false
    const loading = props.loading ? props.loading : false
    const success = props.success ? props.success : false
    const { lang } = useSelector(state => state.app)


    const isAdvancedUpload = function() {
        var div = document.createElement('div')
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
    }()

    const {
        CHOOSE_FILE,
        OR_DRAG_IT_HERE,
        DELETE_FILE,
        COMPLETE_FIELD,
        COMPLETE_ATTACH_FILE1,
        COMPLETE_ATTACH_FILE2,
        ATTACH_FILE_AUDIO,
        FORMAT_NOT_ACCEPTED,
        FILE_TOO_BIG,
        INSERT_ONE_ONLY,
        UNE_ERREUR_S_EST_PRODUITE
    } = getLabel(lang, [
        'CHOOSE_FILE',
        'OR_DRAG_IT_HERE',
        'DELETE_FILE',
        'COMPLETE_FIELD',
        'COMPLETE_ATTACH_FILE1',
        'COMPLETE_ATTACH_FILE2',
        'ATTACH_FILE_AUDIO',
        'FORMAT_NOT_ACCEPTED',
        'FILE_TOO_BIG',
        'INSERT_ONE_ONLY',
        'UNE_ERREUR_S_EST_PRODUITE'
    ])

    useEffect(() => {
        if(!id)
            setId(`input${newID()}`)
    }, [id])

    useEffect(() => {
        if(isRequired && ((typeof inputValue === 'object' && !inputValue) || (typeof inputValue !== 'object' && !inputValue.trim())) && type !== 'submit') {
            setValidationStatus(prev => ({...prev,  [name]: true}))
            if(isSubmit) {
                const message = type && type === 'file' ? COMPLETE_ATTACH_FILE1 + ATTACH_FILE_AUDIO + COMPLETE_ATTACH_FILE2 : COMPLETE_FIELD
                setErrorMessage(message)
            }
        }
    }, [isRequired, inputValue, name, setValidationStatus, type, isSubmit, ATTACH_FILE_AUDIO, COMPLETE_ATTACH_FILE1, COMPLETE_ATTACH_FILE2, COMPLETE_FIELD])

    useEffect(() => {
        if(setFormValues !== null)
            setFormValues(prev => ({...prev,  [name]: inputValue}))
        if(isSubmit) {
            setIsSubmit(false)
        }
    }, [inputValue, isSubmit, name, setFormValues, setIsSubmit])

    useEffect(() => {
        if(success) {
            setInputValue('')
            if(input && input.current)
                input.current.value = ''
        }
    }, [success])

    // Drag & Drop
    const handleDrag = e => { 
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = e => { 
        e.preventDefault()
        e.stopPropagation()
        setIsDrag(true)
    }

    const handleDragOut = e => { 
        e.preventDefault()
        e.stopPropagation()
        setIsDrag(false)
    }

    const handleDrop = e => { 
        e.preventDefault()
        e.stopPropagation()
        setIsDrag(false)
        if (e.dataTransfer.files.length === 1)
            onChangeFile(e)
            else if(e.dataTransfer.files.length > 1)
            setErrorMessage(INSERT_ONE_ONLY)
    }

    useEffect(() => {
        let dragndrop = dropRef.current
        if (dragndrop && isAdvancedUpload) {
            dragndrop.addEventListener('drag', handleDrag) 
            dragndrop.addEventListener('dragstart', handleDrag) 
            dragndrop.addEventListener('dragend', handleDragOut) 
            dragndrop.addEventListener('dragenter', handleDragIn) 
            dragndrop.addEventListener('dragleave', handleDragOut) 
            dragndrop.addEventListener('dragover', handleDragIn) 
            dragndrop.addEventListener('drop', handleDrop) 
            return () => {
                dragndrop.addEventListener('drag', handleDrag) 
                dragndrop.addEventListener('dragstart', handleDrag) 
                dragndrop.addEventListener('dragend', handleDragOut) 
                dragndrop.removeEventListener('dragenter', handleDragIn) 
                dragndrop.removeEventListener('dragleave', handleDragOut) 
                dragndrop.removeEventListener('dragover', handleDragIn) 
                dragndrop.removeEventListener('drop', handleDrop) 
            }
        }
    })
    // fin Drag & Drop

    function checkRequired(value) {
        if(isRequired && ((typeof value === 'object' && value) || (typeof value !== 'object' && value.trim())) && validationStatus[name]) {
            setValidationStatus({...validationStatus, [name]: false})
        } else if(isRequired && ((typeof value === 'object' && !value) || (typeof value !== 'object' && !value.trim()))) {
            setValidationStatus({...validationStatus, [name]: true})
            if(isSubmit) {
                const message = type && type === 'file' ? COMPLETE_ATTACH_FILE1 + ATTACH_FILE_AUDIO + COMPLETE_ATTACH_FILE2 : COMPLETE_FIELD
                setErrorMessage(message)
            }
        }
    }

    const onChange = e => {
        setErrorMessage('')
        let value = e.target.value
        if(type === 'number')
            value = value.replace(/\D/g,'')
        checkRequired(value)
        setInputValue(value)
    }

    const onChangeFile = async e => {
        setErrorMessage('')
        let file = ''
        if (e && e.target && e.target.files && e.target.files[0])
            file = e.target.files[0]
        else if (e && e.dataTransfer && e.dataTransfer.files[0])
            file = e.dataTransfer.files[0]
        checkRequired(file)
        if (file) {
            try {
                if(onChangecustom)
                    onChangecustom('')
                setInputValue(file)
                if(file.size && maxSize && file.size > maxSize) { // 13145728
                    return setErrorMessage(FILE_TOO_BIG)
                }
                if(accept) {
                    const checkFichier = await checkMime(file, accept)
                    if (!checkFichier) {
                        // setValidationStatus({...validationStatus, [name]: true})
                        return setErrorMessage(FORMAT_NOT_ACCEPTED)
                    }
                }
                if(onChangecustom)
                    onChangecustom(file)
            } catch (err) {
                delFile()
                setErrorMessage(UNE_ERREUR_S_EST_PRODUITE)
            }
        } else {
            delFile()
        }
    }

    const delFile = () => {
        setInputValue('')
        input.current.value = ''
        if(onChangecustom)
            onChangecustom('')
        setErrorMessage('')
    }

    function DataList() {
        return (
            <datalist id={`list_${id}`}>
                {dataList.map((item, index) => <option key={index}>{item}</option>)}
            </datalist>
        )
    }

    switch (type) {
        case 'submit':
            return(
                <div className="form-group text-center">
                    <button className={`btn btn-primary ${loading ? 'loading' : ''}`} disabled={loading}>
                        {loading && (
                            <span className='spinner'>
                                <RotatingLines
                                    width="1.03em"
                                    strokeColor="#ffffff"
                                    strokeWidth="2.5"
                                    animationDuration="1"
                                />
                            </span>
                        )}
                        <span className='text'>{value}</span>
                    </button>
                </div>
            )
        
        case 'file':
            return(
                <div className="form-group">
                    <Box
                        ref={dropRef}
                        className={`box_dragndrop ${inline} ${isAdvancedUpload && 'has-advanced-upload'} ${isDrag && 'is-dragover'}`}
                        sx={{ '&.inline': { display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', '& .btn, .icon_dragndrop': { margin: '0 0 0 1em' }, '& .icon_dragndrop, .icon_del_dragndrop': { width: '3em', height: 'auto' } } }}
                    >
                        <div >
                            {inputValue && <button type='button' className='btn' aria-label={DELETE_FILE} onClick={delFile}><svg className="icon_del_dragndrop" role='presentation' version='1.1' xmlns='http://www.w3.org/2000/svg' width='50' height='43' viewBox='0 0 19.484 22.267'><path d='M18.557,3.711h-4.64v-2.32C13.917,0.623,13.294,0,12.526,0H6.959C6.19,0,5.567,0.623,5.567,1.391v2.32H0.928 C0.416,3.711,0,4.126,0,4.639c0,0.512,0.416,0.927,0.928,0.927h0.464V17.86c0,2.431,1.795,4.407,4.003,4.407h9.158 c1.953,0,3.54-1.767,3.54-3.943V5.566h0.464c0.512,0,0.927-0.415,0.927-0.927C19.484,4.126,19.069,3.711,18.557,3.711 M7.423,1.855 h4.639v1.392H7.423V1.855z M16.237,18.324c0,1.007-0.677,2.088-1.684,2.088H5.395c-1.285,0-2.147-1.318-2.147-2.552V5.566h12.989 V18.324z'/><path d='M6.031,18.092c0.512,0,0.928-0.415,0.928-0.928v-8.35c0-0.513-0.416-0.928-0.928-0.928 c-0.512,0-0.928,0.415-0.928,0.928v8.35C5.103,17.677,5.519,18.092,6.031,18.092'/><path d='M9.742,18.092c0.512,0,0.928-0.415,0.928-0.928v-8.35c0-0.513-0.416-0.928-0.928-0.928 c-0.512,0-0.928,0.415-0.928,0.928v8.35C8.814,17.677,9.23,18.092,9.742,18.092'/><path d='M13.453,18.092c0.512,0,0.928-0.415,0.928-0.928v-8.35c0-0.513-0.416-0.928-0.928-0.928 c-0.512,0-0.928,0.415-0.928,0.928v8.35C12.525,17.677,12.941,18.092,13.453,18.092'/></svg></button>}
                            {!inputValue && <svg className="icon_dragndrop" role='presentation' xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path></svg>}
                        </div>
                        <Box
                            component='input'
                            ref={input}
                            id={id}
                            type="file"
                            className="inputfile_dragndrop"
                            name={name}
                            onChange={onChangeFile}
                            placeholder={label}
                            autoFocus={autoFocus}
                            accept={accept}
                            
                            // data-multiple-caption="{count} files selected" multiple // dans le cas de plusieurs fichiers
                        />
                        <label htmlFor={id}>
                            {inputValue && <span>{inputValue.name}</span>}
                            {!inputValue && 
                            <>
                                <span className='bold label'>
                                    {CHOOSE_FILE}{label && <><br />{label}</>}{isRequired && (<span className="isRequired"> *</span>)}
                                </span>
                                <span className="label_dragndrop"><br />{OR_DRAG_IT_HERE}</span>
                            </>}.
                        </label>
                    </Box>
                    <span className='error'>{errorMessage || errorMessageCustom}</span>
                </div>
            )

        case 'hidden':
            return (
                <input type='hidden' name={name} value={inputValue} />
            )

        case 'select':
            return (
                <div className="form-group flottant">
                    <label htmlFor={id}>
                        <span className='label label-select'>{label}{isRequired && (<span className="isRequired"> *</span>)}</span>
                    </label>
                        <Select defaultValue={inputValue} success={success} options={optionsSelect} multiple={multiple} onChange={onChange} />
                    <span className='error'>{errorMessage}</span>
                </div>
            )

            case 'textarea':
                return (
                    <div className="form-group flottant">
                    <label htmlFor={id}>
                        <textarea
                            ref={input}
                            id={id}
                            className={"border-bottom"}
                            name={name}
                            value={inputValue}
                            onChange={onChange}
                            autoFocus={autoFocus}
                            placeholder={label}
                            autoComplete={autoComplete}
                            rows={rows}
                        />
                        <span className='label'>{label}{isRequired && (<span className="isRequired"> *</span>)}</span>
                    </label>
                    <span className='error'>{errorMessage}</span>
                </div>
                )

        default:
            const inputAttr = {
                ref: input,
                id: id,
                type: type === 'password' ? type : 'text',
                className: "border-bottom",
                name: name,
                value:inputValue,
                onChange: onChange,
                autoFocus: autoFocus,
                placeholder: label,
                autoComplete: autoComplete,
            }
            if(dataList)
                inputAttr.list = `list_${id}`
            return(
                <div className="form-group flottant">
                    <label htmlFor={id}>
                        <input
                            { ...inputAttr }
                        />
                        <span className='label'>{label}{isRequired && (<span className="isRequired"> *</span>)}</span>
                    </label>
                    <span className='error'>{errorMessage}</span>
                    {dataList && <DataList />}
                </div>
            )
    }
}


Input.propTypes = {
    name: PropTypes.string
}